import React from 'react';
import { SubmitButtonStyled } from './SubmitButtonStyled';

export const SubmitButton = ({ text, type }) => {
  return (
    <SubmitButtonStyled type={type ? type : 'submit'} variant="outlined">
      {text}
    </SubmitButtonStyled>
  );
};
