import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const Loader = ({ padding = 0 }) => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' }}
      p={Number(padding)}
    >
      <CircularProgress />
    </Box>
  );
};
