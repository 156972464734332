import { createSlice } from '@reduxjs/toolkit';
import { fetchPredefinedReportsList, fetchReportsList } from './reports.actions';

const initialState = {
  reports: {
    isLoading: false,
    status: 'initial',
    reportsList: [],
  },
  predefinedReports: {
    isLoading: false,
    status: 'initial',
    predefinedReportsList: [],
  },
};

export const reportsReducer = createSlice({
  name: 'reports',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchReportsList.pending, (state) => {
        state.reports.isLoading = true;
        state.reports.status = 'initial';
      })
      .addCase(fetchReportsList.fulfilled, (state, action) => {
        state.reports.status = 'in';
        state.reports.isLoading = false;
        state.reports.reportsList = action.payload;
      })
      .addCase(fetchReportsList.rejected, (state, action) => {
        state.reports.status = 'in';
        state.reports.isLoading = false;
        state.reports.reportsList = [];
      })
      .addCase(fetchPredefinedReportsList.pending, (state) => {
        state.predefinedReports.isLoading = true;
        state.predefinedReports.status = 'initial';
      })
      .addCase(fetchPredefinedReportsList.fulfilled, (state, action) => {
        state.predefinedReports.status = 'in';
        state.predefinedReports.isLoading = false;
        state.predefinedReports.predefinedReportsList = action.payload;
      })
      .addCase(fetchPredefinedReportsList.rejected, (state, action) => {
        state.predefinedReports.status = 'in';
        state.predefinedReports.isLoading = false;
        state.predefinedReports.predefinedReportsList = [];
      });
  },
});

export default reportsReducer.reducer;
