export class DateHelper {
  constructor(date = new Date()) {
    this.date = date;
  }

  getDateWithOffset = (daysOffset = 0) => {
    const previous = new Date(this.date.getTime());
    previous.setDate(this.date.getDate() - daysOffset);
    this.date = previous;
    return this;
  };

  getStartOfDay = () => {
    this.date.setHours(0, 0, 0, 1);
    return this;
  };
  getEndOfDay = () => {
    this.date.setHours(23, 59, 59, 999);
    return this;
  };

  getTime = () => {
    return this.date.getTime();
  };

  getSecondsSinceEpoch = () => {
    return Math.floor(this.date.getTime() / 1000);
  };
}
