import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import styled from '@emotion/styled';

export const DrawerStyled = styled(Drawer)`
  flex-shrink: 0;
  width: ${({ width }) => width + 'px'};
  .MuiDrawer-paper {
    width: ${({ width }) => width + 'px'};
    box-sizing: border-box;
    border: none;
    overflow: visible;
  }
  .panel-header {
    margin: 10px 0 10px 35px;
    font-size: 13px;
    color: #a1acb8;
  }
`;

export const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'customMargin',
})`
  padding: 0 15px;
  border-radius: 6px;
  margin: ${({ customMargin }) => (customMargin ? `${customMargin} 0` : '0')};
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiButtonBase-root {
    background-color: ${({ isSelected }) => isSelected && 'rgba(67, 89, 113, 0.2)'};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
    border-radius: 6px;
  }
  .MuiTypography-root {
    margin: 4px 0;
    line-height: 23px;
  }
  .MuiListItemText-root {
    margin: 2px 0 0;
  }

  .menu-icons {
    min-width: 25px;
    margin-right: 12px;
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  border: none;
  position: relative;
  height: 62px;
  margin-top: 12px;
  margin-left: 25px;
  margin-bottom: 1rem;
  .menu-open-button {
    position: absolute;
    right: 0;
    transform: translate(50%);
  }
`;
