import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRecommendations } from '../../api/recommendations';
import { TIMESTAMP_FROM, SORT_ORDER_ASC } from '../../constants';

export const fetchRecommendations = createAsyncThunk('fetchRecommendations', async (body, { getState }) => {
  return await getRecommendations({
    warehouseId: getState().appConfig.appConfig.activeWarehouseId,
    data: {
      accessToken: getState().auth.tokens.accessToken,
    },
    params: { ...body?.params, sort: `${TIMESTAMP_FROM}:${SORT_ORDER_ASC}` },
  });
});
