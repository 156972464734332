import styled from '@emotion/styled';

export const PlanLoader = styled('iframe')`
  min-height: 70vh;
  width: 100%;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 991;
  background: rgba(255, 255, 255, 0.7);
`;
