import React from 'react';

export const usePagination = (list, perPage) => {
  const PER_PAGE = perPage ? perPage : 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const handlePageClick = (selected) => () => setCurrentPage(selected);

  const paginatedList = React.useMemo(() => {
    const startIndex = (currentPage - 1) * PER_PAGE;
    const end = startIndex + PER_PAGE;

    return list.slice(startIndex, end);
  }, [currentPage]);

  const pageCount = Math.ceil(list.length / PER_PAGE);

  const shouldShowPagination = list.length > PER_PAGE;

  return {
    pageCount,
    currentPage,
    paginatedList,
    handlePageClick,
    shouldShowPagination,
  };
};
