import { createSlice } from '@reduxjs/toolkit';
import { fetchProcessList } from './processList.actions';

const initialState = {
  processList: {
    list: [],
    isLoading: false,
    status: 'initial',
  },
  predefinedProcessList: {
    predefinedList: [],
    isLoading: false,
    status: 'initial',
  },
};

export const processListReducer = createSlice({
  name: 'processList',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchProcessList.pending, (state) => {
        state.processList.isLoading = true;
      })
      .addCase(fetchProcessList.fulfilled, (state, action) => {
        state.processList.status = 'in';
        state.processList.isLoading = false;
        state.processList.list = action.payload.filter(item => item.owner !== 'lumilook');
        state.predefinedProcessList.isLoading = false;
        state.predefinedProcessList.status = 'in';
        state.predefinedProcessList.predefinedList = action.payload.filter(item => item.owner === 'lumilook');
      })
      .addCase(fetchProcessList.rejected, (state, action) => {
        state.processList.status = 'in';
        state.processList.isLoading = false;
        state.processList.list = [];
        state.predefinedProcessList.status = 'in';
        state.predefinedProcessList.isLoading = false;
        state.predefinedProcessList.predefinedList = [];
      })

  },
});

export default processListReducer.reducer;
