import React from 'react';
import { Link } from 'react-router-dom';
import { FormInputText } from '../../../components/FormComponents/FormInputText';
import { FormButton } from '../../../components/FormComponents/FormButton';
import { useLoginPageForm } from './useLoginPageForm';
import { AuthFormStyled } from '../authFormStyled';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { login } from '../../../store/auth/auth.actions';
import { fetchWarehousesList } from '../../../store/appConfig/appConfig.action';

export const LoginPageForm = ({ onLogin }) => {
  const { control, handleSubmit, formState } = useLoginPageForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const results = await dispatch(login(data));
    onLogin(results, data);
    if (results.meta.requestStatus === 'fulfilled') redirectToFirstActiveWarehouse();
  };

  const redirectToFirstActiveWarehouse = () => {
    dispatch(fetchWarehousesList()).then((res) => {
      const list = res.payload?.warehouseResponse;
      const activeWarehouse = list.find(({ active }) => active);
      if (activeWarehouse) navigate(`/dashboard/${activeWarehouse.id}`);
    });
  };

  return (
    <AuthFormStyled>
      <FormInputText name={'email'} label={'Adres e-mail'} control={control} isSubmitting={formState.isSubmitting} />
      <FormInputText
        name={'password'}
        label={'Hasło'}
        control={control}
        type={'password'}
        isSubmitting={formState.isSubmitting}
      />
      <FormButton text={'Wyślij'} textAlign={'center'} align={'center'} onClick={handleSubmit(onSubmit)} />
      <div className="form-options">
        <Link to={'/remind-password'}>Nie pamiętam hasła</Link>
        {/*<Link to={'/register'}>Załóz nowe konto</Link>*/}
      </div>
    </AuthFormStyled>
  );
};
