import HeatmapLayer from 'react-leaflet-heatmap-layer-v3/lib/HeatmapLayer';
import * as React from 'react';
const gradientDefault = {
  0.1: '#89BDE0',
  0.2: '#96E3E6',
  0.4: '#82CEB6',
  0.6: '#FAF3A5',
  0.8: '#F5D98B',
  1.0: '#DE9A96',
};
const gradientHot = {
  0.1: '#89BDE0',
  0.15: '#96E3E6',
  0.2: '#82CEB6',
  0.4: '#FAF3A5',
  0.5: '#F5D98B',
  1.0: '#DE9A96',
};

export const HeatMapWrapper = ({ points = [] }) => {
  return (
    <HeatmapLayer
      // fitBoundsOnLoad
      fitBoundsOnUpdate
      points={points}
      longitudeExtractor={(m) => m[1]}
      latitudeExtractor={(m) => m[0]}
      gradient={gradientHot}
      intensityExtractor={(m) => parseFloat(Math.sqrt(m[2]))}
      radius={Number(15)}
      blur={Number(10)}
      max={Number.parseFloat(0.5)}
    />
  );
};
