import { createSlice } from '@reduxjs/toolkit';
import { completeNewPassword } from './challenge.actions';
import { ChallengeRequiredException } from '../../services/auth/exceptions/ChallengeRequiredException';
import { NewPasswordException } from '../../services/auth/exceptions/NewPasswordException';

const initialState = () => {
  return {
    status: 'initial',
    isLoading: false,
    error: null,
    errorDetails: null,
    challenge: null,
    challengeParameters: null,
  };
};

export const challengeReducer = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    createChallenge: (state, action) => {
      state.status = 'idle';
      state.challenge = action.payload?.challenge;
      state.challengeParameters = action.payload?.challengeParameters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(completeNewPassword.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
        state.errorDetails = null;
      })
      .addCase(completeNewPassword.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(completeNewPassword.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.errorDetails = action.payload;
        if (action.payload instanceof ChallengeRequiredException || action.payload instanceof NewPasswordException) {
          state.error = action.payload.message;
        }
      });
  },
});

export const { createChallenge } = challengeReducer.actions;
export default challengeReducer.reducer;
