import Grid2 from '@mui/material/Unstable_Grid2';
import { DynamicListItem } from '../../../components/DynamicListItem/DynamicListItem';
import { processHistoryList } from '../compositoMock';
import { DateChildStyled, ProcessCompositionHistoryListStyled } from './ProcessCompositionHistoryListStyled';
import React from 'react';
import { usePagination } from '../../../components/Pagination/usePagination';
import { Pagination } from '../../../components/Pagination/Pagination';
import { messages } from '../processCompositionMessages';
import { useDispatch, useSelector } from 'react-redux';
import { historyProcessListState } from '../../../store/historyProcessList/historyProcessList.selector';
import { Loader } from '../../../components/Loader/Loader';
import {
  ActionChildStyled,
  MainChildStyled,
  StatusChildStyled,
} from '../../../components/DynamicListItem/DynamicListItemStyled';
import dayjs from 'dayjs';

export const ProcessCompositionHistoryList = ({ handleEdit, navigateTo }) => {
  const { paginatedList, handlePageClick, currentPage } = usePagination(processHistoryList, 10);
  const { historyProcessList, isLoading } = useSelector(historyProcessListState);

  const handleSeeProcess = (scenario_id) => () => {
    if (handleEdit && scenario_id && navigateTo) {
      navigateTo(3);
      handleEdit(scenario_id);
    }
  };

  return (
    <ProcessCompositionHistoryListStyled>
      <Grid2 container rowSpacing={2} columns={12}>
        <Grid2 xs={12}>
          <div className="wrapper wrapperFirst">
            <p className="wrapperHeader">{messages.lists.history.title}</p>
            <span className="wrapperDescription">{messages.lists.history.description}</span>
            <ul>
              <DynamicListItem
                processChild={messages.process.process}
                statusChild={messages.process.date}
                actionChild={messages.process.actions}
                isFirst={true}
              />
              {isLoading ? (
                <div className="wrapperLoader">
                  <Loader />
                </div>
              ) : (
                historyProcessList.map((item, index) => (
                  <DynamicListItem
                    key={index}
                    isHoover={false}
                    isFirst={false}
                    title={messages.button.see}
                    processChild={<MainChildStyled isHistory={true}>{renderScenarioItem(item)}</MainChildStyled>}
                    statusChild={<DateChildStyled>{renderStatus(item)}</DateChildStyled>}
                    actionChild={
                      <ActionChildStyled onClick={handleSeeProcess(item.scenario_id)}>
                        {messages.button.edit}
                      </ActionChildStyled>
                    }
                  />
                ))
              )}
            </ul>
            <Pagination handlePageClick={handlePageClick} currentPage={currentPage} listLength={paginatedList.length} />
          </div>
        </Grid2>
      </Grid2>
    </ProcessCompositionHistoryListStyled>
  );
};

const renderStatus = (item) => {
  return (
    <span>
      <StatusChildStyled isActive={item.status === 'SUCCEEDED'} isMargin={true}>
        {item.status}
      </StatusChildStyled>
      {setDate(item.timestamp)}
    </span>
  );
};

const setDate = (date) => date && dayjs(date).format('DD.MM.YYYY hh:mm:ss');

const renderScenarioItem = (item) => {
  return (
    <>
      {item.scenario_name}
      {item.error && <span>error</span>}
    </>
  );
};
