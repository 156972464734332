import styled from '@emotion/styled';

export const ReportsPageHeaderStyled = styled.div`
  .header {
    position: relative;
    background-color: white;
    border-radius: 8px;
    min-height: 153px;
    padding: 20px;
    margin-bottom: 24px;

    .MuiGrid2-root {
      margin: 0 40px;
    }

    &Img {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &Paragraph {
      color: #566a7f;
      font-size: 14px;
      line-height: 25px;
      font-weight: 400;
    }

    &Process {
      &List {
        color: #697a8d;
        font-size: 14px;
        line-height: 23px;
        font-weight: 400;

        &Counter {
          font-weight: 700;
          margin-left: 2px;
        }
      }

      button {
        color: white;
        background: #0d6dc5;
        font-weight: 400;
        line-height: 14px;
        font-size: 12px;
        margin-top: 20px;
        text-transform: none;
        width: 200px;
      }
    }

    h4,
    h5 {
      color: #566a7f;
      font-weight: 700;
      margin-bottom: 26px;
    }

    h4 {
      font-size: 24px;
      line-height: 19px;
    }

    h5 {
      font-size: 18px;
      line-height: 21px;
    }
  }
`;
