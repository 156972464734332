import styled from '@emotion/styled';

const inputColor = '#435971';

export const InputComponentStyled = styled.div`
  input {
    line-height: 18px;
    font-size: 0.9rem;
  }
  .MuiTextField-root {
    margin-top: 4px;
    border-radius: 6px;
    color: ${inputColor};
    margin-bottom: 22px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
