import * as React from 'react';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster/src/react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css';
import { EventMarker } from './EventMarker';

export const EventsCluster = ({ mapIsReady, points }) => {
  return (
    <>
      {mapIsReady && !!points?.length && (
        <MarkerClusterGroup
          showCoverageOnHover={false}
          spiderLegPolylineOptions={{
            weight: 0,
            opacity: 0,
          }}
        >
          {points.map((point) => (
            <EventMarker position={point.position} key={point.id} data={point} />
          ))}
        </MarkerClusterGroup>
      )}
    </>
  );
};
