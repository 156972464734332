import styled from '@emotion/styled';

export const EventsModalStyled = styled.div`
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div:first-of-type {
      p:first-of-type {
        font-weight: lighter;
        color: #a1acb8;
      }
      p:last-of-type {
        line-height: 26px;
        font-size: 1.25rem;
        color: #435971;
        span:last-of-type {
          font-style: italic;
          font-size: 0.9rem;
          color: #435971;
          line-height: 26px;
        }
      }
    }
    svg {
      font-size: 2rem;
      svg {
        font-size: 1.7rem;
      }
    }
  }

  .divider {
    margin-top: 15px;
  }

  .video-container {
    p {
      margin: 20px 0;
      font-weight: lighter;
      color: #435971;
    }

    video {
      height: auto;
      width: 100%;
      max-width: 1000px;
      object-fit: cover;
      display: block;
      margin: 0 auto;
    }
  }

  .caption {
    margin-top: 20px;
    text-align: end;
    font-weight: lighter;
    font-size: 0.75rem;
    color: #a1acb8;

    span {
      font-weight: 400;
      color: #435971;
    }
  }
`;
