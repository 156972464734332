import { createSlice } from '@reduxjs/toolkit';
import { fetchRecommendations } from './recommendations.actions';

const initialState = {
  data: [],
  isLoading: false,
  status: 'initial',
  error: null,
  activeRecommendation: null,
};

export const recommendationsReducer = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    setActiveRecommendation(state, action) {
      state.activeRecommendation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecommendations.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
        state.data = [];
      })
      .addCase(fetchRecommendations.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.data = action.payload;
        // state.data = [
        //   {
        //     id: 1,
        //     warehouse_id: 7,
        //     recommendation: 'This is a brand new recommendation with positions',
        //     x: 3.2,
        //     y: 7.4,
        //     z: 1.2,
        //     timestamp_from: 1672531200,
        //     timestamp_to: 1675209600,
        //     priority: 'high',
        //   },
        //   {
        //     id: 3,
        //     warehouse_id: 7,
        //     recommendation: 'This is a braaaaaand new recommendatioadfafdfasdfasfn with positions',
        //     x: 50.2,
        //     y: 30.4,
        //     z: 1.2,
        //     timestamp_from: 1672531200,
        //     timestamp_to: 1675209600,
        //     priority: 'high',
        //   },
        //   {
        //     id: 4,
        //     warehouse_id: 7,
        //     recommendation: 'This is a braaaaaand new recommendation with positions',
        //     x: 12.2,
        //     y: 15.4,
        //     z: 1.2,
        //     timestamp_from: 1672531200,
        //     timestamp_to: 1675209600,
        //     priority: 'high',
        //   },
        //   {
        //     id: 5,
        //     warehouse_id: 7,
        //     recommendation: 'This is a braaaaaand new recommendation with positions',
        //     x: 30.2,
        //     y: 35.4,
        //     z: 1.2,
        //     timestamp_from: 1672531200,
        //     timestamp_to: 1675209600,
        //     priority: 'medium',
        //   },
        //
        //   {
        //     id: 12,
        //     warehouse_id: 7,
        //     recommendation: 'This is a braaaaaand new recommendation with positions',
        //     x: 59.2,
        //     y: 20.4,
        //     z: 1.2,
        //     timestamp_from: 1672531200,
        //     timestamp_to: 1675209600,
        //     priority: 'low',
        //   },
        //   {
        //     id: 2,
        //     warehouse_id: 7,
        //     recommendation: 'This is a brand new recommendation without positions',
        //     x: null,
        //     y: null,
        //     z: null,
        //     timestamp_from: 1672531200,
        //     timestamp_to: 1675209600,
        //     priority: 'high',
        //   },
        // ];
      })
      .addCase(fetchRecommendations.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = 'error';
        console.log(action);
        state.data = [];
      });
  },
});
export const { setActiveRecommendation } = recommendationsReducer.actions;
export default recommendationsReducer.reducer;
