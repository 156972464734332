import Grid2 from '@mui/material/Unstable_Grid2';
import { UserHeader } from './UserHeader/UserHeader';
import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { MyAccountStyled, TabsStyled } from './MyAccountStyled';
import { UserDetails } from './UserDetails/UserDetails';
import { WarehouseManagement } from './WarehouseManagement/WarehouseManagement';
import styled from '@emotion/styled';

export const MyAccount = () => {
  const [value, setValue] = React.useState(1);
  const [shouldReset, setShouldReset] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    if (event.target.dataset.value) {
      const currentTabValue = parseInt(event.target.dataset.value);
      //if user clicked active tab again, change keys to remount active component
      if (currentTabValue === value) {
        setShouldReset(Math.random().toString());
      }
    }
  };

  return (
    <Grid2 xs={12} md={12}>
      <MyAccountStyled>
        <UserHeader />
        <TabsStyled>
          <Tabs value={value} onChange={handleChange} onClick={handleClick} className="tabs">
            <Tab
              value={1}
              data-value={1}
              label={<TabLabelStyled dangerouslySetInnerHTML={{ __html: 'Zarzadzaj </br> magazynami' }} />}
            />
            {process.env.NODE_ENV === 'developmenta' && (
              <Tab
                value={2}
                data-value={2}
                label={<TabLabelStyled dangerouslySetInnerHTML={{ __html: 'Dane </br> użytkownika' }} />}
              />
            )}
            {process.env.NODE_ENV === 'developmenta' && (
              <Tab
                value={3}
                data-value={3}
                label={<TabLabelStyled dangerouslySetInnerHTML={{ __html: 'Zarzadzaj </br> użytkownikami' }} />}
              />
            )}
          </Tabs>
        </TabsStyled>
        <TabPanel value={value} index={1} key={`1${shouldReset}`}>
          <WarehouseManagement />
        </TabPanel>
        <TabPanel value={value} index={2} key={`2${shouldReset}`}>
          <UserDetails />
        </TabPanel>
      </MyAccountStyled>
    </Grid2>
  );
};

const TabPanel = (props) => {
  const { children, value, index, shouldReset, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const TabLabelStyled = styled.div`
  pointer-events: none;
`;
