import * as yup from 'yup';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addWarehouse, editUserWarehouse } from '../../../../store/warehouses/warehouses.actions';
import { useDispatch, useSelector } from 'react-redux';
import { authTokens } from '../../../../store/auth/auth.selector';
import { fetchWarehousesList } from '../../../../store/appConfig/appConfig.action';

export const useAddWarehouseForm = (initialValues, onPrevStep, navigateTo, isEditor) => {
  const { accessToken } = useSelector(authTokens);
  const dispatch = useDispatch();

  const defaultValues = {
    name: initialValues?.name || '',
  };

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { isSubmitting } = useFormState({ control });

  const onSubmit = async (formData) => {
    const details = {
      warehouseId: initialValues?.id,
      data: {
        name: formData.name,
        accessToken: accessToken,
      },
    };

    if (formData.name) {
      if (isEditor) {
        dispatch(editUserWarehouse(details)).then(() => {
          dispatch(fetchWarehousesList());
          navigateTo(1);
        });
      } else {
        dispatch(addWarehouse(details.data)).then(() => {
          dispatch(fetchWarehousesList()).then(() => {
            onPrevStep();
          });
        });
      }
    }
  };

  return {
    isSubmitting,
    control,
    handleSubmit,
    onSubmit,
  };
};

const schema = yup
  .object({
    email: yup.string().label('Nazwa Magazynu'),
  })
  .required();
