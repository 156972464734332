import React, { memo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ContentBox from './components/ContentBox';
import { onboardingWarehouse } from '../../data/createWarehouse';
import UploadFile from './components/UploadFile';

const OnboardingUploadFile = (props) => {
  const { step, header, description } = onboardingWarehouse[0];

  return (
    <ContentBox title={step} subtitle={header} description={description}>
      <DndProvider backend={HTML5Backend}>
        <UploadFile {...props} />
      </DndProvider>
    </ContentBox>
  );
};
export default memo(OnboardingUploadFile);
