import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Image, Layer, Rect, Stage, Text as TextComponent, Line, Group } from 'react-konva';
import { Html } from 'react-konva-utils';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import useImage from 'use-image';
import { setImageProperties } from '../../../store/onboarding/onboarding.reducer';
import { onboardingState } from '../../../store/onboarding/onboarding.selector';
import styled from '@emotion/styled';
// import Tooltip from './Tooltip';

const ScaleLine = ({ points, zoomLevel }) => {
  return <Line points={points} stroke={'red'} strokeWidth={2 / zoomLevel} lineCap={'round'} lineJoin={'round'} />;
};

const Canvas = (props) => {
  const {
    containerRef,
    zoomLevel,
    croppedImage,
    handleSubmit: handleSubmitModal,
    handleClose: handleCloseModal,
    setModalOpen,
    children,
    setScale,
    setZoomLevel,
  } = props;
  const group = useRef(null);
  const dispatch = useDispatch();
  const { imageProperties } = useSelector(onboardingState);
  const [drawingMode, setDrawingMode] = useState(false);
  const [containerSize, setContainerSize] = useState({ width: 500, height: 500 });
  const [scaleLinePoints, setScaleLinePoints] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lineLength, setLineLength] = useState(0);

  useEffect(() => {
    if (imageProperties?.linePosition) {
      setScaleLinePoints(imageProperties.linePosition);
      setLineLength(imageProperties.distanceInPicutreInPx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      let height = containerRef.current.offsetHeight;
      let width = containerRef.current.offsetWidth;
      setContainerSize({ width, height });
    }
  }, [containerRef, containerSize.width, setZoomLevel]);

  const [imageCrop] = useImage(croppedImage);
  const [scale, setScaleS] = useState({ x: 0, y: 0 });

  const mouseUp = (e) => {
    if (!drawingMode) return;

    const node = group.current;
    const pos = node.getRelativePointerPosition();

    if (isDrawing) {
      setIsDrawing(false);
      const linePosition = [scaleLinePoints[0], scaleLinePoints[1], pos.x, pos.y];
      dispatch(setImageProperties({ linePosition }));
      setScaleLinePoints(linePosition);
      toggleDrawMode();
      setModalOpen(true);
    } else {
      setScaleLinePoints([pos.x, pos.y, pos.x, pos.y]);
      setIsDrawing(true);
    }
  };

  const mouseMove = (e) => {
    if (!isDrawing) return;

    const node = group.current;
    const pos = node.getRelativePointerPosition();

    setScaleLinePoints([scaleLinePoints[0], scaleLinePoints[1], pos.x, pos.y]);
  };

  const toggleDrawMode = () => {
    if (drawingMode) setDrawingMode(false);
    else setDrawingMode(true);
  };

  const handleDrawIconClick = () => {
    setScale(null);
    dispatch(setImageProperties({}));
    setScaleLinePoints([]);
    toggleDrawMode();
  };

  const handleClose = () => {
    setScaleLinePoints([]);
    handleCloseModal();
  };

  const handleSubmit = () => {
    handleSubmitModal(lineLength);
  };

  useEffect(() => {
    setLineLength(Math.hypot(scaleLinePoints[2] - scaleLinePoints[0], scaleLinePoints[3] - scaleLinePoints[1]));
  }, [scaleLinePoints]);

  const [image] = useImage('/static/Line_fill.svg');
  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        if (node.getWidth() && node.getHeight()) {
          const scaleHeight = containerSize.height / node.getHeight();
          const scaleWidth = containerSize.width / node.getWidth();
          const x = containerSize.height / 2 - (node.getHeight() * scaleWidth) / 2;
          const y = containerSize.width / 2 - (node.getWidth() * scaleHeight) / 2;
          const isHeightMore = scaleHeight >= scaleWidth;
          setZoomLevel(isHeightMore ? scaleWidth : scaleHeight);
          !isHeightMore ? setScaleS({ x: y, y: 0 }) : setScaleS({ x: 0, y: x });
        }
      }
    },
    [containerSize, setZoomLevel]
  );

  return (
    <>
      <Stage
        style={{ cursor: drawingMode ? 'crosshair' : 'move' }}
        width={containerSize.width}
        height={containerSize.height}
      >
        <Layer>
          <>
            <Group
              onMouseMove={mouseMove}
              onMouseUp={mouseUp}
              scale={{ x: zoomLevel, y: zoomLevel }}
              y={scale.y}
              x={scale.x}
              ref={group}
              draggable={!isDrawing}
            >
              <Image image={imageCrop} ref={measuredRef} />
              {scaleLinePoints.length > 0 && <ScaleLine points={scaleLinePoints} zoomLevel={zoomLevel} />}
            </Group>

            <Html>
              <Tooltip
                style={{
                  position: 'absolute',
                  top: 10,
                  left: 10,
                  cursor: 'pointer',
                  fontSize: '1.25rem',
                }}
                onClick={handleDrawIconClick}
                title="Kliknij aby narysować"
                placement="right"
                arrow
                TransitionProps={{ timeout: 0 }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      paddingLeft: '2rem',
                      paddingRight: '2rem',
                      background: 'rgba(0,0,0,.7)',
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'rgba(0,0,0,.7)',
                    },
                  },
                }}
              >
                <DrawLineButtonStyled className={!!drawingMode && 'drawing-mode'}>
                  <img src="/static/line-icon--ghost.svg" alt="line" />
                </DrawLineButtonStyled>
              </Tooltip>
            </Html>
          </>
        </Layer>
      </Stage>
      {React.cloneElement(children, { ...props, handleSubmit: handleSubmit, handleClose: handleClose })}
    </>
  );
};
export default memo(Canvas);

const DrawLineButtonStyled = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(222, 232, 245);
  border-radius: 7px;
  border: 1px solid white;

  &.drawing-mode {
    box-shadow: inset 0px 0px 4px 0px rgba(66, 68, 90, 0.47);
  }

  img {
    display: block;
  }
`;
