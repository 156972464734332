import { getFormData, getHeaders, getSearchParamsString, isPlainObject, omitObjectKey } from '../utils/api';
import axios from 'axios';
import streamSaver from 'streamsaver';

export const get = async (url, data, params) => {
  try {
    const res = await fetch(url + getSearchParamsString(params), {
      method: 'GET',
      redirect: 'follow',
      headers: getHeaders(data),
    }).then((response) => response);

    if (res.status === 403) throw new Error('403');

    return res.json();
  } catch (e) {
    return e.message;
  }
};

export const deleteMethod = async (url, data) => {
  try {
    const res = await fetch(url, {
      method: 'DELETE',
      headers: getHeaders(data),
    }).then((response) => response);

    if (res.status === 403) throw new Error('403');

    return res.json();
  } catch (e) {
    return e.message;
  }
};

export const post = async (url, data, dataType) => {
  const dataBody = omitObjectKey(data, 'accessToken');
  try {
    const res = await fetch(url, {
      method: 'POST',
      body: isPlainObject(dataBody) ? JSON.stringify(dataBody) : dataBody,
      headers: getHeaders({
        ...data,
        contentType: dataType !== undefined ? dataType : 'application/x-www-form-urlencoded',
      }),
    }).then((response) => response);

    return res;
  } catch (e) {
    return e.message;
  }
};

export const put = async (url, data, dataType) => {
  try {
    const res = await fetch(url, {
      method: 'PUT',
      body: getFormData(data),
      headers: getHeaders({ ...data, contentType: dataType !== undefined ? dataType : 'multipart/form-data' }),
    }).then((response) => response);

    return res.json();
  } catch (e) {
    return e.message;
  }
};

export const putJSON = async (url, data) => {
  const dataBody = omitObjectKey(data, 'accessToken');

  try {
    const res = await fetch(url, {
      method: 'PUT',
      body: isPlainObject(dataBody) ? JSON.stringify(dataBody) : dataBody,
      headers: getHeaders({ ...data, contentType: 'application/json' }),
    }).then((response) => response);

    return res.json();
  } catch (e) {
    return e.message;
  }
};

export const saveFileToS3 = async (warehouseId, path, file, accessToken) => {
  const { fields, url } = await get(`${API.apiUrl}/warehouse/${warehouseId}/${path}`, { accessToken });

  return axios.post(
    url,
    { ...fields, file },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
};

export const getFileFromS3 = async (warehouseId, path, accessToken) => {
  const resGet = await get(`${API.apiUrl}/warehouse/${warehouseId}/${path}`, { accessToken });
  return await axios.get(resGet.url);
};

export const getPdfStream = async (url, data, filename) => {
  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: getHeaders({ ...data, contentType: 'application/pdf' }),
      redirect: 'follow',
    });
    const pdfName = filename ?? 'document';
    const fileStream = streamSaver.createWriteStream(`${pdfName}.pdf`);
    const writer = fileStream.getWriter();

    const reader = res.body.getReader();

    const pump = () =>
      reader.read().then(({ value, done }) => {
        if (done) writer.close();
        else {
          writer.write(value);
          return writer.ready.then(pump);
        }
      });

    await pump()
      .then(() => console.log('Closed the stream, Done writing'))
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

const API = {
  apiUrl: process.env.REACT_APP_API,
  baseAuthUrl: process.env.REACT_APP_AUTH,
  baseApiUrl: process.env.REACT_APP_API_URL || `https://lumilook.proxy.beeceptor.com`,
};

export default API;
