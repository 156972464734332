import React from 'react';

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleScreenSize = () => setScreenWidth(window.innerWidth);
    handleScreenSize();
    window.addEventListener('resize', handleScreenSize);

    return () => window.removeEventListener('resize', handleScreenSize);
  }, []);

  return screenWidth;
};
