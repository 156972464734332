import styled from '@emotion/styled';

export const DynamicSelectStyled = styled.div`
  .MuiMenuItem-root {
    font-family: 'Public Sans', sans-serif;
    font-size: 14px !important;
    align-items: center;
    display: flex;
  }

  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    font-family: 'Public Sans', sans-serif;
  }

  .MuiSelect-select {
    padding: 8px;
    border-radius: 8px;
    font-family: 'Public Sans', sans-serif;
    font-size: 14px;
    align-items: center;
    display: flex;
  }

  .MuiFormHelperText-root {
    margin: 0;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: #435971;
  }
`;
