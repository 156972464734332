import styled from '@emotion/styled';

export const UserDetailsEditorStyled = styled.form`
  .wrapper {
    width: 50%;
    margin-top: 22px;
  }

  .label {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #435971;
    margin-bottom: 11px;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
  }

  .checkBoxWrapper {
    display: flex;
  }
`;
