import Grid2 from '@mui/material/Unstable_Grid2';
import { ProcessCompositionListStyled } from './ProcessCompositionListStyled';
import { DynamicListItem } from '../../../components/DynamicListItem/DynamicListItem';
import { messages } from '../processCompositionMessages';
import { useDispatch, useSelector } from 'react-redux';
import { predefinedProcessListState, processListState } from '../../../store/processList/processList.selector';
import { Loader } from '../../../components/Loader/Loader';
import { handleActivateUserProcess, handleMoveProcessFromPredefinedList } from '../../../store/process/process.actions';
import {
  ActionChildStyled,
  MainChildStyled,
  StatusChildStyled,
} from '../../../components/DynamicListItem/DynamicListItemStyled';

export const ProcessCompositionList = ({ navigateTo, handleEdit }) => {
  const dispatch = useDispatch();
  const { list, isLoading } = useSelector(processListState);
  const { predefinedList, isLoading: isPredefinedProcessLoading } = useSelector(predefinedProcessListState);
  const toggleActivateProcess = (active, processId) => () =>
    dispatch(handleActivateUserProcess({ scenarioId: processId, active: !active }));
  const moveProcessFromPredefinedList = (processId) => () => dispatch(handleMoveProcessFromPredefinedList(processId));

  return (
    <ProcessCompositionListStyled>
      <Grid2 container rowSpacing={2} columns={12}>
        <Grid2 xs={12} lg={6}>
          <div className="wrapper wrapperFirst">
            <p className="wrapperHeader">{messages.lists.mainList.title}</p>
            <span className="wrapperDescription">{messages.lists.mainList.description}</span>
            <ul>
              <DynamicListItem
                processChild={messages.process.process}
                statusChild={messages.process.status}
                actionChild={messages.process.actions}
                isFirst={true}
              />
              {isLoading ? (
                <div className="wrapperLoader">
                  <Loader />
                </div>
              ) : (
                <>
                  {list.map((item) => (
                    <DynamicListItem
                      key={item.id}
                      title={messages.button.edit}
                      isFirst={false}
                      id={item.id}
                      isHoover={true}
                      handleEdit={handleEdit}
                      navigateTo={navigateTo}
                      processChild={<MainChildStyled>{item.name}</MainChildStyled>}
                      statusChild={
                        <StatusChildStyled isActive={item.active}>
                          {item.active ? messages.button.switchedOn : messages.button.switchedOff}
                        </StatusChildStyled>
                      }
                      actionChild={
                        <ActionChildStyled onClick={toggleActivateProcess(item.active, item.id)}>
                          {!item.active ? messages.button.switchOn : messages.button.switchOff}
                        </ActionChildStyled>
                      }
                    />
                  ))}
                </>
              )}
            </ul>
          </div>
        </Grid2>
        <Grid2 xs={12} lg={6}>
          <div className="wrapper">
            <p className="wrapperHeader">{messages.lists.predefined.title}</p>
            <span className="wrapperDescription">{messages.lists.predefined.description}</span>
            <ul>
              <DynamicListItem
                processChild={messages.process.process}
                statusChild={null}
                actionChild={messages.process.actions}
                isFirst={true}
              />
              {isPredefinedProcessLoading && (
                <div className="wrapperLoader">
                  <Loader />
                </div>
              )}
              {predefinedList.map((item) => (
                <DynamicListItem
                  key={item.id}
                  isFirst={false}
                  processChild={<MainChildStyled>{item.name}</MainChildStyled>}
                  statusChild={null}
                  actionChild={
                    <ActionChildStyled onClick={moveProcessFromPredefinedList(item.id)}>
                      {messages.button.add}
                    </ActionChildStyled>
                  }
                />
              ))}
            </ul>
          </div>
        </Grid2>
      </Grid2>
    </ProcessCompositionListStyled>
  );
};
