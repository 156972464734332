import React from 'react';
import { FormInputText } from '../../../components/FormComponents/FormInputText';
import { FormButton } from '../../../components/FormComponents/FormButton';
import { AuthFormStyled } from '../authFormStyled';
import { useDispatch } from 'react-redux';
import { useCompleteNewPassword } from './useCompleteNewPassword';
import { completeNewPassword } from '../../../store/challenge/challenge.actions';
import { Link } from 'react-router-dom';

export const CompleteNewPasswordForm = ({ onCompleteNewPassword, email, oldPassword }) => {
  const { control, handleSubmit, formState } = useCompleteNewPassword();
  const dispatch = useDispatch();

  const onSubmit = async ({ password }) => {
    const results = await dispatch(completeNewPassword({ password, email, oldPassword }));
    onCompleteNewPassword(results);
  };

  return (
    <AuthFormStyled>
      <FormInputText
        name={'password'}
        label={'Hasło'}
        control={control}
        type={'password'}
        isSubmitting={formState.isSubmitting}
      />

      <FormInputText
        name={'password_confirm'}
        label={'Potwierdz hasło'}
        control={control}
        type={'password'}
        isSubmitting={formState.isSubmitting}
      />
      <FormButton text={'Wyślij'} textAlign={'center'} align={'center'} onClick={handleSubmit(onSubmit)} />
      <div className="form-options">
        <Link to={'/login'}>Zaloguj się</Link>
      </div>
    </AuthFormStyled>
  );
};
