import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWarehouseReconstructionWithSvg } from '../../api/reconstruction';

export const fetchWarehouseReconstructionWithSvg = createAsyncThunk(
  'eventHistogram/fetchWarehouseReconstructionSvg',
  async (body, { getState }) => {
    return await getWarehouseReconstructionWithSvg(
      getState().appConfig.appConfig.activeWarehouseId,
      getState().auth.tokens.accessToken
    );
  }
);
