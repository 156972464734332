import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { predefinedReportsListState, reportsListSate } from '../../store/reportsManager/reports.selector';
import { fetchPredefinedReportsList, fetchReportsList } from '../../store/reportsManager/reports.actions';

export const useReportPage = () => {
  const dispatch = useDispatch();
  const { reportsList, isLoading } = useSelector(reportsListSate);
  const { predefinedReportsList, isLoading: isPredefinedListLoading } = useSelector(predefinedReportsListState);

  const fetchReports = () => !reportsList?.length && dispatch(fetchReportsList());
  const fetchPredefinedReports = () => !predefinedReportsList?.length && dispatch(fetchPredefinedReportsList());

  React.useEffect(() => {
    fetchReports();
    fetchPredefinedReports();
  }, []);

  return {
    isLoading,
    reportsList,
    predefinedReportsList,
    isPredefinedListLoading,
  };
};
