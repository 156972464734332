import React from 'react';

export const useInputValue = (initialValue) => {
  const [inputValue, setInputValue] = React.useState(initialValue || '');
  const handleInputChange = (event) => setInputValue(event.target.value);

  React.useEffect(() => {
    if (initialValue) {
      setInputValue(initialValue);
    }
  }, [initialValue]);
  return {
    inputValue,
    handleInputChange,
  };
};
