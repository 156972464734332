import { useForm, useFormState } from 'react-hook-form';
import React from 'react';

export const useDiagramForm = () => {
  const { control, handleSubmit, reset, setValue, getValues} = useForm({
    mode: 'onChange',
    reValidateMode: "onChange"

  });

  const { isSubmitting } = useFormState({ control });

  return { isSubmitting, control, handleSubmit, reset, setValue, getValues };
};
