import Grid2 from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import { PaginationStyled } from './PaginationStyled';

export const Pagination = ({ currentPage, handlePageClick, listLength }) => {
  const isNextDisabled = listLength < 10;
  const isPrevDisabled = currentPage === 1;

  return (
    <PaginationStyled>
      <Grid2 container xs={12} className="wrapper">
        <Button disabled={isPrevDisabled} onClick={handlePageClick(currentPage - 1)}>
          {' '}
          {'Poprzednia strona'}
        </Button>
        <Button disabled={isNextDisabled} onClick={handlePageClick(currentPage + 1)}>
          {'Następna strona'}
        </Button>
      </Grid2>
    </PaginationStyled>
  );
};
