import styled from '@emotion/styled';

export const MyAccountStyled = styled.div`
  display: flex;
  flex-direction: column;

  .tabs {
    margin: 30px 0;
  }
`;

export const TabsStyled = styled.div`
  .Mui-selected {
    color: #697a8d !important;
    opacity: 1;
  }

  button {
    color: #697a8d;
    opacity: 0.35;
    align-items: self-start;
    padding-left: 0;
    text-align: left;
    padding-right: 0;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-transform: none;
    margin-left: 0;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
`;
