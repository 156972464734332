import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewScenario, deleteUserScenario, updateUserScenario } from '../../../store/process/process.actions';
import { processState } from '../../../store/process/process.selector';
import { isEmpty } from '../../../utils/utils';
import { fetchProcessList } from '../../../store/processList/processList.actions';
import {clearCaseDetails} from "../../../store/process/process.reducer";

export const useDiagram = (isEditProcess, selectedId, setValue, navigateTo, reset) => {
  const [itemList, setItemList] = React.useState([]);
  const [isAddNewCaseDisabled, setIsAddNewCaseDisabled] = React.useState(false);
  const [activeCase, setActiveCase] = React.useState(0);

  const dispatch = useDispatch();
  const { caseDetails } = useSelector(processState);
  const { eventCases, actionCases, isLoading } = useSelector(processState);

  const handleAddCase = (caseType) => {
    const joined = itemList.concat([
      {
        caseType,
        id: itemList.length,
        caseDetails: null,
      },
    ]);
    setItemList(joined);
  };

  const handleCancelProcess = () => navigateTo(1);

  const handleCaseDetails = (selectedOption, index) => {
    const newArray = itemList.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...item,
          caseDetails: { ...selectedOption },
        };
      }
      return item;
    });

    setItemList(newArray);
    handleSetActiveCase(-1)();
  };

  const removeCase = (selectedIndex) => () => {
    const removed = itemList.filter((item, index) => index !== selectedIndex);
    setItemList(removed);
    reset();
    setInitialValuesForInputs(removed, setValue);
    handleSetActiveCase(-1)();
  };

  const removeProcess = (selectedId) => () =>
    dispatch(deleteUserScenario({ selectedId })).then(() => {
      navigateTo(1);
      dispatch(fetchProcessList());
    });

  const toggleDisableAddNewCase = (shouldDisabled) => () => setIsAddNewCaseDisabled(shouldDisabled);

  const handleSetActiveCase = (index) => () => setActiveCase(index);

  const handleSaveProcess = (processName, intervalTime, isEditProcess) => {
    const scenarioDetails = {
      name: processName,
      run_unique_every_minutes: Number(intervalTime),
      blocks: prepareBlocks(itemList),
    };

    navigateTo(1);

    if (isEditProcess) {
      const editScenarioDetails = {
        active: caseDetails.active,
        draft: caseDetails.draft,
        ...scenarioDetails,
      };

      return dispatch(updateUserScenario({ selectedId, editScenarioDetails })).then(() => dispatch(fetchProcessList()));
    }

    return dispatch(addNewScenario(scenarioDetails)).then(() => dispatch(fetchProcessList()));
  };

  React.useEffect(() => {
    if (caseDetails && isEditProcess) {
      if (caseDetails.blocks) {
        const listItem = prepareEditedBlocks(caseDetails.blocks, eventCases.concat(actionCases));
        setInitialValuesForInputs(listItem, setValue);
        setItemList(listItem);
      }
    }
  }, [caseDetails]);

  return {
    isLoading,
    activeCase,
    itemList,
    isAddNewCaseDisabled,
    removeCase,
    removeProcess,
    handleAddCase,
    handleSaveProcess,
    handleCaseDetails,
    handleCancelProcess,
    handleSetActiveCase,
    toggleDisableAddNewCase,
  };
};

const prepareBlocks = (itemList) => {
  return itemList.map((item) => {
    if (item.caseDetails.parameters && isEmpty(item.caseDetails.parameters)) {
      return { block_id: item.caseDetails.block_id, parameters: {} };
    }
    return {
      block_id: item.caseDetails.block_id,
      parameters: item.caseDetails.parameters,
    };
  });
};

const prepareEditedBlocks = (blocks, blockScenario) => {
  if (blocks.length) {
    const lastId = blocks.find((item) => item.next === null)?.id || 0;
    let removedBlocks = blocks;
    let searchedId = lastId;
    let properArray = [lastId];

    for (let i = 0; i < removedBlocks.length; i++) {
      searchedId = blocks.find((item) => item.next === searchedId)?.id;
      properArray.push(blocks.find((item) => item.id === searchedId)?.id);
    }

    const reverseArray = properArray.filter((item) => item).reverse();

    return reverseArray.map((arrayItem, index) => {
      const section = blocks.find((item) => item.id === arrayItem);
      const block = blockScenario.find((item) => item.id === section.block_id);

      return {
        id: index,
        caseType: block.caseType || '',
        caseDetails: {
          block_id: section.block_id,
          value: block.name,
          name: block.name,
          parameters: isEmpty(section.parameters) ? {} : section.parameters,
        },
      };
    });
  }

  return [];
};

const setInitialValuesForInputs = (listItem, setValue) => {
  listItem.forEach((item, index) => {
    if (!isEmpty(item.caseDetails.parameters)) {
      Object.keys(item.caseDetails.parameters).map((key) => {
        const uniqueKey = `caseId${index}_${key}`;
        setValue(uniqueKey, item.caseDetails.parameters[key]);
      });
    }
  });
};
