import { createTheme } from '@mui/material';

const LumilookTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0D6DC5',
    },
    secondary: {
      main: '#435971',
      light: '#DBE9F6',
    },
    text: {
      primary: '#435971',
    },
    charts: {
      green: '#C3ECA3',
      yellow: '#F8D78C',
      red: '#F2A392',
      blue: '#93bbe6',
      violet: '#d79eea',
      bloodRed: '#b83e6e',
    },
  },
  typography: {
    fontFamily: "'Public Sans', sans-serif",
    body1: {
      fontSize: '0.938rem',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.125rem',
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 'lighter',
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        // Name of the slot
        body1: {
          // Some CSS
          fontSize: '1.375rem',
          lineHeight: '1.1rem',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '0.9rem',
          lineHeight: '1.1rem',
          bottom: 4,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'small' },
          style: {
            fontSize: '0.8rem',
            lineHeight: '0.9rem',
          },
        },
        {
          props: { variant: 'lead' },
          style: {
            fontSize: '1.5rem',
            fontStyle: 'italic',
            fontWeight: 'lighter',
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          fontSize: '0.875rem',
          fontWeight: '300',
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontSize: '0.875rem',
            fontWeight: '300',
            '& > div': {
              paddingTop: '3px',
              paddingBottom: '3px',
            },
          },
        },
      ],
    },
  },
  custom: {
    borderRadius: '8px',
  },
});
export default LumilookTheme;
