import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { useLocation, Link, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { BreadcrumbStyled } from './BreadcrumbStyled';
import { useSelector } from 'react-redux';
import { appConfigState, eventDefinitionState } from '../../../store/appConfig/appConfig.selector';
import { matchExtendedEventDefinition } from '../../../utils/utils';

export const BreadcrumbsNav = ({ level }) => {
  const { activeWarehouseId } = useSelector(appConfigState);
  const { search } = useLocation();
  const { list } = useSelector(eventDefinitionState);
  let [searchParams] = useSearchParams();

  const prepareSecondCategoryLabel = () => {
    const params = searchParams.get('event_type_with_instances');

    if (params) {
      return params
        .split('_')
        .filter((item) => !item.includes('id'))
        .join('_');
    }

    return '';
  };

  const prepareThirdCategoryLabel = () => {
    const params = searchParams.get('instances');
    if (params) {
      return params
    }

    return '';
  };

  const firstLevelPathname = prepareFirstLevelPathName(activeWarehouseId, search, level);
  const secondLevelPathName = prepareSecondLevelPathName(activeWarehouseId, searchParams, search, level);
  const secondLevelLabel = matchExtendedEventDefinition(
    searchParams.get('event_type') || prepareSecondCategoryLabel(),
    list,
    true
  );

  return (
    <Box mt={3}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={firstLevelPathname}>
          <BreadcrumbStyled component="span" label="Eventy w magazynie" />
        </Link>
        {(level === 2 || level === 3) && (
          <Link to={secondLevelPathName}>
            <BreadcrumbStyled component="span" label={secondLevelLabel} />
          </Link>
        )}
        {level === 3 && <BreadcrumbStyled component="span" label={prepareThirdCategoryLabel()} />}
      </Breadcrumbs>
    </Box>
  );
};

const prepareSecondLevelPathName = (activeWarehouseId, searchParams, search, level) => {
  if (level === 2) {
    return `/warehouse-details/${activeWarehouseId}/events/type_instance${search}`;
  }

  if (level === 3) {
    const params =
      searchParams.get('event_type') && searchParams.get('event_type').split(':')[0];

    const restParams = search.includes('&')
      ? search
          .split('&')
          .filter((item, index) => index > 0).filter((item) => !item.includes('instances'))
          .join('&')
      : '';

    return `/warehouse-details/${activeWarehouseId}/events/type_instance?event_type=${params}&${restParams}`;
  }

  return '';
};

export const prepareFirstLevelPathName = (activeWarehouseId, search, level) => {
  if (level === 1) {
    return `/warehouse-details/${activeWarehouseId}/events/type${search}`;
  }

  const params = search.includes('&')
    ? omitInstances(search)
        .split('&')
        .filter((item, index) => index > 0)
        .join('&')
    : '';
  return `/warehouse-details/${activeWarehouseId}/events/type?${params}`;
};

export const omitInstances = (search) =>
  search
    .split('&')
    .filter((item) => !item.includes('instances'))
    .join('&');
