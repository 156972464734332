import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { fetchProcessList } from '../../store/processList/processList.actions';
import { appConfigState } from '../../store/appConfig/appConfig.selector';

export const useFetchProcessComposition = () => {
  const dispatch = useDispatch();
  const { activeWarehouseId } = useSelector(appConfigState);
  const fetchProjectList = () => dispatch(fetchProcessList());

  React.useEffect(() => {
    if (activeWarehouseId !== 0) {
      fetchProjectList();
    }
  }, [activeWarehouseId]);
};
