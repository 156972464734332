import { ProcessCompositionHeaderStyled } from './ProcessCompositionHeaderStyled';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { messages } from '../processCompositionMessages';
import { useSelector } from 'react-redux';
import { processListState } from '../../../store/processList/processList.selector';

export const ProcessCompositionHeader = ({ title, description, isNewProcessSectionEnabled, handleNewProcess }) => {
  const { list } = useSelector(processListState);
  return (
    <ProcessCompositionHeaderStyled>
      <Grid2 container spacing={2}>
        <Grid2 xs={8} spacing={2} rowSpacing={16} columnSpacing={16}>
          <Box p={3} className="header">
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
            <div className="headerParagraph">{description}</div>
          </Box>
        </Grid2>
        <Grid2 xs={4} rowSpacing={3} columnSpacing={3}>
          {isNewProcessSectionEnabled && (
            <Box p={3} className="header headerProcess">
              <Typography variant="h5" gutterBottom>
                {messages.lists.addProcess.title}
              </Typography>
              <div className="headerProcessList">
                <span>{messages.lists.addProcess.createdProcess}</span>
                <span className="headerProcessListCounter">{list.length}</span>
              </div>
              <Button onClick={handleNewProcess}>{messages.lists.addProcess.createNewProcess}</Button>
              <img className="headerImg" src={'/static/new-process-icon.svg'} alt={'new process'} />
            </Box>
          )}
        </Grid2>
      </Grid2>
    </ProcessCompositionHeaderStyled>
  );
};
