import { Box, Button, Typography } from '@mui/material';
import { messages } from '../../ProcessComposition/processCompositionMessages';
import React from 'react';
import { ReportsListPageHeaderStyled } from './ReportsListPageHeaderStyled';

export const ReportsListPageHeader = ({ reportsCount = 0 }) => {
  return (
    <ReportsListPageHeaderStyled>
      <Box p={3} className="header headerProcess">
        <Typography variant="h4" gutterBottom>
          {messages.reports.addReport.title}
        </Typography>
        <div className="headerProcessList">
          <span>Liczba aktualnie dostępnych raportów:</span>
          <span className="headerProcessListCounter">{reportsCount}</span>
        </div>
        <img className="headerImg" src={'/static/new-report.svg'} alt={'new report'} />
      </Box>
    </ReportsListPageHeaderStyled>
  );
};
