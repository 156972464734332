import * as yup from 'yup';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { pl } from 'yup-locales';
import { setLocale } from 'yup';

setLocale(pl);

export const useLoginPageForm = () => {
  const schema = yup
    .object({
      email: yup.string().required().label('adres e-mail'),
      password: yup.string().required().label('hasło'),
    })
    .required();

  const defaultValues = {
    email: '',
    password: '',
  };

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  // const onSubmit ()

  const { isValid, isSubmitting, isSubmitSuccessful, isSubmitted, isValidating } = useFormState({ control });

  return { control, formState: { isValid, isSubmitting, isSubmitSuccessful, isSubmitted, isValidating }, handleSubmit };
};
