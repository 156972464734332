import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  MenuItem,
  TableRowColumn,
  Menu,
  TableContainer,
  Link as MuiLink,
  useTheme,
  Button,
} from '@mui/material/';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TableComponentStyled } from './styles';
import { TableOutlinedButton, TableRowStyled } from '../TableOutlineButton/TableOutlinedButton';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { useSelector } from 'react-redux';
import { eventDefinitionState } from '../../store/appConfig/appConfig.selector';
import { matchEventDefinitions } from '../../utils/utils';
import { LABEL_DATE_FORMAT, LABEL_TIME_FORMAT, PRIORITY_HIGH, PRIORITY_MEDIUM } from '../../constants';
import { RecommendationCell } from './Cells/RecommendationCell';
import { RecommendationTypeCell } from './Cells/RecommendationTypeCell';

export const TableComponent = ({
  headers,
  rows,
  rowPerPage,
  isLoading,
  handleReadEvent,
  isModalButton,
  showActionButton = false,
  level,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentRow, setCurrentRow] = React.useState();
  const { list } = useSelector(eventDefinitionState);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event, row) => {
    setCurrentRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);
  return (
    <TableComponentStyled nrOfRows={rowPerPage > rows ? rows.length : rowPerPage} isLoading={isLoading}>
      {isLoading && (
        <div className="loader">
          <Loader />
        </div>
      )}
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, i) => (
                <TableCell key={i}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 && (
              <TableRow>
                <TableCell colSpan={headers?.length ?? 99} className="event-name">
                  <Box p={5} textAlign={'center'}>
                    Brak danych
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {rows.map((row, i) => {
              const high = theme.palette.charts.red;
              const mid = theme.palette.charts.yellow;
              const low = theme.palette.charts.green;

              const priorityColor =
                row.priority === PRIORITY_HIGH ? high : row.priority === PRIORITY_MEDIUM ? mid : low;

              return (
                <TableRowStyled read={row.read} key={i}>
                  {Object.entries(row).map(([key, value]) => {
                    const uniqueKey = `${value}_${i}_${key}`;

                    if (key === 'read' || key === 'id' || key === 'slug' || key === 'instances') {
                      return false;
                    }

                    if (key === 'date') {
                      return (
                        <TableCell key={uniqueKey} className="event-date" style={{ width: '80px' }}>
                          <p>{dayjs(value).format(LABEL_DATE_FORMAT)}</p>
                          <p>{dayjs(value).format(LABEL_TIME_FORMAT)}</p>
                        </TableCell>
                      );
                    }

                    if (key === 'timestamp') {
                      return (
                        <TableCell key={uniqueKey} className="event-date" style={{ width: '80px' }}>
                          {!!new Date(value).getTime() && (
                            <>
                              <p>{dayjs.unix(value).format(LABEL_DATE_FORMAT)}</p>
                              <p>{dayjs.unix(value).format(LABEL_TIME_FORMAT)}</p>
                            </>
                          )}
                        </TableCell>
                      );
                    }

                    if (key === 'priority') {
                      return (
                        <TableCell key={uniqueKey} className="event-priority">
                          <span style={{ backgroundColor: priorityColor }}>{value}</span>
                        </TableCell>
                      );
                    }
                    if (key === 'idName') {
                      return (
                        <TableCell key={uniqueKey} className="event-name">
                          <span>{value}</span>
                        </TableCell>
                      );
                    }
                    if (key === 'name') {
                      const content = (
                        <div key={uniqueKey}>
                          <span>{`${matchEventDefinitions(value, list)}${row.instances || ''}`}</span>
                          {level === 3 && isModalButton !== false && (
                            <span className="view-button">
                              <TableOutlinedButton onClick={() => handleReadEvent(row.id)} variant="outlined">
                                Zobacz
                              </TableOutlinedButton>
                            </span>
                          )}
                        </div>
                      );
                      return (
                        <TableCell key={uniqueKey} className="event-name">
                          {row.slug ? <Link to={row.slug || ''}>{content}</Link> : content}
                        </TableCell>
                      );
                    }

                    if (key === 'recommendation') {
                      return <RecommendationCell key={uniqueKey} data={value} />;
                    }

                    if (key === 'recommendationType') {
                      return <RecommendationTypeCell key={uniqueKey} data={value} />;
                    }

                    if (key === 'action') {
                      let content;
                      if (value?.type === 'link') {
                        content = (
                          <MuiLink href={value.url} target="_blank" rel="noopener" variant="body2">
                            {value.body}
                          </MuiLink>
                        );
                      }
                      if (value?.type === 'callback') {
                        content = (
                          <Button
                            variant="text"
                            onClick={() => value.onClick({ id: value.id, timestamp: value.params?.timestamp ?? '' })}
                          >
                            {value.body}
                          </Button>
                        );
                      }
                      return (
                        <TableCell key={uniqueKey} className="action-cell">
                          {content}
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={uniqueKey} className="event-cell">
                        {value}
                      </TableCell>
                    );
                  })}
                  {showActionButton && (
                    <TableCell className="event-options">
                      <IconButton onClick={(e) => handleMenuClick(e, row.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRowStyled>
              );
            })}
          </TableBody>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                handleReadEvent(currentRow);
                handleClose();
              }}
            >
              Zobacz
            </MenuItem>
            <MenuItem onClick={handleClose}>Oznacz jako przeczytane</MenuItem>
            <MenuItem onClick={handleClose}>Ocen zdarzenie</MenuItem>
          </Menu>
        </Table>
      </TableContainer>
    </TableComponentStyled>
  );
};
