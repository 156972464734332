import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DynamicSelectStyled } from './DynamicSelectStyled.js';
import { FormHelperText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const DynamicSelect = ({ options, handleSelect, initialValue, label, displayFirstLabel }) => {
  const [value, setValue] = React.useState('');

  const handleChange = (event, name) => {
    if (handleSelect) {
      handleSelect(event.target.value);
    }
    setValue(event.target.value);
  };

  React.useEffect(() => {
    if (initialValue) {
      handleSelect(initialValue);
      setValue(initialValue);
    }
  }, []);

  return (
    <DynamicSelectStyled>
      <FormControl sx={{ m: 1, minWidth: 100 }}>
        {label && <FormHelperText>{label}</FormHelperText>}
        <Select
          value={value}
          IconComponent={KeyboardArrowDownIcon}
          onChange={(event, name) => handleChange(event, name)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {displayFirstLabel && (
            <MenuItem value="">
              <em>{'Wybierz'}</em>
            </MenuItem>
          )}
          {options.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value} name={item.label}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </DynamicSelectStyled>
  );
};
