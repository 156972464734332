import { AuthContainer } from '../../../components/AuthContainer';
import { SetNewPasswordForm } from './SetNewPasswordForm';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { AuthenticationException } from '../../../services/auth/exceptions/AuthenticationException';
import { setNewPassword } from '../../../store/auth/auth.actions';

export const SetNewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitComplete = async (data) => {
    const results = await dispatch(setNewPassword(data));
    if (results?.error) {
      if (results?.payload instanceof AuthenticationException) {
        enqueueSnackbar(results.payload.message, { variant: 'error' });
      } else enqueueSnackbar('Błąd w trakcie zmiany hasła', { variant: 'error' });
    }

    if (results.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar('Hasło zmienione poprawnie, możesz się teraz zalogować', { variant: 'success' });
      navigate('/login');
    }
  };

  return (
    <AuthContainer title={'Ustal nowe hasło'}>
      <SetNewPasswordForm onSubmitComplete={onSubmitComplete} />
    </AuthContainer>
  );
};
