import styled from '@emotion/styled';

export const TableComponentStyled = styled.div`
  margin-top: 40px;
  position: relative;

  @media (max-width: 767px) {
    position: relative;
    overflow: auto;
    height: ${({ nrOfRows }) => nrOfRows * 80 + 'px'};

    & > div:first-of-type {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    overflow-x: scroll;
  }

  table {
    position: relative;
    opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
  }

  .loader {
    position: absolute;
    top: 50%;
    margin: 0 auto;
    left: calc(50% - 40px);
  }

  th:not(:first-of-type) {
    text-align: center;
  }
  td:not(:first-of-type) {
    text-align: center;
  }

  .MuiTableCell-root {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .MuiTableHead-root {
    .MuiTableCell-root {
      color: #566a7f;
      font-weight: 500;
      font-size: 0.8rem;
    }
  }

  .event-cell {
    color: #697a8d;
    line-height: 23px;
  }

  .event-date {
    p:first-of-type {
      color: #435971;
      font-size: 0.75rem;
      line-height: 23px;
    }
    p:last-of-type {
      color: #a1acb8;
      font-size: 0.8rem;
      line-height: 20px;
    }
  }

  .event-priority {
    span {
      padding: 5px 21px;
      border-radius: 4px;
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 18px;
      color: #566a7f;
    }
  }

  .event-name {
    color: #697a8d;
    line-height: 23px;
    button {
      margin-left: 10px;
    }
  }
`;

export const PaginationStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
`;
