import styled from '@emotion/styled';

export const CreatorCancelButtonStyled = styled.button`
  background: white;
  color: #566a7f;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  height: 50px;
  width: 350px;
  margin-right: 40px;
  margin-top: 26px;
  border: 1px solid #0d6dc5;
  border-radius: 4px;
  cursor: pointer;
`;

export const CreatorSaveButtonStyled = styled.button`
  background: #0d6dc5;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  height: 50px;
  width: 350px;
  margin-right: 40px;
  margin-top: 26px;
  border: 1px solid #0d6dc5;
  border-radius: 4px;
  cursor: pointer;

  :disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;

export const CreatorRemoveButtonStyled = styled.button`
  background: #e41d1d;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  height: 50px;
  width: 350px;
  margin-right: 40px;
  margin-top: 26px;
  border: 1px solid #e41d1d;
  border-radius: 4px;
  cursor: pointer;

  :disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;
