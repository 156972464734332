import { AddWarehouseMainTitle, AddWarehouseStyled, AddWarehouseTitle } from './AddWarehouseStyled';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { SubmitButton } from '../../../../components/SubmmitButton';
import { FormInputText } from '../../../../components/FormComponents/FormInputText';
import { useAddWarehouseForm } from './useAddWarehouseForm';
import { Loader } from '../../../../components/Loader/Loader';

export const AddWarehouse = ({
  handleCreateWarehousePlane,
  isEditor,
  onPrevStep,
  navigateTo,
  initialValues,
  isLoading,
}) => {
  const { control, handleSubmit, isSubmitting, onSubmit } = useAddWarehouseForm(
    initialValues,
    onPrevStep,
    navigateTo,
    isEditor
  );

  return (
    <>
      <AddWarehouseStyled>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddWarehouseTitle>{'Dodaj nowy magazyn'}</AddWarehouseTitle>
          <Grid2 container>
            <Grid2 xs={12} lg={6}>
              <AddWarehouseMainTitle>{'Nazwa magazynu'}</AddWarehouseMainTitle>
              <FormInputText name={'name'} control={control} isSubmitting={isSubmitting} />
            </Grid2>
          </Grid2>
          <div className="wrapper">
            <SubmitButton text={isEditor ? 'Zmień dane' : 'Dodaj magazyn'} />
          </div>
        </form>
      </AddWarehouseStyled>
      {isEditor && (
        <AddWarehouseStyled>
          <AddWarehouseTitle>{'Stwórz plan magazynu'}</AddWarehouseTitle>
          <AddWarehouseMainTitle>{'Stwórz plan swojego magazynu za pomocą naszego edytora.'}</AddWarehouseMainTitle>
          <div className="wrapper" onClick={handleCreateWarehousePlane(initialValues?.plan_exists, initialValues?.id)}>
            {isLoading ? (
              <Loader />
            ) : (
              <SubmitButton
                type="button"
                text={initialValues?.plan_exists ? 'Edytuj plan magazynu' : 'Stwórz plan magazynu'}
              />
            )}
          </div>
        </AddWarehouseStyled>
      )}
    </>
  );
};
