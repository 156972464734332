import dayjs from 'dayjs';

export const convertBase64ToUrl = async (dataUrl, fileName) => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();

  return new File([blob], fileName, { type: 'image/png' });
};

export const convertFileToBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const checkIsJSON = (str) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const restParamsObject = (searchParams, filter = []) => {
  let paramsObject = {};

  for (const [key, value] of searchParams.entries()) {
    if (filter.length) {
      if (filter.find((el) => el === key)) {
        paramsObject[key] = value;
      }
    } else {
      paramsObject[key] = value;
    }
  }
  return paramsObject;
};

export const setUrlPageParamOnStart = (url) => {
  const page = new RegExp(/page=([1-9]\d*)/);
  return url.replace(page, 'page=1');
};

export const matchEventDefinitions = (name, list) => {
  const definition = list.find((item) => item.name === name);

  return definition?.description || name;
};

export const matchExtendedEventDefinition = (name, list, matchExtendedEventDefinition) => {
  const definition = list.find((item) => name.includes(item.name));

  if (matchExtendedEventDefinition) {
    return definition?.description;
  }

  if (definition) {
    return name.replace(definition.name, definition.description);
  }

  return definition?.description || name;
};

export const isEmpty = (obj) =>
  obj === undefined || obj === {} || obj === null ? true : Object.keys(obj).length === 0;

export const setOffset = (searchParams, limit, page) => {
  const urlPage = Number(searchParams.get('page')) || 0;
  const urlLimit = Number(searchParams.get('limit')) || 20;
  const handlePage = urlPage - 1;
  const preparedPage = handlePage < 0 ? 0 : handlePage;

  if (limit) {
    return limit * preparedPage;
  }

  if (page) {
    return urlLimit * (page - 1);
  }
  return urlLimit * preparedPage;
};

export const getGranularity = (timestampFrom, timestampTo) => {
  const diff = dayjs(timestampFrom).diff(timestampTo, 'month');
  switch (diff) {
    default:
      return 'day';
  }
};

export const fixSvg = (svg) => {
  const regex = /height=([\d\.]+)\s/gm;
  const subst = `height="$1" `;
  return svg.replace(regex, subst);
};
