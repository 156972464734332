import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box, Tabs, Tab } from '@mui/material';
import { Events } from './Events/Events';
import { Statistics } from './Statistics/Statistics';
import { DateRangePickerComponent } from '../../components/DateRangePicker/DateRangePicker';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { WarehouseDetailsStyled } from './WarehouseDetailsStyled';
import { useSelector } from 'react-redux';
import { appConfigState } from '../../store/appConfig/appConfig.selector';
import { warehouseDetailsPagesTexts } from '../../data/warehouseDetailsPagesTexts';
import { useWarehouseDetails } from './useWarehouseDetails';

const tabs = [
  {
    title: warehouseDetailsPagesTexts.nav.tab1Title,
    imgScr: '/static/transactions-icon.png',
  },
  {
    title: warehouseDetailsPagesTexts.nav.tab2Title,
    imgScr: '/static/graph-up-icon.png',
  },
];

export const WarehouseDetails = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { activeWarehouseId } = useSelector(appConfigState);
  const { onDateChange, dateValue, timestampParams } = useWarehouseDetails();

  const handleTabs = (_, newValue) =>
    newValue === 0
      ? navigate(
          `/warehouse-details/${activeWarehouseId}/events/type?${createSearchParams(timestampParams).toString()}`
        )
      : navigate(`/warehouse-details/${activeWarehouseId}/stats?${createSearchParams(timestampParams).toString()}`);

  return (
    <WarehouseDetailsStyled>
      <Grid2
        container
        justifyContent={{ xs: 'center', md: 'space-between' }}
        alignItems="center"
        className="warehouse-header"
        spacing={3}
      >
        <Grid2>
          <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
            <Tabs value={pathname.includes('events') ? 0 : 1} onChange={handleTabs} scrollButtons="auto">
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={
                    <div>
                      <img src={tab.imgScr} alt={tab.title} />
                      <span dangerouslySetInnerHTML={{ __html: tab.title }} />
                    </div>
                  }
                />
              ))}
            </Tabs>
          </Box>
        </Grid2>

        <Grid2 className="date-picker-container">
          <div>
            <p>{warehouseDetailsPagesTexts.dateTimePicker.title}</p>
            <DateRangePickerComponent onChange={onDateChange} value={dateValue} />
          </div>
        </Grid2>

        {pathname.includes('events') && <Events />}
        {pathname.includes('stats') && <Statistics />}
      </Grid2>
    </WarehouseDetailsStyled>
  );
};
