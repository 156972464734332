import { Button, InputAdornment } from '@mui/material';
import React, { memo } from 'react';
import { Modal } from '../../../components/Modal/Modal';
import { ModalContent } from '../../../components/Modal/ModalContent';
import { InputComponent } from '../../../components/InputComponent';

const ModalDistance = ({ isModalOpen, handleClose, handleChange, handleSubmit }) => {
  return (
    <Modal
      closeByClickedOutside={true}
      isModalOpen={isModalOpen}
      closeModal={handleClose}
      maxWidth="lg"
      backgroundColor={'rgba(0, 0, 0, 0.75)'}
    >
      <ModalContent
        headerTitle={'Ile cm odpowiada w rzeczywistości zaznaczony element'}
        handleCloseButton={handleClose}
        body={
          <InputComponent
            label="Długość odcinka"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="start">cm</InputAdornment>,
            }}
            handleChange={handleChange}
          />
        }
        footer={<Button onClick={handleSubmit}>Zatwierdź</Button>}
      />
    </Modal>
  );
};
export default memo(ModalDistance);
