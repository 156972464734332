export const messages = {
  creator: {
    content: {
      title: 'Stwórz proces',
      description:
        'Stwórz nowy proces poprzez przeciąganie wybranych elementy (zdarzenia lub akcję) na prawą stronę kompozytora.',
    },
    header: {
      title: 'Stwórz nowy proces',
      description:
        'Stwórz nowy proces korzystając z kompozytora procesów. Wybierz zdarzenia, które mają być monitorowane, a naśtepnie zdefiniuj akcję jaka ma się wydarzyć w momencie wystąpienia danego zdarzenia.',
    },
  },
  lists: {
    addProcess: {
      title: 'Twoje Procesy',
      createdProcess: 'Stworzone procesy:',
      createNewProcess: 'Stwórz nowy process',
    },
    history: {
      description: 'Historia procesów',
      title: 'Zobacz zdarzenia, które miały miejsce w Twoich magazynach w określonym przedziale czasowym.',
    },
    mainList: {
      title: 'Lista Procesów',
      description: 'Zobacz listę  wykorzystywanych przez Ciebie procesów.',
    },
    predefined: {
      title: 'Predefiniowane procesy',
      description: 'Zobacz listę predefiniowanych procesów i dodaj je do swojego projektu.',
    },
  },
  reports: {
    addReport: {
      title: 'Twoje raporty',
      count: 'Stworzone raporty:',
      button: 'Stwórz nowy raport',
    },
    title: 'Lista raportów',
    description: 'Zobacz listę raportów generowanych w ramach Twojego projektu.',
    predefinedTitle: 'Predefiniowane raporty',
    predefinedDescription: 'Zobacz listę predefiniowanych raportów i dodaj je do swojego projektu.',
  },
  button: {
    switchedOn: 'Włączony',
    switchedOff: 'Wyłączony',
    switchOn: 'Włącz',
    switchOff: 'Wyłącz',
    add: 'Dodaj',
    remove: 'Usuń',
    shows: 'Podgląd',
    see: 'Zobacz',
    edit: 'Edytuj',
  },
  label: {
    yes: 'TAK',
    no: 'NIE',
  },
  process: {
    process: 'PROCESS',
    used: 'UŻYWANY',
    actions: 'AKCJE',
    status: 'STATUS',
    date: 'DATA',
  },
};
