import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEventsByType } from '../../../api/events';
import { EVENT_TYPE_TIMELINE } from '../../../constants';

export const fetchTimeline = createAsyncThunk(
  'timeline/fetchTimeline',
  async ({ warehouseId, key, params, eventType }, { getState }) => {
    return await getEventsByType({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      eventType: EVENT_TYPE_TIMELINE,
      params: { ...params, granularity: 'day' },
      data: { ...getState().auth.tokens },
    });
  }
);
