import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { onboardingWarehouse } from '../../data/createWarehouse';
import ContentBox from './components/ContentBox';
import NavigationWarehouse from './components/NavigationOnboarding';

const OnboardingSummary = () => {
  const { step, header, description, stepId } = onboardingWarehouse[4];
  const navigate = useNavigate();

  return (
    <>
      <ContentBox title={header} subtitle={step} description={description} />
      <NavigationWarehouse handleNextStep={() => navigate('/my-account')} stepId={stepId} />
    </>
  );
};
export default memo(OnboardingSummary);
