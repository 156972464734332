import React from 'react';
import { FormInputText } from '../../../components/FormComponents/FormInputText';
import { FormButton } from '../../../components/FormComponents/FormButton';
import { AuthFormStyled } from '../authFormStyled';
import { useSetNewPassword } from './useSetNewPassword';
import { Link } from 'react-router-dom';

export const SetNewPasswordForm = ({ onSubmitComplete }) => {
  const { control, handleSubmit, formState } = useSetNewPassword();

  return (
    <AuthFormStyled>
      <FormInputText name={'email'} label={'Adres e-mail'} control={control} isSubmitting={formState.isSubmitting} />

      <FormInputText
        name={'verificationCode'}
        label={'Kod weryfikacyjny'}
        control={control}
        type={'text'}
        isSubmitting={formState.isSubmitting}
      />

      <FormInputText
        name={'password'}
        label={'Hasło'}
        control={control}
        type={'password'}
        isSubmitting={formState.isSubmitting}
      />

      <FormInputText
        name={'passwordConfirm'}
        label={'Potwierdz hasło'}
        control={control}
        type={'password'}
        isSubmitting={formState.isSubmitting}
      />
      <FormButton text={'Wyślij'} textAlign={'center'} align={'center'} onClick={handleSubmit(onSubmitComplete)} />
      <div className="form-options">
        <Link to={'/login'}>Zaloguj się</Link>
      </div>
    </AuthFormStyled>
  );
};
