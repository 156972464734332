import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { appConfigState } from '../../../store/appConfig/appConfig.selector';
import { setActiveWarehouseId } from '../../../store/appConfig/appConfig.reducer';

export const DrawerStoreSelector = ({ options, title }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const { warehouseId } = useParams();
  const { activeWarehouseId, isAnyWarehouseActive } = useSelector(appConfigState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => setIsOpen(!isOpen);
  const handleNavigate = (id) => () => {
    navigate(`/dashboard/${id}`);
    dispatch(setActiveWarehouseId(id));
  };
  React.useEffect(() => {
    if (warehouseId !== activeWarehouseId) {
      dispatch(setActiveWarehouseId(Number(warehouseId)));
    }
  }, [warehouseId, activeWarehouseId]);

  return (
    <ListStyled>
      <ListItemButton onClick={handleClick} className="expand-button">
        <ListItemText primary={title} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options.map(
            (item, index) =>
              item.active && (
                <ListButtonStyled
                  key={item.id}
                  onClick={handleNavigate(item.id)}
                  active={item.id === activeWarehouseId}
                  disabled={!isAnyWarehouseActive}
                >
                  <ListItemIcon>
                    <span className="icon">
                      <span />
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={item.name} className="list-item" />
                </ListButtonStyled>
              )
          )}
        </List>
      </Collapse>
    </ListStyled>
  );
};

const ListStyled = styled(List)`
  padding: 0 15px;

  .expand-button {
    background-color: rgba(13, 109, 197, 0.5);
    border-radius: 6px;
    color: #0d6dc5;
    line-height: 23px;
  }
`;

const ListButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})`
  :disabled {
    cursor: not-allowed;
  }

  .list-item span {
    color: #566a7f;
    opacity: ${({ active }) => (active ? 1 : 0.5)};
    overflow: hidden;
  }

  .icon {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: rgba(13, 109, 197, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${({ active }) => (active ? 1 : 0.5)};

    span {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: rgba(13, 109, 197, 1);
    }
  }
`;
