import styled from '@emotion/styled';
import React from 'react';
import { Box } from '@mui/system';
import { Fade, Typography } from '@mui/material';

export const AuthContainer = ({ title, description, children }) => {
  return (
    <AuthContainerStyled>
      <Fade in timeout={400}>
        <div>
          <Box align={'center'} sx={{ mb: 5 }}>
            <img src="/static/lumilook-logo.svg" alt="Lumilook logo" />
            <p className={'title'}>{title}</p>
          </Box>
          {description && (
            <Box sx={{ pb: 4 }}>
              <Typography variant={'body2'}>{description}</Typography>
            </Box>
          )}
          {children}
        </div>
      </Fade>
    </AuthContainerStyled>
  );
};

const AuthContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  & > div {
    background-color: #fff;
    border-radius: 6px;
    padding: 30px;
    width: 42%;
    max-width: 600px;
    @media (max-width: 767px) {
      width: 90%;
    }
  }

  img {
    width: 50%;
    height: auto;
    object-fit: contain;
  }

  p.title {
    margin-top: 30px;
    line-height: 19px;
    font-size: 1.1rem;
    color: #697a8d;
  }
`;
