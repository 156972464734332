import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ContentBox from './components/ContentBox';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import AddIcon from '@mui/icons-material/Add';
import NavigationWarehouse from './components/NavigationOnboarding';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import MenuItems from './components/MenuItems';
import { useDispatch, useSelector } from 'react-redux';
import { setImageCropped, setImageCroppedProperties } from '../../store/onboarding/onboarding.reducer';
import { onboardingWarehouse } from '../../data/createWarehouse';
import { GridContainer } from './components/GridContainerStyled';
import { CropperStyled } from './components/CropperStyled';
import { onBoardingReconstructionState, onboardingState } from '../../store/onboarding/onboarding.selector';

const CustomizeWarehouse = ({ onPrevStep, onNextStep }) => {
  const { step, header, description } = onboardingWarehouse[1];
  const dispatch = useDispatch();
  const { file, stepSource } = useSelector(onboardingState);
  const { image_raw } = useSelector(onBoardingReconstructionState);

  const [zoom, setZoom] = useState(0);
  const [image, setImageSrc] = useState(null);
  const [cropper, setCropper] = useState();
  // const minHeight = `${(props) => (!props.isMinHeight ? '60vh' : '0px')}`;

  useEffect(() => {
    if (stepSource === 'AccountEditPlan') {
      setImageSrc(image_raw);
    }

    if (file !== null && stepSource !== 'AccountEditPlan') {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file[0]);
    }
  }, [file]);

  const handleZoomChange = useCallback(
    (type) => {
      const isAdd = type === 'add';
      const newZoom = isAdd ? zoom + 0.25 : zoom - 0.25;

      if (newZoom >= 0) {
        setZoom(newZoom);
      }
    },
    [zoom]
  );

  const handleCropChange = useCallback(
    (shouldSaveInStore = false) => {
      const imgSrc = cropper.getCroppedCanvas().toDataURL();
      const imgData = cropper.getData();
      if (shouldSaveInStore) {
        dispatch(setImageCropped(imgSrc));
        dispatch(setImageCroppedProperties(imgData));
      }
    },
    [cropper, dispatch]
  );

  const handleRotate = useCallback(() => {
    cropper.rotate(90);
    handleCropChange();
  }, [cropper, handleCropChange]);

  const handleCrop = useCallback(async () => {
    try {
      if (typeof cropper !== 'undefined') {
        handleCropChange(true);
        onNextStep();
      }
    } catch (e) {
      console.error(e);
    }
  }, [cropper, handleCropChange, onNextStep]);

  const getMenuItems = useMemo(
    () => [
      { Icon: AddIcon, handleClick: () => handleZoomChange('add') },
      { Icon: RemoveIcon, handleClick: () => handleZoomChange('remove') },
      { Icon: RestartAltIcon, handleClick: handleRotate, isDisabled: false },
    ],
    [handleRotate, handleZoomChange]
  );

  return (
    <>
      <ContentBox title={step} subtitle={header} description={description}>
        <Grid2 container xs={11} style={{ margin: 'auto' }}>
          <GridContainer maxHeight={'100vh'}>
            {image && (
              <CropperStyled
                viewMode={1}
                dragMode="move"
                zoomTo={zoom}
                src={image}
                background={false}
                zoomOnTouch={false}
                zoomOnWheel={false}
                style={{ height: '80vh', width: '100%' }}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={false}
              />
            )}
          </GridContainer>
        </Grid2>
        <MenuItems zoom={zoom} menu={getMenuItems} />
        <NavigationWarehouse handleNextStep={handleCrop} handleBackStep={onPrevStep} />
      </ContentBox>
    </>
  );
};
export default CustomizeWarehouse;
