import styled from '@emotion/styled';
import { Paper } from '@mui/material';

export const ModalStyled = styled(Paper)`
  & {
    background-color: pink;
  }

  .MuiDialog-paper {
    background-color: pink;
  }
`;
