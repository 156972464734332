import React, { useEffect, useState } from 'react';
import { InfoWidgetStyled } from './InfoWidgetStyled';
import { useSelector } from 'react-redux';
import { Loader } from '../../../components/Loader/Loader';
import { useInfoWidget } from './useInfoWidget';

export const InfoWidget = ({ icon, title, subTitle, selector }) => {
  const [widgetData, setWidgetData] = useState([]);
  const { isLoading, data } = useSelector(selector);

  const { count, change } = useInfoWidget(widgetData);

  useEffect(() => {
    setWidgetData(data);
  }, [data]);

  return (
    <InfoWidgetStyled isChangePositive={change > 0}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="header">{icon && <img src={icon} alt={title} />}</div>
          <div>
            <p className="title">{title}</p>
            <p className="sub-title">{subTitle}</p>
          </div>
          <div className="number">{count}</div>
          <div className="change">
            {change > 0 && <img src="/static/bx-down-arrow-alt-top.svg" alt="arrow up" />}
            {change < 0 && <img src="/static/bx-down-arrow-alt.svg" alt="arrow-down" />}
            <span>{change}%</span>
          </div>
        </>
      )}
    </InfoWidgetStyled>
  );
};
