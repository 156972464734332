import { UserHeaderStyled } from './UserHeaderStyled';
import Grid2 from '@mui/material/Unstable_Grid2';

export const UserHeader = () => {
  return (
    <></>
    // <UserHeaderStyled>
    //   <div className="header">{'Konto użytkownika'}</div>
    //   <div className="wrapper">
    //     <Grid2 xs={6} md={6} lg={6}>
    //       <p>Nazwa konta</p>
    //       <span>{'Konto Testowe'}</span>
    //     </Grid2>
    //     <Grid2 xs={6} md={6} lg={6}>
    //       <p>{'Rodzaj konta'}</p>
    //       <span>{'Konto premium (3magazyny)'}</span>
    //     </Grid2>
    //   </div>
    // </UserHeaderStyled>
  );
};
