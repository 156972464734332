import { MapChartStyled } from './MapChartStyled';
import * as L from 'leaflet';
import { ImageOverlay, MapContainer, useMap, ZoomControl, useMapEvents, LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { HeatMapWrapper } from './HeatMapWrapper/HeatMapWrapper';
import { memo, useEffect, useState } from 'react';
import { Loader } from '../Loader/Loader';
import { LoaderWrapper } from '../../pages/Onboarding/OnBoardingViewEditor/OnBoardingViewEditorStyled';
import { RecommendationsCluster } from './RecommendationsCluster/RecommendationsCluster';
import { convertFileToBase64, fixSvg } from '../../utils/utils';
import { EventsCluster } from './EventsCluster/EventsCluster';

const MapChart = ({ recommendations, events, heatmapData, svg, sceneDimensions, isLoading }) => {
  const [bounds, setBounds] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [mapReady, setMapReady] = useState(false);
  const [base64Image, setBase64Image] = useState(null);

  const onMapReady = () => {
    setMapReady(true);
  };
  useEffect(() => {
    if (sceneDimensions?.width && sceneDimensions?.length) {
      setMapBounds([sceneDimensions.width / 2, sceneDimensions.length / 2]);
      setBounds([
        [0, sceneDimensions.width],
        [sceneDimensions.length, 0],
      ]);
    }
  }, [sceneDimensions]);

  const getBase64File = async (svg) => {
    return await convertFileToBase64(new Blob([fixSvg(svg)], { type: 'image/svg+xml' }));
  };
  useEffect(() => {
    getBase64File(svg).then((r) => setBase64Image(r));
  }, [svg]);

  return (
    <>
      <MapChartStyled minHeight={'60vh'}>
        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {!!bounds && !!mapBounds && !!svg && (
          <MapContainer
            id={'map-container'}
            bounds={mapBounds}
            center={mapBounds}
            zoom={3}
            maxZoom={8}
            minZoom={-10}
            zoomSnap={0.5}
            zoomDelta={0.5}
            crs={L.CRS.Simple}
            zoomControl={false}
            attributionControl={false}
            whenReady={onMapReady}
            // scrollWheelZoom={false}
          >
            <MapController imgWidth={sceneDimensions.width} imgHeight={sceneDimensions.length} />
            <LayersControl position="topright">
              {heatmapData && (
                <LayersControl.Overlay checked name="Heatmap">
                  <HeatMapWrapper points={heatmapData} />
                </LayersControl.Overlay>
              )}

              {recommendations && (
                <LayersControl.Overlay checked name="Rekomendacje">
                  <RecommendationsCluster points={recommendations} mapIsReady={mapReady} />
                </LayersControl.Overlay>
              )}

              {events && (
                <LayersControl.Overlay checked name="Zdarzenia">
                  <EventsCluster points={events} mapIsReady={mapReady} />
                </LayersControl.Overlay>
              )}

              {base64Image && (
                <LayersControl.Overlay checked name="Plan magazynu">
                  <ImageOverlay bounds={bounds} url={base64Image} className={'bg--white svg-map'} />
                </LayersControl.Overlay>
              )}
            </LayersControl>
            <ZoomControl position="bottomright" />
            <MapEvents />
          </MapContainer>
        )}
      </MapChartStyled>
    </>
  );
};

const MapEvents = () => {
  useMapEvents({
    click(e) {
      // console.log(points);
      // points.push([e.latlng.lat, e.latlng.lng, 1]);
      // setState your coords here
      // coords exist in "e.latlng.lat" and "e.latlng.lng"
      // console.log(e.latlng.lat);
      // console.log(e.latlng.lng);
    },
  });
  return false;
};

function MapController({ imgWidth, imgHeight }) {
  const map = useMap();

  const group = new L.featureGroup();
  new L.Marker([0, imgWidth]).addTo(group);
  new L.Marker([imgHeight, imgWidth]).addTo(group);
  new L.Marker([imgHeight, 0]).addTo(group);
  new L.Marker([0, 0]).addTo(group);

  map.fitBounds(group.getBounds());
  return null;
}
export default memo(MapChart);
