import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEventsByType } from '../../api/events';
import { EVENT_TYPE_TIMELINE, EVENT_TYPE_TYPE, EVENTS_SORT_BY_COLUMN, SORT_ORDER } from '../../constants';
import { dateRanges } from '../../utils/dateRanges';

export const fetchEventsForLastDayWidget = createAsyncThunk(
  'dashboard/fetchEventsForLastDayWidget',
  async (_, { dispatch }) => {
    const [r1, r2] = await Promise.all([
      dispatch(
        fetchEventByDate({
          dataSet: 'current',
          params: { ...dateRanges.lastDay.current, sort: `${EVENTS_SORT_BY_COLUMN}:${SORT_ORDER}` },
        })
      ),
      dispatch(
        fetchEventByDate({
          dataSet: 'previous',
          params: { ...dateRanges.lastDay.previous, sort: `${EVENTS_SORT_BY_COLUMN}:${SORT_ORDER}` },
        })
      ),
    ]);
    return [r1, r2];
  }
);

export const fetchEventsForLast7DaysWidget = createAsyncThunk(
  'dashboard/fetchEventsForLast7DaysWidget',
  async (_, { dispatch }) => {
    const [r1, r2] = await Promise.all([
      dispatch(
        fetchEventByDate({
          dataSet: 'current',
          params: { ...dateRanges.last7Days.current, sort: `${EVENTS_SORT_BY_COLUMN}:${SORT_ORDER}` },
        })
      ),
      dispatch(
        fetchEventByDate({
          dataSet: 'previous',
          params: { ...dateRanges.last7Days.previous, sort: `${EVENTS_SORT_BY_COLUMN}:${SORT_ORDER}` },
        })
      ),
    ]);
    return [r1, r2];
  }
);
export const fetchEventsForLast30DaysWidget = createAsyncThunk(
  'dashboard/fetchEventsForLast30DaysWidget',
  async (_, { dispatch }) => {
    const [r1, r2] = await Promise.all([
      dispatch(
        fetchEventByDate({
          dataSet: 'current',
          params: { ...dateRanges.last30Days.current, sort: `${EVENTS_SORT_BY_COLUMN}:${SORT_ORDER}` },
        })
      ),
      dispatch(
        fetchEventByDate({
          dataSet: 'previous',
          params: { ...dateRanges.last30Days.previous, sort: `${EVENTS_SORT_BY_COLUMN}:${SORT_ORDER}` },
        })
      ),
    ]);
    return [r1, r2];
  }
);

export const fetchEventsLast7DaysGroupedByDay = createAsyncThunk(
  'dashboard/eventsLast7DaysGroupedByDay',
  async (_, { dispatch }) => {
    const response = await dispatch(
      fetchEventByDate({
        dataSet: 'current',
        params: { last: 7, granularity: 'day' },
        eventType: EVENT_TYPE_TIMELINE,
      })
    );

    return response.payload;
  }
);

export const fetchEventByDate = createAsyncThunk(
  'dashboard/fetchEventByDate',
  async ({ key, params, eventType }, { getState }) => {
    return await getEventsByType({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      eventType: eventType ? eventType : EVENT_TYPE_TYPE,
      params: { ...params },
      data: { ...getState().auth.tokens },
    });
  }
);
