import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { eventsHistogramData } from '../../../store/warehouseDetails/histogram/eventsHistogram.selector';
import { useEffect, useState } from 'react';
import { fetchEventsHistogram } from '../../../store/warehouseDetails/histogram/eventsHistogram.action';
import { restParamsObject } from '../../../utils/utils';
import { reconstructionState } from '../../../store/reconcstruction/reconstruction.selector';
import { fetchWarehouseReconstructionWithSvg } from '../../../store/reconcstruction/reconstruction.action';

export const useEventsHistogram = () => {
  const gridSizeInCm = 100;
  const dispatch = useDispatch();
  const { data: histogramData, isLoading: isHistogramLoading } = useSelector(eventsHistogramData);
  const {
    data: reconstructionData,
    isLoading: isReconstructionLoading,
    status: reconstructionStatus,
  } = useSelector(reconstructionState);
  const [svg, setSvg] = useState(null);
  const [sceneDimensions, setSceneDimensions] = useState(null);
  const [searchParams] = useSearchParams();
  const [heatMapPoints, setHeatMapPoints] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    dispatch(fetchWarehouseReconstructionWithSvg());
    setIsInitialized(true);
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchEventsHistogram({ params: { ...restParamsObject(searchParams, ['timestamp_from', 'timestamp_to']) } })
    );
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (isReconstructionLoading === false && reconstructionStatus === 'fulfilled' && isInitialized) {
      setSvg(reconstructionData.svg);
      setSceneDimensions(JSON.parse(reconstructionData.scene_dimensions));
    }
  }, [isReconstructionLoading, reconstructionData, reconstructionStatus, isInitialized]);

  useEffect(() => {
    if (!!histogramData?.length) {
      //[x,y,count]
      const transformed = histogramData.map((item) => {
        return [item?.start_x * gridSizeInCm, item?.start_y * gridSizeInCm, item?.count];
      });
      setHeatMapPoints(transformed);
    }
  }, [histogramData]);

  return {
    histogramData,
    isEventsHistogramLoading: isHistogramLoading || isReconstructionLoading,
    svg,
    sceneDimensions,
    heatMapPoints,
  };
};
