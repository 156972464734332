import React from 'react';

export const useIframePlanner = () => {
  const [isIframeError, setIframeError] = React.useState(false);
  const [isIframeLoading, setIframeLoading] = React.useState(true);

  const handleIframeError = () => setIframeError(true);
  const handleIframeLoading = () => setIframeLoading(false);

  return {
    isIframeError,
    isIframeLoading,
    handleIframeError,
    handleIframeLoading,
  };
};
