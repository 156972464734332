import { DynamicSelect } from '../../../../components/DynamicSelect/DynamicSelect';
import { CaseType } from '../../ProcessCompositionCreator/ProcessCompositionButton/ProcessCompositionButtons';
import { InputComponent } from '../../../../components/InputComponent';
import { Button } from '@mui/material';
import { DiagramCaseDetailsStyled } from './DiagramCaseDetailsStyled';
import React from 'react';
import { useSelector } from 'react-redux';
import { processState } from '../../../../store/process/process.selector';
import { prepareCase, preparedKeys } from './diagramCaseDetails.helper';
import { eventDefinitionState } from '../../../../store/appConfig/appConfig.selector';
import { isEmpty } from '../../../../utils/utils';
import { FormInputText } from '../../../../components/FormComponents/FormInputText';
import FormInputSelect from '../../../../components/FormComponents/FormInputSelect';

export const DiagramCaseDetails = ({
  item,
  index,
  removeCase,
  handleCaseDetails,
  initialValue,
  toggleDisableAddNewCase,
  control,
  getValues,
}) => {
  const [caseDetails, setCaseDetails] = React.useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = React.useState(true);

  const { list } = useSelector(eventDefinitionState);
  const { eventCases, actionCases } = useSelector(processState);

  const selectCase = item.caseType === CaseType.ACTION ? 'Wybrana akcja' : 'Wybrane Zdarzenie';
  const caseParams = item.caseType === CaseType.ACTION ? 'Parametry akcji' : 'Parametry Zdarzenia';
  const description = item.caseType === CaseType.ACTION ? 'Wybierz  akcje' : 'Wybierz zdarzenie';
  const options = item.caseType === CaseType.ACTION ? prepareCase(actionCases, list) : prepareCase(eventCases, list);
  const checkIsEmpty = isEmpty(caseDetails?.parameters);

  const handleSubmit = () => {
    handleCaseDetails(
      {
        ...caseDetails,
        parameters: preparedKeys(control._formValues, index),
      },
      index
    );
  };

  const handleSelect = (selectedValue) => {
    const selectedCase = options.find((item) => item.value === selectedValue);
    return selectedCase ? setCaseDetails(selectedCase) : null;
  };

  const isDisabled = () => {
    if (caseDetails?.parameters && isEmpty(caseDetails.parameters)) {
      return false;
    }

    if (caseDetails === null) {
      return true;
    }

    return disableSubmitButton;
  };

  const handleOnblur = () => checkValidateButton();

  const checkValidateButton = () => {
    const values = getValues();
    const filteredObject = Object.keys(values)
      .filter((key) => key.includes(`caseId${index}`))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: values[key] });
      }, {});

    const shouldEnableSubmitButtonArray = Object.keys(caseDetails.parameters).map((key) => {
      const object = caseDetails.parameters[key];
      const uniqueKey = `caseId${index}_${key}`;

      if (object.validation[0] === 'required') {
        return filteredObject[uniqueKey]?.length === 0;
      }

      return object.validation[0] === 'required';
    });

    setDisableSubmitButton(!shouldEnableSubmitButtonArray.every((item) => item === false));
  };

  React.useEffect(() => {
    toggleDisableAddNewCase(true)();

    return () => {
      toggleDisableAddNewCase(false)();
    };
  }, [toggleDisableAddNewCase]);

  React.useEffect(() => {
    if (!checkIsEmpty) {
      checkValidateButton();
    }
  }, [checkIsEmpty]);

  return (
    <DiagramCaseDetailsStyled>
      <p>{selectCase}</p>
      <DynamicSelect initialValue={initialValue} options={options} handleSelect={handleSelect} displayFirstLabel />
      <p className="paragraph">{caseParams}</p>
      <div className="input">
        <span className="inputDescription">
          {caseDetails === null ? description : caseDetails?.parameters?.description}
        </span>
        <div className="inputWrapper">
          {caseDetails &&
            Object.keys(caseDetails.parameters).map((key) => {
              const object = caseDetails.parameters[key];
              const uniqueKey = `caseId${index}_${key}`;

              if (object.type === 'String') {
                return (
                  <FormInputText
                    key={uniqueKey}
                    name={uniqueKey}
                    control={control}
                    label={object.name}
                    isRequired={object.validation[0] === 'required'}
                    onBlur={handleOnblur}
                  />
                );
              }

              if (object.type === 'Select') {
                return (
                  <FormInputSelect
                    getValues={getValues}
                    key={uniqueKey}
                    onBlur={handleOnblur}
                    name={uniqueKey}
                    control={control}
                    label={object.name}
                    isRequired={object.validation[0] === 'required'}
                    options={object.options.map((item) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })}
                  />
                );
              }

              return <FormInputText key={uniqueKey} name={uniqueKey} control={control} label={object.name} />;
            })}
        </div>
      </div>
      <Button type={'button'} disabled={isDisabled()} onClick={handleSubmit}>
        {'Zapisz'}
      </Button>
      <div className="delete" onClick={removeCase(index)}>
        {'Usuń element'}
      </div>
    </DiagramCaseDetailsStyled>
  );
};
