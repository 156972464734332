import styled from '@emotion/styled';

export const MapChartStyled = styled('div')`
  background: #f8f8ff;
  border: 1px dashed #0d6dc5;
  text-align: center;
  margin: auto;
  overflow: hidden;
  min-height: ${(props) => props.minHeight ?? 'unset'};
  border-radius: ${(props) => 2 * props.theme.shape.borderRadius}px;
  width: 100%;
  position: relative;

  .leaflet-container {
    width: 100%;
    min-height: ${(props) => props.minHeight ?? 'unset'};
  }
`;
