import { TableOutlinedButton } from '../../TableOutlineButton/TableOutlinedButton';
import { TableCell } from '@mui/material/';
import React from 'react';

export const RecommendationCell = ({ data }) => {
  const { id, body, onRowClick, marker } = data;
  const clickHandle = () => {
    onRowClick(id);
  };

  return (
    <TableCell className="event-name">
      <div id={`recommendation-${id}`}>
        {body}
        {!!marker && (
          <span className="view-button" onClick={clickHandle}>
            <TableOutlinedButton variant="outlined">Zobacz na mapie</TableOutlinedButton>
          </span>
        )}
      </div>
    </TableCell>
  );
};
