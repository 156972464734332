import styled from '@emotion/styled';

export const BarGraphStyled = styled.div`
  position: relative;
  width: 100%;
  height: ${({ height }) => height + 'px'};

  // height: ${({ height }) => height + 130 + 'px'};
  //
  // @media (max-width: 768px) {
  //   height: ${({ height }) => height + 150 + 'px'};
  // }
  // @media (max-width: 375px) {
  //   height: ${({ height }) => height + 250 + 'px'};
  // }

  .chart {
    position: absolute;
    height: ${({ height }) => height + 'px'};
    width: 100%;
  }
  .graph-title {
    line-height: 25px;
    font-weight: 700;
    font-size: 1.2rem;
    color: #566a7f;
  }
  .graph-sub-title {
    height: 15px;
    margin: 18px 0;
    line-height: 23px;
    color: #697a8d;
  }
  .graph-footer {
    height: 15px;
    margin-top: 16px;
    line-height: 20px;
    color: #697a8d;
  }
`;
