import { createSlice } from '@reduxjs/toolkit';
import { fetchEventsTypes } from './eventsTypes.action';

const initialState = {
  data: [],
  isLoading: false,
  status: 'initial',
  error: null,
};

export const eventsTypes = createSlice({
  name: 'eventsTypes',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventsTypes.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
      })
      .addCase(fetchEventsTypes.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchEventsTypes.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.data = [];
        state.error = 'error';
      });
  },
});

export default eventsTypes.reducer;
