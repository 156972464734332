export const eventsItemLimitSelectorOptions = [
  {
    name: 20,
    value: 20,
    label: 20,
  },
  {
    name: 50,
    value: 50,
    label: 50,
  },
  {
    name: 80,
    value: 80,
    label: 80,
  },
  {
    name: 100,
    value: 100,
    label: 100,
  },
];
