import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { LoginPage } from '../pages/Auth/LoginPage/LoginPage';
import { RemindPasswordPage } from '../pages/Auth/RemindPasswordPage/RemindPasswordPage';
import { authenticatedPagesList } from './pagesList';
import { authorize } from '../store/auth/auth.actions';
import { authState } from '../store/auth/auth.selector';
import { CompleteNewPassword } from '../pages/Auth/CompleteNewPassword/CompleteNewPassword';
import { ErrorPage } from '../pages/ErrorPage/ErrorPage';
import { fetchAppConfigInitialData } from '../store/appConfig/appConfig.action';
import { appConfigState } from '../store/appConfig/appConfig.selector';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import { SetNewPassword } from '../pages/Auth/SetNewPassword/SetNewPassword';

export const Router = () => {
  const dispatch = useDispatch();
  const { isLoading, loggedIn, status } = useSelector(authState);
  const { isAnyWarehouseActive, areAllFetched } = useSelector(appConfigState);

  useEffect(() => {
    dispatch(authorize()).then(() => {
      dispatch(fetchAppConfigInitialData());
    });
  }, [dispatch]);

  if (!areAllFetched && loggedIn) {
    return null;
  }

  return (
    <ErrorBoundary redirect={process.env.NODE_ENV !== 'development'}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<ErrorPage errorCode={404} />} />
          <Route path="/internal-error" element={<ErrorPage errorCode={500} />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/remind-password" element={<RemindPasswordPage />} />
          <Route path="/complete-new-password" element={<CompleteNewPassword />} />
          <Route path="/set-new-password" element={<SetNewPassword />} />
          {/*<Route path="/register" element={<RegisterPage />} />*/}
          <Route path="/complete-new-password" element={<CompleteNewPassword />} />
          {authenticatedPagesList(isAnyWarehouseActive)
            .filter((item) => !item.shouldHide)
            .map((page, number) => (
              <Route
                key={number}
                path={page.hrefWithParam ? page.hrefWithParam : page.href}
                element={
                  <ProtectedRoute loggedIn={loggedIn} authStatus={status} isLoading={isLoading}>
                    {page.component}
                  </ProtectedRoute>
                }
              />
            ))}
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
