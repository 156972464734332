import { IconButton } from '@mui/material';
import React from 'react';
import { memo } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const containerStyle = {
  display: 'flex',
  margin: 'auto',
  paddingTop: '0.25rem',
};
const MenuItems = ({ menu }) => {
  return (
    <Grid2 xs={11} container style={containerStyle}>
      {menu.map(({ Icon, handleClick, isDisabled }, index) => {
        return (
          <IconButton onClick={handleClick} disabled={isDisabled} key={index} size={'small'}>
            <Icon color="primary" />
          </IconButton>
        );
      })}
    </Grid2>
  );
};
export default memo(MenuItems);
