import styled from '@emotion/styled';

export const AuthFormStyled = styled.form`
  @media (max-width: 767px) {
    width: 100%;
  }

  .form-options {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    & > a {
      ${({ theme }) => theme.palette.primary.main};
      font-size: 0.8rem;
      line-height: 23px;
    }
  }
`;
