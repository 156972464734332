import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAvailableScenario,
  addScenario,
  getScenarioById,
  updateScenario,
  deleteScenario,
} from '../../api/process';
import { fetchProcessList } from '../processList/processList.actions';

export const fetchAvailableScenario = createAsyncThunk(
  'process/fetchAvailableScenario',
  async (warehouseId, { getState }) => {
    const response = await getAvailableScenario({ data: getState().auth.tokens });
    return response;
  }
);

export const addNewScenario = createAsyncThunk('process/addNewScenario', async (scenarioDetails, { getState }) => {
  const response = await addScenario({
    warehouseId: getState().appConfig.appConfig.activeWarehouseId,
    data: {
      accessToken: getState().auth.tokens.accessToken,
      ...scenarioDetails,
    },
  });
  return response;
});

export const updateUserScenario = createAsyncThunk(
  'process/updateUserScenario',
  async ({ selectedId, editScenarioDetails }, { getState }) => {
    const response = await updateScenario({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      scenarioId: selectedId,
      data: {
        accessToken: getState().auth.tokens.accessToken,
        ...editScenarioDetails,
      },
    });
    return response;
  }
);

export const getUserProcessById = createAsyncThunk('process/getUserProcessById', async (scenarioId, { getState }) => {
  const response = await getScenarioById({
    warehouseId: getState().appConfig.appConfig.activeWarehouseId,
    scenarioId: scenarioId,
    data: {
      accessToken: getState().auth.tokens.accessToken,
    },
  });

  return response;
});

export const deleteUserScenario = createAsyncThunk(
  'process/deleteUserScenario',
  async ({ selectedId }, { getState }) => {
    const response = await deleteScenario({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      scenarioId: selectedId,
      data: {
        accessToken: getState().auth.tokens.accessToken,
      },
    });
    return response;
  }
);

export const handleActivateUserProcess = createAsyncThunk(
  'process/handleActivateUserProcess',
  async (body, { getState, dispatch }) => {
    const editScenarioDetails = {
      ...getState().processList.processList.list.find((item) => item.id === body.scenarioId),
      active: body.active,
    };

    const response = await updateScenario({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      scenarioId: body.scenarioId,
      data: {
        accessToken: getState().auth.tokens.accessToken,
        ...editScenarioDetails,
      },
    });

    await dispatch(fetchProcessList());

    return response;
  }
);

export const handleMoveProcessFromPredefinedList = createAsyncThunk(
  'process/handleMoveProcessFromPredefinedList',
  async (scenarioId, { getState, dispatch }) => {
    const scenarioDetails = {
      ...getState().processList.predefinedProcessList.predefinedList.find((item) => item.id === scenarioId),
      active: false,
    };

    const response = await addScenario({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      data: {
        accessToken: getState().auth.tokens.accessToken,
        ...scenarioDetails,
      },
    });

    await dispatch(fetchProcessList());

    return response;
  }
);

export const handleScenarioExecutions = createAsyncThunk(
  'process/handleScenarioExecutions',
  async (scenarioId, { getState, dispatch }) => {
    const scenarioDetails = {
      ...getState().processList.predefinedProcessList.predefinedList.find((item) => item.id === scenarioId),
      active: false,
    };

    const response = await addScenario({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      data: {
        accessToken: getState().auth.tokens.accessToken,
        ...scenarioDetails,
      },
    });

    await dispatch(fetchProcessList());

    return response;
  }
);
