import { createSlice } from '@reduxjs/toolkit';
import { fetchReportsList } from './reportsList.actions';

const initialState = {
  isLoading: false,
  status: 'initial',
  data: [],
  error: null,
};

export const reportsListReducer = createSlice({
  name: 'reportsList',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchReportsList.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
        state.data = [];
        state.error = null;
      })
      .addCase(fetchReportsList.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.data = action.payload ?? [];
        // state.data = [
        //   {
        //     id: 1,
        //     url: 'http://wp.pl',
        //     name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //     timestamp: new Date().getTime() / 1000,
        //   },
        //   {
        //     id: 1,
        //     url: 'http://wp.pl',
        //     name: 'consectetur adipiscing elit., Lorem ipsum dolor sit amet, ',
        //     timestamp: new Date().getTime() / 1000,
        //   },
        // ];
      })
      .addCase(fetchReportsList.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.data = [];
        state.error = 'error';
      });
  },
});

export default reportsListReducer.reducer;
