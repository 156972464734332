import styled from '@emotion/styled';

export const ProcessCompositionCreatorStyled = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  margin-top: 26px;

  .creator {
    margin-bottom: 20px;

    &Title {
      font-weight: 700;
      font-size: 18px;
      line-height: 19px;
      color: #697a8d;
      margin-bottom: 26px;
    }

    &Description {
      font-size: 14px;
      line-height: 25px;
      font-weight: 400;
      color: #566a7f;
    }
  }
`;
