import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEventsDefinitions, getWarehousesList, getWarehousesLimit } from '../../api/appConfig';

export const fetchAppConfigInitialData = createAsyncThunk(
  'appConfig/fetchAppConfigInitialData',
  async (body, { getState }) => {
    const [warehouseResponse, eventDefinitionResponse, warehousesLimitResponse] = await Promise.all([
      getWarehousesList(getState().auth.tokens),
      getEventsDefinitions(getState().auth.tokens),
      getWarehousesLimit(getState().auth.tokens),
    ]);

    return { warehouseResponse, eventDefinitionResponse, warehousesLimitResponse };
  }
);

export const fetchWarehousesList = createAsyncThunk('appConfig/fetchWarehousesList', async (body, { getState }) => {
  const warehouseResponse = await getWarehousesList(getState().auth.tokens);
  return { warehouseResponse };
});
