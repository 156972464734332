import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import styled from '@emotion/styled';

const FormInputSelect = ({ control, label, name, options, onBlur, isRequired }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DynamicSelectStyled>
          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <FormHelperText>{isRequired ? 'Wybierz*' : 'Wybierz'}</FormHelperText>
            <Select
              value={value || ''}
              IconComponent={KeyboardArrowDownIcon}
              onChange={(v) => {
                onChange(v);
                onBlur();
              }}
            >
              {options.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value} name={item.label}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DynamicSelectStyled>
      )}
    />
  );
};

export default FormInputSelect;

export const DynamicSelectStyled = styled.div`
  .MuiOutlinedInput-root {
    margin-bottom: 10px;
    width: 100%;
  }

  .MuiFormHelperText-root {
    font-weight: normal !important
  }

  .MuiMenuItem-root {
    font-family: 'Public Sans', sans-serif;
    font-size: 14px !important;
    align-items: center;
    display: flex;
    margin: 10px 0;
  }

  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    font-family: 'Public Sans', sans-serif;
  }

  .MuiSelect-select {
    padding: 8px;
    border-radius: 8px;
    font-family: 'Public Sans', sans-serif;
    font-size: 14px;
    align-items: center;
    display: flex;
  }

  .MuiFormHelperText-root {
    margin: 0;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: #435971;
  }
`;
