import React from 'react';
import { ProcessCompositionHeader } from './ProcessCompositionHeader/ProcessCompositionHeder';
import { ProcessCompositionList } from './ProcessCompositionList/ProcessCompositionList';
import { ProcessCompositionHistoryList } from './ProcessCompositionHistoryList/ProcessCompositionHistoryList';
import { Step } from '../../components/Step/Step';
import { Stepper } from '../../components/Stepper/Stepper';
import { ProcessCompositionCreator } from './ProcessCompositionCreator/ProcessCompositionCreator';
import { useFetchProcessComposition } from './useFetchProcessComposition';
import { useDispatch } from 'react-redux';
import { fetchAvailableScenario, getUserProcessById } from '../../store/process/process.actions';
import {fetchHistoryProcessList} from "../../store/historyProcessList/historyProcessList.actions";

export const ProcessComposition = () => {
  const [selectedId, setSelectedId] = React.useState(0);
  const dispatch = useDispatch();
  useFetchProcessComposition();

  const handleEditProcess = (processId) => {
    setSelectedId(processId);
    dispatch(getUserProcessById(processId));
  };

  React.useEffect(() => {
    dispatch(fetchAvailableScenario());
    dispatch(fetchHistoryProcessList());
  }, []);

  return (
    <Stepper>
      <Step
        step={1}
        render={(props) => {
          return (
            <>
              <ProcessCompositionHeader
                handleNewProcess={props.onNextStep}
                isNewProcessSectionEnabled={true}
                title={'Kompozytor Procesów'}
                description={
                  'Twórz, edytuj oraz przeglądaj procesy, który stworzyłeś na potrzeby odpowiedniego zarządzania swoimi projektami. Możesz również skorzystać z predefiniowanych procesów stworzonych przez nas i dodać je do swojego projektu.'
                }
              />
              <ProcessCompositionList navigateTo={props.navigateTo} handleEdit={handleEditProcess} />
              <ProcessCompositionHistoryList navigateTo={props.navigateTo} handleEdit={handleEditProcess} />
            </>
          );
        }}
      />
      <Step
        step={2}
        render={(props) => {
          return <ProcessCompositionCreator navigateTo={props.navigateTo} isEditProcess={false} />;
        }}
      />
      <Step
        step={3}
        render={(props) => {
          return (
            <ProcessCompositionCreator navigateTo={props.navigateTo} isEditProcess={true} selectedId={selectedId} />
          );
        }}
      />
    </Stepper>
  );
};
