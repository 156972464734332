import styled from '@emotion/styled';

export const ReportsPageCreatorStyled = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;

  input {
    padding: 12px;
  }

  .MuiSelect-select {
    padding: 14px;
  }

  .content {
    &Header {
      font-size: 18px;
      line-height: 19px;
      font-weight: 700;
      color: #697a8d;
      margin-bottom: 26px;
    }

    &Select {
      .MuiSelect-select {
        padding: 12px;
      }
    }

    &Description {
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #566a7f;
      margin-bottom: 24px;
    }

    &Checkbox {
      display: flex;
    }
  }
`;
