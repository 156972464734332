import { createSlice } from '@reduxjs/toolkit';
import { fetchWarehouseReconstruction } from './onboarding.actions';
import { checkIsJSON } from '../../utils/utils';

const initialState = {
  newPlan: {
    activeStep: 1,
    file: null,
    imageRaw: null,
    imageCropped: null,
    imageCroppedProperties: null,
    imageProperties: {},
    isValid: false,
    isDisabledSteps: false,
    sceneDimensions: {},
    stepSource: '',
  },
  reconstruction: {
    isLoading: false,
    image_raw: '',
    image_cropped: '',
    image_cropped_properties: '',
    scene_dimensions: '',
    svg: '',
    three_d_scene: '',
    is_disabled_steps: false,
  },
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialState,

  reducers: {
    setActiveStep: (state, action) => {
      state.newPlan.activeStep = action.payload;
    },
    clearFile: (state, action) => {
      state.newPlan.file = null;
    },
    setStepSource: (state, action) => {
      state.newPlan.stepSource = action.payload;
    },
    setFile: (state, action) => {
      state.newPlan.file = action.payload;
      state.newPlan.isValid = action.payload !== null ? true : false;
      state.newPlan.isDisabledSteps = false;
    },
    setIsValid: (state, action) => {
      state.newPlan.isValid = action.payload;
    },
    setDisabledSteps: (state, action) => {
      state.newPlan.isDisabledSteps = action.payload;
      state.newPlan.imageRaw = null;
      state.newPlan.imageCropped = null;
      state.newPlan.imageCroppedProperties = null;
      state.newPlan.imageProperties = {};
      state.newPlan.isValid = true;
    },
    setImage: (state, action) => {
      state.newPlan.imageRaw = action.payload;
    },
    setImageCropped: (state, action) => {
      state.newPlan.imageCropped = action.payload;
      state.newPlan.isDisabledSteps = false;
      state.newPlan.imageProperties = {};
    },
    setImageCroppedProperties: (state, action) => {
      state.newPlan.imageCroppedProperties = action.payload;
    },
    setImageProperties: (state, action) => {
      state.newPlan.imageProperties = action.payload;
    },
    setSceneDimensions: (state, action) => {
      state.newPlan.sceneDimensions = {
        width: action.payload.width,
        length: action.payload.length,
      };
    },
    setIsSceneDimensionsModalValid: (state, action) => {
      state.newPlan.isSceneDimensionsModalValid = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouseReconstruction.pending, (state, action) => {
        state.reconstruction.isLoading = true;
      })
      .addCase(fetchWarehouseReconstruction.fulfilled, (state, action) => {
        state.reconstruction.isLoading = false;
        state.reconstruction.image_raw = action.payload.image_raw;
        state.reconstruction.image_cropped = action.payload.image_cropped;
        state.reconstruction.image_cropped_properties = reformatData(action.payload.image_cropped_properties);
        state.reconstruction.scene_dimensions = reformatData(action.payload.scene_dimensions);
        state.reconstruction.svg = reformatData(action.payload.svg);
        state.reconstruction.three_d_scene = reformatData(action.payload.three_d_scene);
        state.reconstruction.is_disabled_steps = reformatData(action.payload.is_disabled_steps);
      })
      .addCase(fetchWarehouseReconstruction.rejected, (state, action) => {
        state.reconstruction.isLoading = false;
      });
  },
});

export const {
  setImage,
  clearFile,
  setFile,
  setActiveStep,
  setStepSource,
  setIsValid,
  setDisabledSteps,
  setImageCropped,
  setImageProperties,
  setImageCroppedProperties,
  setSceneDimensions,
  setIsSceneDimensionsModalValid,
} = onboardingSlice.actions;
export default onboardingSlice.reducer;

const reformatData = (value) => (checkIsJSON(value) ? JSON.parse(value) : value);
