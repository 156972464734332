import React, { useEffect } from 'react';
import { InfoWidget } from './InfoWidget/InfoWidget';
import { DashboardCards } from '../../components/DashboardCards/DashboardCards';
import Grid2 from '@mui/material/Unstable_Grid2';

import { eventsLastDay, eventsLast7Days, eventsLast30Days } from '../../store/dashboard/dashboard.selector';
import {
  fetchEventsForLastDayWidget,
  fetchEventsForLast7DaysWidget,
  fetchEventsForLast30DaysWidget,
} from '../../store/dashboard/dashboard.actions';
import { dashboardPageTexts } from '../../data/dashbaordPageTexts';
import { DashboardPageStyled } from './DashboardStyled';
import { DashboardEvents } from '../../components/DashboardEvents/Dashboard';
import { useDispatch } from 'react-redux';
import ContentBox from '../../components/ContentBox/ContentBox';
import { BarChart } from '../../components/BarGraph/BarGraph';
import { useTimeline } from './useTimeline';
import { useLocation } from 'react-router-dom';

export const DashboardPage = () => {
  const dispatch = useDispatch();
  const { chartData, highestValue, isLoading, refreshTimelineData } = useTimeline();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchEventsForLastDayWidget());
    dispatch(fetchEventsForLast7DaysWidget());
    dispatch(fetchEventsForLast30DaysWidget());
    refreshTimelineData();
  }, [pathname, dispatch]);

  const selectOptions = [
    // {selector: events, name: dashboardPageTexts.eventsBox.select.lastDay },
    { selector: eventsLastDay, name: dashboardPageTexts.eventsBox.select.lastDay },
    { selector: eventsLast7Days, name: dashboardPageTexts.eventsBox.select.last7Days },
    { selector: eventsLast30Days, name: dashboardPageTexts.eventsBox.select.last30Days },
  ];

  return (
    <DashboardPageStyled>
      <Grid2 container spacing={2} justifyContent="center">
        <Grid2 xs={12} lg={8}>
          <Grid2 container>
            <Grid2 xs={6} md={4}>
              <InfoWidget
                title={dashboardPageTexts.lastDayWidget.title}
                subTitle={dashboardPageTexts.lastDayWidget.subTitle}
                selector={eventsLastDay}
              />
            </Grid2>
            <Grid2 xs={6} md={4}>
              <InfoWidget
                title={dashboardPageTexts.last7DaysWidget.title}
                subTitle={dashboardPageTexts.last7DaysWidget.subTitle}
                selector={eventsLast7Days}
              />
            </Grid2>
            <Grid2 xs={6} md={4}>
              <InfoWidget
                title={dashboardPageTexts.last30DaysWidget.title}
                subTitle={dashboardPageTexts.last30DaysWidget.subTitle}
                selector={eventsLast30Days}
              />
            </Grid2>
            <Grid2 xs={12}>
              <ContentBox description={dashboardPageTexts.barChart.subTitle} title={dashboardPageTexts.barChart.title}>
                <BarChart
                  data={chartData}
                  highestValue={highestValue}
                  isLoading={isLoading}
                  height={340}
                  axisType={'logarithmic'}
                  showAxisScale
                />
              </ContentBox>
            </Grid2>
            <Grid2 xs={12}>
              <DashboardEvents className="events content-box" selectBoxOptions={selectOptions} />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 xs={12} lg={4}>
          <DashboardCards />
        </Grid2>
      </Grid2>
    </DashboardPageStyled>
  );
};
