import { createSlice } from '@reduxjs/toolkit';
import { fetchDangerLevel, fetchACSABenchmark } from './dangerLevel.action';

const initialState = {
  ACSAData: {
    data: [],
    isLoading: false,
    status: 'initial',
    error: null,
  },
  ACSABenchmark: {
    data: [],
    isLoading: false,
    status: 'initial',
    error: null,
  },
};

export const dangerLevel = createSlice({
  name: 'dangerLevel',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDangerLevel.pending, (state) => {
        state.ACSAData.isLoading = true;
        state.ACSAData.status = 'pending';
        state.ACSAData.error = null;
        state.ACSAData.data = [];
      })
      .addCase(fetchDangerLevel.fulfilled, (state, action) => {
        state.ACSAData.status = 'fulfilled';
        state.ACSAData.isLoading = false;
        state.ACSAData.data = action.payload;
      })
      .addCase(fetchDangerLevel.rejected, (state, action) => {
        state.ACSAData.status = 'rejected';
        state.ACSAData.isLoading = false;
      })
      .addCase(fetchACSABenchmark.pending, (state) => {
        state.ACSABenchmark.isLoading = true;
        state.ACSABenchmark.status = 'pending';
        state.ACSABenchmark.error = null;
        state.ACSABenchmark.data = [];
      })
      .addCase(fetchACSABenchmark.fulfilled, (state, action) => {
        state.ACSABenchmark.status = 'fulfilled';
        state.ACSABenchmark.isLoading = false;
        state.ACSABenchmark.data = action.payload;
      })
      .addCase(fetchACSABenchmark.rejected, (state) => {
        state.ACSABenchmark.status = 'rejected';
        state.ACSABenchmark.isLoading = false;
        state.ACSABenchmark.error = 'error';
      });
  },
});

export default dangerLevel.reducer;
