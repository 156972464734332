import React from 'react';
import { Stepper } from '../../components/Stepper/Stepper';
import { Step } from '../../components/Step/Step';
import CustomizeWarehouse from './CustomizeWarehouse';
import OnboardingUploadFile from './OnboardingUploadFile';
import { OnBoardingViewEditor } from './OnBoardingViewEditor/OnBoardingViewEditor';
import OnboardingDefineScale from './OnboardingDefineScale';
import OnboardingSummary from './OnboardingSummary';

export const Onboarding = () => {
  return (
    <Stepper>
      <Step step={1} render={(props) => <OnboardingUploadFile {...props} />} />
      <Step step={2} render={(props) => <CustomizeWarehouse {...props} />} />
      <Step step={3} render={(props) => <OnboardingDefineScale {...props} />} />
      <Step step={4} render={(props) => <OnBoardingViewEditor {...props} />} />
      <Step step={5} render={(props) => <OnboardingSummary {...props} />} />
    </Stepper>
  );
};
