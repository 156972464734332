import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activateReport,
  addPredefinedReport,
  deActivateReport,
  getPredefinedReportsList,
  getReportsList,
  saveReport,
} from '../../api/reports';

export const fetchReportsList = createAsyncThunk('reports/fetchReportList', async () => {
  const response = await getReportsList();
  return response;
});

export const fetchPredefinedReportsList = createAsyncThunk('reports/fetchPredefinedReportList', async () => {
  const response = await getPredefinedReportsList();
  return response;
});

export const activeUserReport = createAsyncThunk('reports/activeUserReport', async (reportId) => {
  const response = await activateReport(reportId);

  await updateReportList(response);

  return response;
});

export const deActiveUserReport = createAsyncThunk('reports/deActiveUserReport', async (reportId) => {
  const response = await deActivateReport(reportId);

  await updateReportList(response);

  return response;
});

export const addPredefinedUserReport = createAsyncThunk(
  'reports/addPredefinedUserReport',
  async (predefinedReportId) => {
    const response = await addPredefinedReport(predefinedReportId);

    await updateReportList(response);

    return response;
  }
);

export const saveUserReport = createAsyncThunk('reports/saveUserReport', async (body) => {
  const response = await saveReport(body);

  await updateReportList(response);

  return response;
});

const updateReportList = async (response, dispatch) => {
  if (response.status) {
    await dispatch.dispatch(fetchReportsList());
  }
};
