import styled from '@emotion/styled';

export const DynamicListItemStyled = styled.li`
  border-bottom: 1px solid lightgray;
  padding: 8px 0;
  ${(props) => (props.isFirst ? 'font-size: 12px' : '')};
  ${(props) => (props.isFirst ? 'color: #566A7F' : '')};
  ${(props) => (props.isFirst ? 'line-height: 14px' : '')};
  ${(props) => (props.isFirst ? 'font-weight: 600' : '')};
  ${(props) => (props.isFirst ? 'padding-bottom: 0' : '')};

  .actions {
    text-align: center;
  }

  .status {
    text-align: center;
  }

  .process {
    display: flex;
    align-items: center;

    button {
      border-radius: 4px;
      border: 1px solid #0d6dc5;
      height: 20px;
      width: 60px;
      margin-left: 20px;
      text-align: center;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }
  }
`;

export const MainChildStyled = styled.div`
  color: #697a8d;
  font-weight: 600;
  line-height: 23px;
  ${(props) => (props.isHistory ? ` font-weight: 400` : ' font-weight: 600;')}
`;

export const StatusChildStyled = styled.button`
  color: #566a7f;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  min-width: 70px;
  border-radius: 5px;
  margin-right: 2px;
  height: 22px;
  text-align: center;
  border: none;
  ${(props) => (props.isMargin ? 'margin-right: 5px' : 'margin-right: 0')};
  ${(props) => (props.isActive ? 'background: rgba(113, 221, 55, 0.2)' : 'background: rgba(228, 29, 29, 0.2)')};
`;

export const ActionChildStyled = styled.button`
  color: #0d6dc5;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  height: 23px;
  text-align: center;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline #0d6dc5;
`;
