import React from 'react';
import styled from '@emotion/styled';
import { Box, Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';

const ContentBox = ({ title, description, widget, children, className }) => {
  const theme = useTheme();
  return (
    <>
      <ContentBoxStyled className={className}>
        <Paper elevation={0} sx={{ height: '100%', borderRadius: theme.custom.borderRadius }}>
          <Box p={3}>
            <Grid2 container columnSpacing={0} rowSpacing={0} p={0}>
              <Grid2 xs={12} md={8}>
                {title && (
                  <Typography variant="h4" gutterBottom>
                    {title}
                  </Typography>
                )}
              </Grid2>
              <Grid2 xs={12} md={4}>
                {widget && (
                  <Box justifyContent={{ md: 'flex-end', xs: 'flex-start' }} display={'flex'}>
                    {widget}
                  </Box>
                )}
              </Grid2>
            </Grid2>
            <Grid2>{description && <div className="description">{description}</div>}</Grid2>
            <Grid2>{children}</Grid2>
          </Box>
        </Paper>
      </ContentBoxStyled>
    </>
  );
};

const ContentBoxStyled = styled(Box)`
  height: 100%;
`;

export default ContentBox;
