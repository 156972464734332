import { Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { onboardingWarehouse } from '../../data/createWarehouse';
import ModalDistance from './components/ModalDistance';
import ContentBox from './components/ContentBox';
import MenuItems from './components/MenuItems';
import NavigationWarehouse from './components/NavigationOnboarding';
import Canvas from './components/Canvas';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setImageProperties, setSceneDimensions } from '../../store/onboarding/onboarding.reducer';
import { GridContainer } from './components/GridContainerStyled';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { onBoardingReconstructionState, onboardingState } from '../../store/onboarding/onboarding.selector';

const OnboardingDefineScale = ({ onNextStep, onPrevStep }) => {
  const containerRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [scale, setScale] = useState(null);

  const { step, header, description } = onboardingWarehouse[2];
  const dispatch = useDispatch();
  const {
    stepSource,
    imageCropped: newPlanImageCropped,
    imageProperties,
    imageCroppedProperties: newPlanImageCroppedProperties,
  } = useSelector(onboardingState);
  const { image_cropped, image_cropped_properties } = useSelector(onBoardingReconstructionState);

  const imageCropped = stepSource === 'AccountEditPlan' ? image_cropped : newPlanImageCropped;
  const imageCroppedProperties =
    stepSource === 'AccountEditPlan' ? image_cropped_properties : newPlanImageCroppedProperties;

  useEffect(() => {
    if (imageProperties) {
      setScale(imageProperties.distaneInFact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoomOut = useCallback(() => {
    if (zoomLevel > 0.25) setZoomLevel(zoomLevel - 0.25);
  }, [zoomLevel]);

  const zoomIn = useCallback(() => {
    if (zoomLevel < 2) setZoomLevel(zoomLevel + 0.25);
  }, [zoomLevel]);

  const handleChange = useCallback((e) => {
    setScale(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (line) => {
      const resizeScale = scale / line;
      // if resizeScale === 100% we shouldn't resize if > 100% we should resize probably 2% if 102%
      // if resizeScale === 50% we should reduce about 50%
      batch(() => {
        dispatch(
          setImageProperties({
            linePosition: imageProperties.linePosition,
            resizeScale,
            distaneInFact: parseFloat(scale),
            distanceInPicutreInPx: line,
          })
        );
        dispatch(
          setSceneDimensions({
            width: imageCroppedProperties.width * resizeScale,
            length: imageCroppedProperties.height * resizeScale,
          })
        );
      });

      setModalOpen(false);
    },
    [dispatch, imageProperties.linePosition, scale, imageCroppedProperties]
  );

  const handleClose = useCallback(() => {
    setScale(null);
    setModalOpen(false);
  }, []);

  const getMenuItems = useMemo(
    () => [
      { Icon: AddIcon, handleClick: zoomIn },
      { Icon: RemoveIcon, handleClick: zoomOut },
    ],
    [zoomIn, zoomOut]
  );

  return (
    <>
      <ContentBox title={step} subtitle={header} description={description}>
        <Grid2 ref={containerRef} container xs={11} style={{ margin: 'auto' }}>
          <GridContainer minHeight={'60vh'} maxHeight={'100vh'}>
            {imageCropped && (
              <Canvas
                containerRef={containerRef}
                zoomLevel={zoomLevel}
                setZoomLevel={setZoomLevel}
                croppedImage={imageCropped}
                setModalOpen={setModalOpen}
                setScale={setScale}
                isModalOpen={isModalOpen}
                handleClose={handleClose}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              >
                <ModalDistance />
              </Canvas>
            )}
          </GridContainer>
        </Grid2>
        <MenuItems zoom={zoomLevel} menu={getMenuItems} />
        <Grid2 style={{ textAlign: 'center', padding: '0.2rem' }}>
          {scale && <Typography variant="body1">Długość zdefiniowanego odcinka to: {scale} cm.</Typography>}
        </Grid2>
        <NavigationWarehouse
          handleNextStep={onNextStep}
          handleBackStep={onPrevStep}
          isNextButtonDisabled={!imageProperties?.resizeScale}
        />
      </ContentBox>
    </>
  );
};
export default memo(OnboardingDefineScale);
