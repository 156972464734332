import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import NavBar from '../Navbar';
import SideBar from '../SideBar';

const LayoutMain = (props) => {
  const { children } = props;
  const [isSideBarOpen, setIsSideBarOpen] = React.useState(window.innerWidth > 991 ? true : false);

  const handleDrawerOpen = () => {
    setIsSideBarOpen(true);
  };

  const handleDrawerClose = () => {
    setIsSideBarOpen(false);
  };

  return (
    <LayoutMainStyled>
      <SideBar
        isSideBarOpen={isSideBarOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      ></SideBar>

      <Box className="content-container">
        <NavBar isSideBarOpen={isSideBarOpen} handleDrawerOpen={handleDrawerOpen}></NavBar>
        <div className="content">{children}</div>
      </Box>
    </LayoutMainStyled>
  );
};

export default LayoutMain;

const LayoutMainStyled = styled(Box)`
  display: flex;
  .content-container {
    flex: 1;
    padding: 12px 26px;
  }
  .content {
    margin-top: 30px;
  }
`;
