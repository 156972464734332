import React from 'react';
import { Divider, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const ModalContentStyled = styled('div')`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
  }
`;

export const ModalContent = ({ headerTitle, body, footer, handleCloseButton }) => {
  return (
    <ModalContentStyled>
      <Box px={3} py={2}>
        <Box className={'header'}>
          <Box>
            {headerTitle && (
              <Typography variant={'h3'} component={'div'}>
                {headerTitle}
              </Typography>
            )}
          </Box>
          {handleCloseButton && (
            <IconButton onClick={handleCloseButton}>
              <HighlightOffIcon />
            </IconButton>
          )}
        </Box>
        <Divider className="divider" sx={{ mt: 1, mb: 3 }} />
        <Box mb={2}>{body}</Box>
        {footer && <Box className={'footer'}>{footer}</Box>}
      </Box>
    </ModalContentStyled>
  );
};
