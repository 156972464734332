import styled from '@emotion/styled';

export const StatsticsStyled = styled.div`
  width: 100%;

  .graph {
    position: relative;
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    margin: 16px auto 0;
  }
`;
