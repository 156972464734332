import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEventsByType } from '../../../api/events';
import { EVENT_TYPE_TYPE, EVENTS_SORT_BY_COLUMN, SORT_ORDER } from '../../../constants';

export const fetchEventsTypes = createAsyncThunk(
  'eventTypes/fetchEventsTypes',
  async (body, { getState }) =>
    await getEventsByType({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      data: { ...getState().auth.tokens },
      eventType: EVENT_TYPE_TYPE,
      params: { ...body.params, sort: `${EVENTS_SORT_BY_COLUMN}:${SORT_ORDER}` },
    })
);
