import { FormControlLabel, FormControl } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { CheckboxStyled } from './CheckboxStyled';

export const CheckboxComponent = ({ label, labelVariant, isSelected, disabled, handleCheckbox, checked, name }) => {
  const labelProps = { inputProps: { 'aria-label': label } };
  const formControLabelProps = labelVariant ? { componentsProps: { typography: { variant: labelVariant } } } : {};

  return (
    <CheckboxStyled disabled={disabled}>
      <FormControl>
        <FormControlLabel
          {...formControLabelProps}
          label={label}
          control={
            <Checkbox
              {...labelProps}
              name={name}
              defaultChecked={isSelected}
              disabled={disabled}
              onChange={handleCheckbox}
              checked={checked}
            />
          }
        />
      </FormControl>
    </CheckboxStyled>
  );
};
