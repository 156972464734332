import { createSlice } from '@reduxjs/toolkit';
import { fetchWarehouseEvents } from './events.actions';

const initialState = {
  idOfEventToShow: null,
  events: {
    eventList: [],
    isLoading: false,
  },
};

export const eventsReducer = createSlice({
  name: 'events',
  initialState,

  reducers: {
    updateEvent: (state, action) => {
      let event = state.events.find((event) => event.id === action.payload.event.id);
      event[action.payload.name] = action.payload.value;
    },
    setIdOfEventToShow: (state, action) => {
      state.idOfEventToShow = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouseEvents.pending, (state) => {
        state.events.isLoading = true;
      })
      .addCase(fetchWarehouseEvents.fulfilled, (state, action) => {
        state.events.isLoading = false;
        state.events.eventList = action.payload;
      })
      .addCase(fetchWarehouseEvents.rejected, (state) => {
        state.events.isLoading = false;
      });
  },
});

export const { updateEvent, setIdOfEventToShow } = eventsReducer.actions;

export default eventsReducer.reducer;
