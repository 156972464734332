import Grid2 from '@mui/material/Unstable_Grid2';
import { ReportsListPageHeader } from './ReportsPageHeader/ReportsListPageHeader';
import ContentBox from '../../components/ContentBox/ContentBox';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportsListState } from '../../store/reportsList/reportsList.selector';
import { fetchReportsList } from '../../store/reportsList/reportsList.actions';
import { Loader } from '../../components/Loader/Loader';
import { TableComponent } from '../../components/TableComponent/TableComponent';
import { getPdf } from '../../api/reportsList';
import { appConfigState } from '../../store/appConfig/appConfig.selector';
import { authState, authTokens } from '../../store/auth/auth.selector';

export const ReportsListPage = () => {
  const [rows, setRows] = useState([]);
  const [reportsCount, setReportsCount] = useState(0);
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(reportsListState);
  const { activeWarehouseId } = useSelector(appConfigState);
  const { tokens } = useSelector(authState);

  const headers = ['NAZWA', 'AKCJA', 'DATA'];

  useEffect(() => {
    dispatch(fetchReportsList());
  }, [dispatch]);

  const handleDownloadClick = useCallback(({ id, timestamp }) => {
    const fileName = `Lumilook Report_${activeWarehouseId}_${id}_${timestamp}`;
    getPdf(
      {
        warehouseId: activeWarehouseId,
        reportId: id,
        data: { ...tokens },
      },
      fileName
    ).then();
  }, []);

  useEffect(() => {
    if (!!data?.length) {
      setRows(
        data.map(({ id, name, timestamp }) => ({
          name,
          action: { onClick: handleDownloadClick, type: 'callback', body: 'Pobierz', id, params: { timestamp } },
          timestamp,
        }))
      );
      setReportsCount(data.length);
    }
  }, [data]);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={8}>
          <ContentBox
            title={'Raporty'}
            description={
              'W tej sekcji możesz pobrać raporty wygenerowane przez nasze algorytmy wspomaganę sztuczną inteligencją.'
            }
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <ReportsListPageHeader reportsCount={reportsCount} />
        </Grid2>
        <Grid2 xs={12}>
          <ContentBox title={'Lista raportów'}>
            {isLoading ? (
              <Loader padding={'3'} />
            ) : (
              rows && <TableComponent rows={rows} headers={headers} rowPerPage={20} />
            )}
          </ContentBox>
        </Grid2>
      </Grid2>
    </>
  );
};
