import React from 'react';

export const useModal = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const handleToggleModal = (shouldToggleModal) => () => setModalOpen(shouldToggleModal);

  return {
    isModalOpen,
    handleToggleModal,
    setModalOpen,
  };
};
