import { Box, Button, Grid } from '@mui/material';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import styled from '@emotion/styled';
import { GridContainer } from './GridContainerStyled';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const BoxStyled = styled(Box)`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #676767;
`;
const NavigationBox = styled(Box)`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;
const ButtonStyled = styled(Button)`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Mulish';
  text-transform: initial;
  letter-spacing: 0;
  padding: 0;
  text-decoration: underline;
`;
const BoxImg = styled(Box)`
  margin: 3rem;
`;

export const TargetBox = (props) => {
  const { onDrop } = props;
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item.files);
        }
      },
      canDrop() {
        return true;
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props]
  );

  const handleCapture = ({ target }) => {
    if (target.files[0]) {
      onDrop([target.files[0]]);
    }
  };

  const isActive = canDrop && isOver;

  return (
    <Grid2 container xs={11} md={11} margin={'auto'}>
      <GridContainer ref={drop} sx={{ p: 4 }}>
        <NavigationBox>
          {isActive ? 'Upuść plik tutaj' : 'Upusc plik tutaj lub '}
          <input style={{ display: 'none' }} id="raised-button-file" multiple type="file" onChange={handleCapture} />
          <label htmlFor="raised-button-file">
            <ButtonStyled component="span">Wybierz z dysku</ButtonStyled>
          </label>
        </NavigationBox>
        <BoxImg>
          <img alt={'uploadIcon'} src={'/static/UploadIcon.svg'} />
        </BoxImg>
        <BoxStyled>Wspierane formaty: JPEG, PNG</BoxStyled>
      </GridContainer>
    </Grid2>
  );
};
