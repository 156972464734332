import React, { useEffect } from 'react';
import { AuthContainer } from '../../../components/AuthContainer';
import { LoginPageForm } from './LoginPageForm';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { ChallengeRequiredException } from '../../../services/auth/exceptions/ChallengeRequiredException';

export const LoginPage = () => {
  const { loggedIn, error } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitComplete = (results, formData) => {
    if (results?.error) {
      if (results?.payload instanceof ChallengeRequiredException) {
        enqueueSnackbar('Potrzebne informacje', { variant: 'info' });
        navigate('/complete-new-password', { state: formData });
      }
    }
  };

  useEffect(() => {
    if (loggedIn) {
      navigate('/');
    }
  }, [loggedIn, navigate]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  return (
    <AuthContainer title={'Zaloguj się do aplikacji'}>
      <LoginPageForm onLogin={onSubmitComplete} />
    </AuthContainer>
  );
};
