import { AuthenticationException } from './exceptions/AuthenticationException';
import { NewPasswordException } from './exceptions/NewPasswordException';

export const AuthExceptionResolver = (exception) => {
  switch (exception.name) {
    case 'UserNotConfirmedException':
      return new AuthenticationException('Musisz najpierw potwierdzić konto', exception.name);
    case 'NotAuthorizedException':
      switch (exception.message) {
        case 'Password attempts exceeded':
          return new AuthenticationException('Liczba prób logowania została przekroczona', exception.name);
        case 'Incorrect username or password.':
          return new AuthenticationException('Nieprawidłowy login lub hasło', exception.name);
        case 'Temporary password has expired and must be reset by an administrator.':
          return new AuthenticationException(
            'Hasło tymczasowe wygasło, skontaktuj się z administratorem w celu odnowienia dostępu.',
            exception.name
          );
        default:
          return new AuthenticationException('Błąd w trakcie logowania', exception.name);
      }
    case 'InvalidPasswordException':
      return new NewPasswordException(`Hasło nie spełnia polityki haseł: ${exception.message}`, exception.name);
    case 'AuthError':
      switch (exception.message) {
        case 'Confirmation code cannot be empty':
          return new AuthenticationException(`Kod potwierdzający nie może byc pusty`);
        default:
          return new AuthenticationException(`Błąd: ${exception.message}`);
      }
    case 'LimitExceededException':
      return new AuthenticationException('Liczba prób została przekroczona, spróbuj ponownie później');
    case 'ExpiredCodeException':
      switch (exception.message) {
        case 'Invalid code provided, please request a code again.':
          return new AuthenticationException(`Niepoprawny kod weryfikacyjny`);
        default:
          return new AuthenticationException(`Błąd: ${exception.message}`);
      }
    case 'CodeMismatchException':
      switch (exception.message) {
        case 'Invalid verification code provided, please try again.':
          return new AuthenticationException(`Niepoprawny kod weryfikacyjny`);
        default:
          return new AuthenticationException(`Błąd: ${exception.message}`);
      }
    default:
      return new Error('Błąd w trakcie logowania');
  }
};
