import styled from '@emotion/styled';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export const GridContainer = styled(Grid2)`
  background: ${(props) => props.theme.palette.secondary.light};
  border: 1px dashed #0d6dc5;
  text-align: center;
  margin: auto;
  overflow: hidden;
  min-height: ${(props) => props.minHeight ?? 'unset'};
  border-radius: ${(props) => 2 * props.theme.shape.borderRadius}px;
  width: 100%;
  position: relative;
`;
