import React from 'react';
import styled from '@emotion/styled';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Box from '@mui/material/Box';

export const ErrorPage = ({ errorCode, errorMessage }) => {
  const defaultMessage = errorCode === 500 ? 'Przepraszamy. Wystąpił problem techniczny. Już nad nim pracujemy.' : 'Strona nie istnieje';
  const message = errorMessage ? errorMessage : defaultMessage;
  return (
    <Grid2
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid2 item>
        <ErrorPageStyled>
          <Box className={'errorCode'}>{errorCode}</Box>
          <Box>{message}</Box>
        </ErrorPageStyled>
      </Grid2>
    </Grid2>
  );
};

export const ErrorPageStyled = styled.div`
  .errorCode {
    font-size: 15rem;
    font-weight: bolder;
  }
`;
