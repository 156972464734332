import * as L from 'leaflet';

export const recommendationIconLowPriority = new L.Icon({
  iconUrl: require('./assets/info-green.svg').default,
  iconSize: new L.Point(25, 25),
});
export const recommendationIconMediumPriority = new L.Icon({
  iconUrl: require('./assets/info-yellow.svg').default,
  iconSize: new L.Point(25, 25),
});

export const recommendationIconHighPriority = new L.Icon({
  iconUrl: require('./assets/info-red.svg').default,
  iconSize: new L.Point(25, 25),
});

export const recommendationIconUndefinedPriority = new L.Icon({
  iconUrl: require('./assets/info-black.svg').default,
  iconSize: new L.Point(25, 25),
});

export const eventIconDefault = new L.Icon({
  iconUrl: require('./assets/exclamation-triangle.svg').default,
  iconSize: new L.Point(25, 25),
});
