import { createSlice } from '@reduxjs/toolkit';
import { authorize, login, logOut } from './auth.actions';
import { ChallengeRequiredException } from '../../services/auth/exceptions/ChallengeRequiredException';
import { AuthenticationException } from '../../services/auth/exceptions/AuthenticationException';

const initialState = () => {
  return {
    tokens: null,
    details: null,
    status: 'initial',
    isLoading: false,
    loggedIn: false,
    error: null,
    errorDetails: null,
  };
};

export const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authorize.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
      })
      .addCase(authorize.fulfilled, (state, action) => {
        state.tokens = action.payload.tokens;
        state.details = action.payload.details;
        if (state?.details?.email) {
          state.loggedIn = true;
        }
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(authorize.rejected, (state, action) => {
        state.tokens = null;
        state.details = null;
        state.loggedIn = false;
        state.isLoading = false;
        state.status = 'rejected';
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.status = 'pending';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.tokens = action.payload.tokens;
        state.details = action.payload.details;
        if (state?.details?.email) {
          state.loggedIn = true;
        }
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.tokens = null;
        state.details = null;
        state.loggedIn = false;
        state.status = 'rejected';
        state.isLoading = false;
        if (action.payload instanceof ChallengeRequiredException) {
          state.errorDetails = action.payload;
        }
        if (action.payload instanceof AuthenticationException) {
          state.error = action.payload.message;
          state.errorDetails = action.payload;
        }
      })
      .addCase(logOut.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
      })
      .addCase(logOut.fulfilled, (state, action) => {
        state.tokens = null;
        state.details = null;
        state.loggedIn = false;
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(logOut.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default authReducer.reducer;
