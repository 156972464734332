import styled from '@emotion/styled';
import { AppBar } from '@mui/material';

export const AppBarStyled = styled(AppBar)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: none;
  height: 62px;
  position: relative;

  .menu-open-button {
    position: absolute;
    left: 0;
    transform: rotate(-180deg) translate(50%);
  }

  .notifications-icon {
    position: relative;
    svg {
      color: #435971;
      font-size: 1.7rem;
    }
    .notifications-number {
      position: absolute;
      top: 5px;
      right: 3px;
      height: 18px;
      width: 18px;
      background-color: #ff3e1d;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 50%;
      font-size: 0.85rem;
      font-weight: 500;
    }
  }
  .user-account-icon {
    border-radius: 50%;
    background-color: rgba(13, 109, 197, 0.5);
    color: #fff;
    z-index: 1;
    font-size: 0.85rem;
    font-weight: bold;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
  }
  .user-account-small-circle {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background-color: #fff;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      background-color: #71dd37;
      height: 9px;
      width: 9px;
      border-radius: 50%;
    }
  }
`;
