import styled from '@emotion/styled';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export const CropperStyled = styled(Cropper)`
  .cropper-modal {
    background: none;
  }
  .cropper-face {
    background: ${(props) => props.theme.palette.primary.main};
  }
`;
