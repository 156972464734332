import React from 'react';
import { AuthContainer } from '../../../components/AuthContainer';
import { useRemindPasswordForm } from './useRemindPasswordForm';
import { FormInputText } from '../../../components/FormComponents/FormInputText';
import { AuthFormStyled } from '../authFormStyled';
import { FormButton } from '../../../components/FormComponents/FormButton';
import { Link } from 'react-router-dom';

export const RemindPasswordPage = () => {
  const { control, handleSubmit, formState, onSubmit } = useRemindPasswordForm();

  return (
    <AuthContainer title={'Przypomnienie hasła'}>
      <AuthFormStyled onSubmit={handleSubmit(onSubmit)}>
        <FormInputText
          name={'username'}
          label={'Adres e-mail'}
          control={control}
          isSubmitting={formState.isSubmitting}
        />
        <FormButton text={'Przypomnij hasło'} textAlign={'center'} align={'center'} onClick={handleSubmit(onSubmit)} />
        <div className="form-options">
          <Link to={'/login'}>Zaloguj się</Link>
          <Link to={'/set-new-password'}>Ustaw nowe hasło</Link>
        </div>
      </AuthFormStyled>
    </AuthContainer>
  );
};
