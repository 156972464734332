import React from 'react';

import { FormControl, InputLabel, TextField } from '@mui/material';
import { InputComponentStyled } from './InputComponentStyled';

export const InputComponent = ({
  id,
  type,
  label,
  handleChange,
  value,
  name,
  placeholder,
  minLength,
  InputProps,
  inputProps,
}) => {
  const [isError, setError] = React.useState(false);
  const [errorLabel, setErrorLabel] = React.useState('');
  const [isFieldTouched, setFieldTouched] = React.useState(false);

  const validate = (event) => {
    setFieldTouched(true);
    validatePhoneNumber(event, minLength, type, setError, setErrorLabel);
    validateEmail(event, type, setError, setErrorLabel);
  };

  const onChange = (event, name) => {
    if (isFieldTouched) {
      validate(event);
    }
    handleChange(event, name);
  };

  return (
    <InputComponentStyled>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <TextField
        error={isError}
        hiddenLabel
        id={id}
        type={type}
        placeholder={placeholder ? placeholder : ''}
        fullWidth
        size="small"
        value={value}
        onBlur={validate}
        onChange={(event) => onChange(event, name && name)}
        required
        InputProps={InputProps}
        inputProps={{ minLength: minLength }}
        helperText={errorLabel}
      />
    </InputComponentStyled>
  );
};

const validatePhoneNumber = (event, minLength, type, setError, setErrorLabel) => {
  if (minLength && type === 'phone') {
    if (event.target.value.length < minLength) {
      setError(true);
      setErrorLabel('Podaj poprawny numer telefonu');
    } else {
      setError(false);
      setErrorLabel('');
    }
  }
};

const validateEmail = (event, type, setError, setErrorLabel) => {
  if (type === 'email') {
    const regexp = /^([a-zA-ZÀ-Ž0-9\_\+\-\.])+@([a-zA-ZÀ-Ž0-9\_\+\-\.])+\.([a-zA-ZÀ-Ž])+$/;

    if (!event.target.value.match(regexp)) {
      setError(true);
      setErrorLabel('Podaj poprawny email');
    } else {
      setError(false);
      setErrorLabel('');
    }
  }
};
