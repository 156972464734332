import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/auth.reducer';
import processListReducer from './processList/processList.reducer';
import historyProcessListReducer from './historyProcessList/historyProcessList.reducer';
import processReducer from './process/process.reducer';
import eventsReducer from './events/events.reducer';
import onboardingReducer from './onboarding/onboarding.reducer';
import reportsReducer from './reportsManager/reports.reducer';
import warehousesReducer from './warehouses/warehouses.reducer';
import dashboardReducer from './dashboard/dashboard.reducer';
import challengeReducer from './challenge/challenge.reducer';
import appConfigReducer from './appConfig/appConfig.reducer';
import eventsHistogram from './warehouseDetails/histogram/eventsHistogram.reducer';
import eventsTypes from './warehouseDetails/eventTypes/eventsTypes.reducer';
import dangerLevel from './warehouseDetails/dangerLevel/dangerLevel.reducer';
import timelineReducer from './warehouseDetails/timeline/timeline.reducer';
import recommendationsReducer from './recommendations/recommendations.reducer';
import reconstructionReducer from './reconcstruction/reconstruction.reducer';
import reportsListReducer from './reportsList/reportsList.reducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    challenge: challengeReducer,
    warehouses: warehousesReducer,
    appConfig: appConfigReducer,
    processList: processListReducer,
    historyProcessList: historyProcessListReducer,
    process: processReducer,
    events: eventsReducer,
    onboarding: onboardingReducer,
    reports: reportsReducer,
    dashboard: dashboardReducer,
    eventsHistogram: eventsHistogram,
    eventsTypes: eventsTypes,
    dangerLevel: dangerLevel,
    timeline: timelineReducer,
    recommendations: recommendationsReducer,
    reconstruction: reconstructionReducer,
    reportsList: reportsListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
