import styled from '@emotion/styled';

export const InfoWidgetStyled = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 180px;
  min-width: 130px;
  .header {
    flex-direction: column;
    display: flex;
    align-items: center;
    img {
      width: 39px;
      height: auto;
      object-fit: cover;
      margin-right: 9px;
    }
  }
  .title {
    font-weight: 600;
  }
  .number {
    line-height: 31px;
    font-weight: 700;
    font-size: 2.5rem;
    color: #566a7f;
    margin-top: 25px;
  }
  .change {
    margin-top: 20px;
    color: ${({ isChangePositive }) => (isChangePositive ? '#71DD37' : '#E41D1D')};
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 20px;
    img {
      transform: ${({ isChangePositive }) => isChangePositive && 'rotate(180deg)'};
      margin-right: 6px;
    }
  }
`;
