import styled from '@emotion/styled';

export const PaginationStyled = styled.div`
  .wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 767px) {
      justify-content: center;
      margin-bottom: 10px;
    }
  }

  button {
    color: white;
    background: #0d6dc5;
    width: 190px;
    height: 32px;
    font-size: 13px;
    font-weight: 400;
    line-height: 15;
    border-radius: 4px;
    border: none;

    :disabled {
      background: #697a8d;
      opacity: 0.25;
    }

    :hover {
      background: #0d6dc5;
    }
  }
`;
