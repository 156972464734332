import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEventsHistogram } from '../../../api/events';

export const fetchEventsHistogram = createAsyncThunk(
  'eventHistogram/fetchEventsHistogram',
  async (body, { getState }) => {
    return await getEventsHistogram({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      data: { ...getState().auth.tokens },
      params: { ...body.params },
    });
  }
);
