import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const SubmitButtonStyled = styled(Button)`
  font-weight: 700;
  line-height: 21px;
  font-size: 18px;
  margin-top: 25px;
  color: white;
  background: #0d6dc5;
  text-transform: none;
  width: 350px;
  height: 50px;

  :hover {
    background: #0d6dc5;
  }
`;
