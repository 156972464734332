import React from 'react';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import styled from '@emotion/styled';
import { ListHeader } from './ListHeader';
import { useSelector } from 'react-redux';
import { onboardingState } from '../../../store/onboarding/onboarding.selector';

export const OnboardingStepIndicator = ({ title, options }) => {
  const { activeStep } = useSelector(onboardingState);

  return (
    <List component="div" disablePadding>
      <ListHeader>{title}</ListHeader>
      {options.map((item, index) => (
        <ListItemStyled key={index} active={item.stepId === activeStep}>
          <ListItemIcon>
            <span className="icon">
              <span>{item.stepId}</span>
            </span>
          </ListItemIcon>
          <ListItemText primary={item.name} className="list-item" />
        </ListItemStyled>
      ))}
    </List>
  );
};

const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'active',
})`
  .list-item span {
    color: #566a7f;
    opacity: ${({ active }) => (active ? 1 : 0.5)};
  }
  .icon {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 1px solid rgba(13, 109, 197, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${({ active }) => (active ? 1 : 0.5)};
  }
`;
