import { ProcessComposition } from '../pages/ProcessComposition/ProcessComposition';
import RecommendationsPage from '../pages/Recommendations/Recommendations';
import { ReportsPage } from '../pages/ReportsPage/ReportsPage';
import { WarehouseDetails } from '../pages/WarehouseDetails/WarehouseDetails';
import { WarehousePage } from '../pages/WarehousePage';
import { HomePage } from '../pages/HomePage/HomePage';
import { DashboardPage } from '../pages/Dashboard/Dashboard';
import { Onboarding } from '../pages/Onboarding/Onboarding';
import { MyAccount } from '../pages//MyAccount/MyAccount';
import { ReportsListPage } from '../pages/ReportsListPage/ReportsListPage';

export const authenticatedPagesList = (isSectionDisabled, isAppConfigLoaded) => [
  {
    name: 'Home',
    href: '/',
    hrefWithParam: '/',
    icon: '/static/Arhive_load_fill.svg',
    component: <HomePage />,
    menu: [],
  },
  {
    name: 'Kreator magazynu',
    href: '/warehouse',
    hrefWithParam: '/warehouse/:warehouseId',
    icon: '/static/Arhive_load_fill.svg',
    component: <WarehousePage />,
    menu: [],
  },
  {
    name: 'Strona glówna',
    href: '/dashboard',
    hrefWithParam: '/dashboard/:warehouseId',
    icon: '/static/Home_fill.svg',
    component: <DashboardPage />,
    menu: ['sideBar'],
    isSectionDisabled,
  },
  {
    name: 'Magazyn',
    href: '/warehouse-details/:warehouseId/events/type',
    hrefWithParam: '/warehouse-details/:warehouseId/events/:eventType/*',
    alternativeMenuParam: '/warehouse-details/:warehouseId/stats',
    icon: '/static/Arhive_load_fill.svg',
    component: <WarehouseDetails />,
    menu: ['sideBar'],
    isSectionDisabled,
  },
  {
    name: 'Magazyn',
    href: '/warehouse-details/:warehouseId/stats',
    hrefWithParam: '/warehouse-details/:warehouseId/stats',
    icon: '/static/Arhive_load_fill.svg',
    component: <WarehouseDetails />,
    menu: [''],
    shouldHide: false,
    isSectionDisabled,
  },
  {
    name: 'Menedżer raportów',
    href: '/reports',
    hrefWithParam: '/reports/:warehouseId',
    icon: '/static/Chart_fill.svg',
    component: <ReportsPage />,
    menu: ['sideBar'],
    shouldHide: true,
    isSectionDisabled,
  },
  {
    name: 'Rekomendacje',
    href: '/recommendations',
    hrefWithParam: '/recommendations/:warehouseId',
    icon: '/static/Lamp.svg',
    component: <RecommendationsPage />,
    menu: ['sideBar'],
    isSectionDisabled,
  },
  {
    name: 'Raporty',
    href: '/reports-list',
    hrefWithParam: '/reports-list/:warehouseId',
    icon: '/static/Chart_fill.svg',
    component: <ReportsListPage />,
    menu: ['sideBar'],
    shouldHide: false,
    isSectionDisabled,
  },
  {
    name: 'Kompozytor procesów',
    href: '/process-composition',
    hrefWithParam: '/process-composition/:warehouseId',
    icon: '/static/composition.svg',
    customMargin: '35px',
    component: <ProcessComposition />,
    menu: ['sideBar'],
    isSectionDisabled,
  },
  {
    name: 'Onboarding',
    href: '/onboarding',
    hrefWithParam: '/onboarding',
    icon: '/static/Arhive_load_fill.svg',
    component: <Onboarding />,
    menu: [],
  },
  {
    name: 'Moje konto',
    href: '/my-account',
    hrefWithParam: '/my-account',
    icon: '',
    component: <MyAccount />,
    menu: [],
  },
];
