import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEventsByType, getRawEventsByType } from '../../api/events';
import { EVENTS_SORT_BY_COLUMN, SORT_ORDER } from '../../constants';

export const fetchWarehouseEvents = createAsyncThunk('warehouses/fetchWarehouseEvents', async (body, { getState }) => {
  let params = {};
  params =
    body.eventType === 'raw' ? { ...body.params } : { ...body.params, sort: `${EVENTS_SORT_BY_COLUMN}:${SORT_ORDER}` };

  const response = await getEventsByType({
    warehouseId: getState().appConfig.appConfig.activeWarehouseId,
    eventType: body.eventType,
    data: { ...getState().auth.tokens },
    params: params,
  });
  return response;
});

export const fetchRowEvent = createAsyncThunk('warehouses/fetchRawEvents', async (body, { getState }) => {
  const response = await getRawEventsByType({
    warehouseId: getState().appConfig.appConfig.activeWarehouseId,
    data: { ...getState().auth.tokens },
    eventId: body.eventId,
  });
  return response;
});
