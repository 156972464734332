import { createSlice } from '@reduxjs/toolkit';
import { fetchAvailableScenario, getUserProcessById } from './process.actions';

const initialState = {
  eventCases: [],
  actionCases: [],
  caseDetails: {},
  isLoading: false,
  status: 'initial',
};

export const processReducer = createSlice({
  name: 'process',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableScenario.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAvailableScenario.fulfilled, (state, action) => {
        state.status = 'in';
        state.isLoading = false;
        state.eventCases = action.payload
          .filter((item) => Object.keys(item.parameters).length === 0)
          .map((item) => {
            return {
              caseType: 'event',
              ...item,
            };
          });
        state.actionCases = action.payload
          .filter((item) => Object.keys(item.parameters).length !== 0)
          .map((item) => {
            return {
              caseType: 'action',
              ...item,
            };
          });
      })
      .addCase(fetchAvailableScenario.rejected, (state, action) => {
        state.status = 'in';
        state.isLoading = false;
        state.eventCases = {};
        state.actionCases = [];
      })
      .addCase(getUserProcessById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProcessById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.caseDetails = action.payload;
      })
      .addCase(getUserProcessById.rejected, (state, action) => {
        state.status = 'in';
        state.isLoading = false;
        state.caseDetails = {};
      });
  },
  reducers: {
    clearCaseDetails: (state) => {
      state.eventCases = [];
    },
  },
});

export const { clearCaseDetails } = processReducer.actions;

export default processReducer.reducer;
