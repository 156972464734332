import { createSlice } from '@reduxjs/toolkit';
import {
  fetchEventsForLastDayWidget,
  fetchEventsForLast7DaysWidget,
  fetchEventsForLast30DaysWidget,
  fetchEventsLast7DaysGroupedByDay,
} from './dashboard.actions';

const initialState = {
  events: {
    eventsLastDay: {
      isLoading: false,
      status: 'initial',
      data: {
        current: [],
        previous: [],
      },
    },
    eventsLast7Days: {
      isLoading: false,
      status: 'initial',
      data: {
        current: [],
        previous: [],
      },
    },
    eventsLast30Days: {
      isLoading: false,
      status: 'initial',
      data: {
        current: [],
        previous: [],
      },
    },
  },
  eventList: {
    data: [],
    isLoading: false,
    status: 'initial',
  },
  eventsLast7DaysGroupedByDay: {
    data: [],
    isLoading: false,
    status: 'initial',
  },
};

export const dashboardReducer = createSlice({
  name: 'dashboard',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchEventsForLastDayWidget.pending, (state) => {
        state.events.eventsLastDay.isLoading = true;
        state.events.eventsLastDay.status = 'pending';
      })
      .addCase(fetchEventsForLastDayWidget.fulfilled, (state, action) => {
        state.events.eventsLastDay.status = 'fulfilled';
        state.events.eventsLastDay.isLoading = false;

        action.payload.forEach((innerAction) => {
          state.events.eventsLastDay.data[innerAction.meta.arg.dataSet] = innerAction.payload;
        });
      })
      .addCase(fetchEventsForLastDayWidget.rejected, (state, action) => {
        state.events.eventsLast7Days.status = 'rejected';
        state.events.eventsLast7Days.isLoading = false;
      })
      .addCase(fetchEventsForLast7DaysWidget.pending, (state) => {
        state.events.eventsLast7Days.isLoading = true;
        state.events.eventsLast7Days.status = 'pending';
      })
      .addCase(fetchEventsForLast7DaysWidget.fulfilled, (state, action) => {
        state.events.eventsLast7Days.status = 'fulfilled';
        state.events.eventsLast7Days.isLoading = false;

        action.payload.forEach((innerAction) => {
          state.events.eventsLast7Days.data[innerAction.meta.arg.dataSet] = innerAction.payload;
        });
      })
      .addCase(fetchEventsForLast7DaysWidget.rejected, (state, action) => {
        state.events.eventsLast7Days.status = 'rejected';
        state.events.eventsLast7Days.isLoading = false;
      })
      .addCase(fetchEventsForLast30DaysWidget.pending, (state) => {
        state.events.eventsLast30Days.isLoading = true;
        state.events.eventsLast30Days.status = 'pending';
      })
      .addCase(fetchEventsForLast30DaysWidget.fulfilled, (state, action) => {
        state.events.eventsLast30Days.status = 'fulfilled';
        state.events.eventsLast30Days.isLoading = false;

        action.payload.forEach((innerAction) => {
          state.events.eventsLast30Days.data[innerAction.meta.arg.dataSet] = innerAction.payload;
        });
      })
      .addCase(fetchEventsForLast30DaysWidget.rejected, (state, action) => {
        state.events.eventsLast30Days.status = 'rejected';
        state.events.eventsLast30Days.isLoading = false;
      })
      .addCase(fetchEventsLast7DaysGroupedByDay.pending, (state) => {
        state.eventsLast7DaysGroupedByDay.status = 'pending';
        state.eventsLast7DaysGroupedByDay.isLoading = true;
      })
      .addCase(fetchEventsLast7DaysGroupedByDay.fulfilled, (state, action) => {
        state.eventsLast7DaysGroupedByDay.data = action.payload;
        state.eventsLast7DaysGroupedByDay.status = 'fulfilled';
        state.eventsLast7DaysGroupedByDay.isLoading = false;
      })
      .addCase(fetchEventsLast7DaysGroupedByDay.rejected, (state) => {
        state.eventsLast7DaysGroupedByDay.data = [];
        state.eventsLast7DaysGroupedByDay.status = 'rejected';
        state.eventsLast7DaysGroupedByDay.isLoading = false;
      });
  },
});

export default dashboardReducer.reducer;
