import { InputLabel, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';
import styled from '@emotion/styled';

export const FormInputText = ({
  name,
  control,
  label,
  placeholder,
  InputProps,
  inputProps,
  type,
  isSubmitting,
  isRequired,
  onBlur,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <InputComponentStyled>
          <InputLabel htmlFor={name}>{isRequired ? `${label}*` : label}</InputLabel>
          <TextField
            error={!!error?.message}
            disabled={isSubmitting}
            onChange={onChange}
            hiddenLabel
            onBlur={onBlur}
            value={value}
            type={type}
            placeholder={placeholder ? placeholder : ''}
            fullWidth
            size="small"
            InputProps={InputProps}
            inputProps={inputProps}
            helperText={error?.message ? error.message : ''}
          />
        </InputComponentStyled>
      )}
    />
  );
};

const InputComponentStyled = styled.div`
  .MuiTextField-root {
    margin-top: 4px;
    margin-bottom: 22px;
  }
`;
