import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { processState } from '../../../store/process/process.selector';
import { fetchAvailableScenario } from '../../../store/process/process.actions';

export const useProcessCreator = () => {
  const dispatch = useDispatch();
  const { eventCases } = useSelector(processState);

  const fetchAvailableScenarioList = () => !eventCases?.length && dispatch(fetchAvailableScenario());
  React.useEffect(() => {
    fetchAvailableScenarioList();
  }, []);
};
