import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { setFile } from '../../../store/onboarding/onboarding.reducer';

const TextFieldStyled = styled(TextField)(() => ({
  width: '100%',
  '& fieldset': {
    borderColor: '#0D6DC5',
  },
}));

export const FileList = ({ files }) => {
  const dispatch = useDispatch();

  if (files?.length === 0 || !files) {
    return;
  }

  return (
    <Grid2 container style={{ margin: 'auto' }} xs={11}>
      <Typography fontWeight={'bold'} mt={4} mb={2}>
        Wgrany plik:
      </Typography>
      {files.map((file) => (
        <TextFieldStyled
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => dispatch(setFile(null))}>
                <DeleteIcon color="error" />
              </IconButton>
            ),
          }}
          value={file.name}
          key={file}
        />
      ))}
    </Grid2>
  );
};
