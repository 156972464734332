export const getHeaders = (data) => {
  const accessToken = data?.accessToken ?? false;
  const apiSecret = data?.apiSecret ?? false;
  const contentType = data?.contentType ?? 'application/json';
  let headers = {};

  if (contentType) headers['content-type'] = contentType;
  if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
  if (apiSecret) headers['API-Token'] = apiSecret;

  return headers;
};

export const getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (key !== 'accessToken') {
      if (typeof object[key] !== 'object') formData.append(key, object[key]);
      else formData.append(key, JSON.stringify(object[key]));
    }
  });
  return formData;
};

export const getSearchParams = (object) => {
  return new URLSearchParams(object).toString();
};

export const isPlainObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';

export const omitObjectKey = (thisIsObject, omitKey) =>
  Object.keys(thisIsObject)
    .filter((key) => key !== omitKey)
    .reduce((obj, key) => {
      obj[key] = thisIsObject[key];
      return obj;
    }, {});

export const getSearchParamsString = (object) => {
  try {
    return '?' + new URLSearchParams(object).toString();
  } catch (e) {
    console.log(e.message);
    return '';
  }
};
