import { createSlice } from '@reduxjs/toolkit';
import { fetchWarehouseReconstructionWithSvg } from './reconstruction.action';

const initialState = {
  data: null,
  isLoading: false,
  status: 'initial',
  error: null,
};

export const reconstructionReducer = createSlice({
  name: 'reconstruction',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouseReconstructionWithSvg.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(fetchWarehouseReconstructionWithSvg.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchWarehouseReconstructionWithSvg.rejected, (state, action) => {
        state.status = 'rejected';
        state.isLoading = false;
        state.error = 'error';
      });
  },
});

export default reconstructionReducer.reducer;
