import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveStep } from '../../store/onboarding/onboarding.reducer';
import { onboardingState } from '../../store/onboarding/onboarding.selector';

export const Stepper = (props) => {
  const { activeStep = 1 } = useSelector(onboardingState);

  const [step, setStep] = React.useState(activeStep);
  const dispatch = useDispatch();

  const handleSaveStep = React.useCallback(
    (number) => {
      setStep(number);
      dispatch(setActiveStep(number));
    },
    [dispatch]
  );
  const onNextStep = () => handleSaveStep(step + 1);
  const onPrevStep = () => handleSaveStep(step - 1);
  const isStepActive = (index) => index + 1 === step;
  const navigateToStep = React.useCallback((navigateStep) => handleSaveStep(navigateStep), [handleSaveStep]);

  React.useEffect(() => {
    if (activeStep) {
      navigateToStep(activeStep);
    }
  }, [activeStep, navigateToStep]);

  React.useEffect(() => {
    if (props.initialStep) {
      navigateToStep(props.initialStep);
    }
  }, []);

  return (
    <>
      {React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, {
          onNextStep,
          onPrevStep,
          currentStep: step,
          isActive: isStepActive(index),
          navigateTo: (step) => navigateToStep(step),
        });
      })}
    </>
  );
};
