import React from 'react';
import styled from '@emotion/styled';

export const HeaderStyled = styled('p')`
  margin: 10px 0 10px 35px;
  font-size: 13px;
  color: #a1acb8;
  text-transform: uppercase;
`;
export const ListHeader = ({ children }) => {
  return <HeaderStyled>{children}</HeaderStyled>;
};
