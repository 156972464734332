import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWarehouseReconstructionAll, saveWarehouseReconstruction } from '../../api/reconstruction';

export const handleSaveWarehousePlan = createAsyncThunk(
  'onboarding/handleSaveWarehousePlan',
  async ({ warehouseId, body }) => {
    return await saveWarehouseReconstruction(warehouseId, body);
  }
);

export const fetchWarehouseReconstruction = createAsyncThunk(
  'onboarding/fetchWarehouseReconstruction',
  async ({ warehouseId }, { getState }) => {
    return await getWarehouseReconstructionAll(warehouseId, getState().auth.tokens.accessToken);
  }
);
