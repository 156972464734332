import { DynamicListItem } from '../../../../components/DynamicListItem/DynamicListItem';
import { Loader } from '../../../../components/Loader/Loader';
import { messages } from '../../../ProcessComposition/processCompositionMessages';
import { ActionChildStyled, MainChildStyled } from '../../../../components/DynamicListItem/DynamicListItemStyled';
import {
  AddNewWarehouseButtonStyled,
  StatusChildStyled,
  WarehouseManagementListHeader,
  WarehouseManagementListStyled,
} from './WarehouseManagementListStyled';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWarehouseById } from '../../../../store/warehouses/warehouses.actions';
import React from 'react';
import { warehouses, appConfigState } from '../../../../store/appConfig/appConfig.selector';

export const WarehouseManagementList = ({ onNextStep, navigateTo, handleWarehouseState }) => {
  const [isWarehouseLoading, setWarehouseLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { list, isLoading } = useSelector(warehouses);
  const { warehousesLimit } = useSelector(appConfigState);

  const handleAddNewWarehouse = () => onNextStep();
  const handleEditWarehouse = (warehouseId) => () => {
    setWarehouseLoading(true);
    dispatch(fetchWarehouseById(warehouseId)).then((item) => {
      navigateTo(3);
      setWarehouseLoading(false);
      handleWarehouseState(item.payload);
    });
  };

  return (
    <WarehouseManagementListStyled>
      <WarehouseManagementListHeader>{'Lista Twoich magazynów'}</WarehouseManagementListHeader>
      {isLoading || isWarehouseLoading ? (
        <div className="wrapperLoader">
          <Loader />
        </div>
      ) : (
        <>
          <ul>
            <DynamicListItem
              processChild={'NAZWA MAGAZYNU'}
              statusChild={'STATUS'}
              actionChild={'AKCJE'}
              isFirst={true}
            />
            {list.map((item) => (
              <DynamicListItem
                key={item.id}
                title={messages.button.shows}
                isFirst={false}
                id={item.id}
                isHoover={false}
                processChild={<MainChildStyled>{item.name}</MainChildStyled>}
                statusChild={<StatusChildStyled>{item.active ? 'Aktywny' : 'Niekatywny'}</StatusChildStyled>}
                actionChild={<ActionChildStyled onClick={handleEditWarehouse(item.id)}>{'Edytuj'}</ActionChildStyled>}
              />
            ))}
          </ul>
          <AddNewWarehouseButtonStyled disabled={list.length >= warehousesLimit} onClick={handleAddNewWarehouse}>
            {'Dodaj nowy magazyn'}
          </AddNewWarehouseButtonStyled>
        </>
      )}
    </WarehouseManagementListStyled>
  );
};
