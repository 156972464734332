import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReportsList } from '../../api/reportsList';

export const fetchReportsList = createAsyncThunk(
  'reports/fetchReportList',
  async (_, { getState }) =>
    await getReportsList({
      data: { ...getState().auth.tokens },
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
    })
);
