import { createSlice } from '@reduxjs/toolkit';
import { fetchHistoryProcessList } from './historyProcessList.actions';

const initialState = {
  historyProcessList: [],
  isLoading: false,
  status: 'initial',
};

export const historyProcessListReducer = createSlice({
  name: 'historyProcessList',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoryProcessList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHistoryProcessList.fulfilled, (state, action) => {
        state.status = 'in';
        state.isLoading = false;
        state.historyProcessList = action.payload;
      })
      .addCase(fetchHistoryProcessList.rejected, (state, action) => {
        state.status = 'in';
        state.isLoading = false;
        state.historyProcessList = [];
      });
  },
});

export default historyProcessListReducer.reducer;
