import { DateHelper } from './dateHelper';

export const dateRanges = {
  lastDay: {
    current: {
      timestamp_from: new DateHelper().getDateWithOffset(1).getStartOfDay().getTime(),
      timestamp_to: new DateHelper().getDateWithOffset(1).getEndOfDay().getTime(),
    },
    previous: {
      timestamp_from: new DateHelper().getDateWithOffset(2).getStartOfDay().getTime(),
      timestamp_to: new DateHelper().getDateWithOffset(2).getEndOfDay().getTime(),
    },
  },
  last7Days: {
    current: {
      timestamp_from: new DateHelper().getDateWithOffset(8).getStartOfDay().getTime(),
      timestamp_to: new DateHelper().getDateWithOffset(1).getEndOfDay().getTime(),
    },
    previous: {
      timestamp_from: new DateHelper().getDateWithOffset(16).getStartOfDay().getTime(),
      timestamp_to: new DateHelper().getDateWithOffset(9).getEndOfDay().getTime(),
    },
  },
  last30Days: {
    current: {
      timestamp_from: new DateHelper().getDateWithOffset(31).getStartOfDay().getTime(),
      timestamp_to: new DateHelper().getDateWithOffset(1).getEndOfDay().getTime(),
    },
    previous: {
      timestamp_from: new DateHelper().getDateWithOffset(62).getStartOfDay().getTime(),
      timestamp_to: new DateHelper().getDateWithOffset(32).getEndOfDay().getTime(),
    },
  },
};
