import styled from '@emotion/styled';

export const ProcessCompositionDiagramsStyled = styled.div`
  background: white;
  padding: 20px;
  border-radius: 0 0 8px 8px;

  .arrow {
    position: absolute;
    bottom: -30px;
  }

  ul {
    list-style: none;
    width: 210px;
    text-align: center;
  }

  .modal {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    width: 385px;
    padding: 10px 5px;

    &Buttons {
      display: flex;
    }

    &Button {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    p {
      margin-left: 10px;
      color: #a1acb8;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }
  
  .wrapperLoader {
    position: absolute;
    top: 20%;
    opacity: 0.5;
  }

  .main {
    display: flex;
    justify-content: center;

    &Content {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      width: 100%;
    }
  }

  .creator {
    margin-bottom: 20px;

    &Content {
      min-height: 500px;
    }

    &Separator {
      border-right: 2px solid rgba(67, 89, 113, 0.1);
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      min-height: 500px;
      align-items: center;
    }
  }

  .diagramItem {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;

    &AddIcon {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }

    &ArrowIcon {
      position: absolute;
      bottom: -30px;
      height: 40px;

      &Hide {
        display: none;
      }
    }

    &Modal {
      position: absolute;
      left: 210px;
      top: 10px;
      radius: 5px
    }
  }
`;
