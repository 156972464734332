import LumilookTheme from '../globalStyles/lumilookTheme';
import {
  EVENT_GROUP_FORKLIFT_HIT_PERSON,
  EVENT_GROUP_NAME_FORKLIFT_NEAR_PERSON,
  EVENT_GROUP_NAME_NO_HELMET,
  EVENT_GROUP_NAME_NO_VEST,
  EVENT_GROUP_NAME_PERSON_LYING_DOWN,
  EVENT_GROUP_NAME_SOCIAL_DISTANCING,
  EVENT_GROUP_NAMES,
  LABEL_DATE_FORMAT,
} from '../constants';
import { matchEventDefinitions } from './utils';
import dayjs from 'dayjs';

export const getPieChartData = (data, eventNameDefinitions) => {
  const labels = [];
  const chartData = [];
  const colors = [];

  if (Array.isArray(data)) {
    data.forEach((event) => {
      labels.push(matchEventDefinitions(event?.name, eventNameDefinitions) ?? 'unknown');
      chartData.push(event?.count ?? 0);
      colors.push(matchColorForEventGroupName(event?.name));
    });
  }
  return {
    labels: labels,
    datasets: [
      {
        backgroundColor: colors,
        data: chartData,
        borderWidth: 1,
      },
    ],
  };
};

export const getHighestValueForBarChartData = (data) => {
  let highestNum = Math.max(
    ...Object.values(data).map((item) => {
      return item.reduce((acc, value) => {
        return acc > value?.count ?? 0 ? acc : value.count;
      }, 0);
    })
  );
  if (isNaN(highestNum)) highestNum = 0;
  return highestNum;
};

export const getBarChartData = (data, eventNameDefinitions) => {
  const arrayData = Object.values(data);
  return EVENT_GROUP_NAMES.map((eventGroupName) => {
    return {
      label: matchEventDefinitions(eventGroupName, eventNameDefinitions) ?? 'unknown',
      data: matchEvents(arrayData, eventGroupName),
      backgroundColor: matchColorForEventGroupName(eventGroupName),
    };
  });
};

export const matchColorForEventGroupName = (eventGroupName) => {
  const colorStrategy = {
    [EVENT_GROUP_FORKLIFT_HIT_PERSON]: () => LumilookTheme.palette.charts.red,
    [EVENT_GROUP_NAME_SOCIAL_DISTANCING]: () => LumilookTheme.palette.charts.blue,
    [EVENT_GROUP_NAME_PERSON_LYING_DOWN]: () => LumilookTheme.palette.charts.violet,
    [EVENT_GROUP_NAME_FORKLIFT_NEAR_PERSON]: () => LumilookTheme.palette.charts.bloodRed,
    [EVENT_GROUP_NAME_NO_HELMET]: () => LumilookTheme.palette.charts.yellow,
    [EVENT_GROUP_NAME_NO_VEST]: () => LumilookTheme.palette.charts.green,
  };
  if (!!colorStrategy[eventGroupName]) {
    return colorStrategy[eventGroupName]();
  }

  return LumilookTheme.palette.charts.red;
};

const matchEvents = (eventList, type) => {
  const flatData = eventList.flat();
  let events = flatData.filter((item) => item.name === type).map((item) => item?.count ?? 0);
  if (events.length === 0) events[0] = 0;
  return events;
};

export const getDangerLevelChartData = (ACSAData, ACSABenchmark) => {
  const benchmark = [...ACSABenchmark].sort((a, b) => a?.value - b?.value);
  const data = [...ACSAData].sort((a, b) => a?.timestamp - b?.timestamp);
  if (benchmark.length === 3) {
    return {
      labels: data.map((item) => dayjs.unix(item.timestamp).format(LABEL_DATE_FORMAT)),
      datasets: benchmark.map((level) => {
        return {
          backgroundColor: matchColorForDangerLevel(level.severity),
          label: data.map((item) =>
            getHighestDangerLevelNameForCurrentProbability(item.risk.probabilities.total, benchmark)
          ),
          data: data.map((item) => checkDangerLevel(item.risk.probabilities.total, level.value)),
        };
      }),
    };
  }
};

const getHighestDangerLevelNameForCurrentProbability = (probability, benchmark) => {
  let levelName = '';
  for (const level of benchmark) {
    if (level.value <= probability) levelName = level.severity;
  }
  return `${levelName}: ${probability}`;
};

const checkDangerLevel = (probability, severity) => {
  if (probability >= severity) return severity;
  else return 0;
};

const matchColorForDangerLevel = (severity = '') => {
  const colorStrategy = {
    ['medium']: () => LumilookTheme.palette.charts.yellow,
    ['low']: () => LumilookTheme.palette.charts.green,
    ['high']: () => LumilookTheme.palette.charts.bloodRed,
  };

  if (!!colorStrategy[severity]) {
    return colorStrategy[severity]();
  }

  return LumilookTheme.palette.charts.red;
};

export const dangerLevelChartOptions = {
  tooltip: {
    callbacks: {
      label: (context) => context.dataset.label[context.dataIndex] || '',
    },
  },
};
