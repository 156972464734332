import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { fetchWarehouseEvents } from '../../../store/events/events.actions';
import { eventsState } from '../../../store/events/events.selector';
import { useParams } from 'react-router';
import { matchExtendedEventDefinition, restParamsObject, setUrlPageParamOnStart } from '../../../utils/utils';
import { appConfigState, eventDefinitionState } from '../../../store/appConfig/appConfig.selector';
import { eventGroupedName, headers, rawEventHeaders } from './contents';

export const useEvents = () => {
  const [level, setLevel] = useState(1);
  const [eventType, setEventType] = useState('');
  const [urlParams, setUrlParams] = useState('');
  const [tableHeaders, setTableHeaders] = useState(headers);
  const [markers, setMarkers] = useState([]);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { eventList, isLoading } = useSelector(eventsState);
  const { activeWarehouseId } = useSelector(appConfigState);
  const { eventType: pathEventType } = useParams();
  const [searchParams] = useSearchParams();
  const { list } = useSelector(eventDefinitionState);

  const handleEventRequest = useCallback(() => {
    dispatch(fetchWarehouseEvents({ eventType: eventType, params: restParamsObject(searchParams) }));
  }, [dispatch, eventType, searchParams]);

  useEffect(() => {
    let eventRows = [];
    let eventMarkers = [];
    const sortedEventList = [...eventList].sort((a, b) => a.read - b.read);

    const getEventName = (name, level, list) => {
      if (level === 1) {
        return matchExtendedEventDefinition(name, list);
      }

      if (level === 2) {
        return `${eventGroupedName}`;
      }

      if (level === 3) {
        return ``;
      }
    };

    for (const event of sortedEventList) {
      const { name, count, instances, id, x, y, z, timestamp } = event;

      let eventRow = {
        slug: matchSlugToLevel(level, activeWarehouseId, name, search, instances),
        name: getEventName(name, level, list),
        count,
        instances: Boolean(instances) ? instances.map((item) => ` ${item}`) : '',
        id,
      };

      if (level === 3) {
        eventRow.idName = id;
        eventRow.timestamp = timestamp;
        delete eventRow.count;
      }

      eventRows.push(eventRow);
      if (typeof x == 'number' && typeof y == 'number' && typeof z == 'number') {
        eventMarkers.push({
          name: matchExtendedEventDefinition(name, list),
          count: count ?? 0,
          id,
          position: [x * 100, y * 100],
        });
      }
    }
    setRows(eventRows);
    setMarkers(eventMarkers);
  }, [eventList, activeWarehouseId, level, list, search]);

  useEffect(() => {
    handleSetLevel(pathname, setLevel);
    handleSetEventType(pathEventType, setEventType);
    handleUrlParams(search, setUrlParams);
  }, [pathname, search, pathEventType]);

  useEffect(() => {
    if (level === 3) {
      setTableHeaders(rawEventHeaders);
    } else {
      setTableHeaders(headers);
    }
  }, [level]);

  // useEffect(() => {
  //   if (!!eventType && !!urlParams && !isLoading) {
  //     setTimeout(() => handleEventRequest(), 200);
  //   }
  // }, [urlParams, handleEventRequest, eventType]);

  useEffect(() => {
    setTimeout(() => {
      if (eventType && urlParams && !isLoading) {
        handleEventRequest();
      }
    }, 200);
  }, [urlParams]);

  return {
    rows,
    level,
    isLoading,
    eventList,
    tableHeaders,
    markers,
  };
};

const matchSlugToLevel = (level, activeWarehouseId, name, search, instances) => {
  if (level === 1) {
    const preparedSearch = setUrlPageParamOnStart(search);
    return `/warehouse-details/${activeWarehouseId}/events/type_instance?event_type=${name}&${preparedSearch.replace(
      '?',
      ''
    )}`;
  }

  if (level === 2) {
    const preparedSearch = search
      .split('&')
      .filter((item) => !item.includes('event_type='))
      .join('&');

    const preparedInstance = prepareInstance(instances);

    return `/warehouse-details/${activeWarehouseId}/events/raw?event_type=${name}${preparedInstance}&${setUrlPageParamOnStart(
      preparedSearch.replace('?', '')
    )}`;
  }

  return '';
};

const handleSetLevel = (pathname, setLevel) => {
  if (pathname.includes('raw')) {
    return setLevel(3);
  }

  if (pathname.includes('type_instance')) {
    return setLevel(2);
  }

  if (pathname.includes('type')) {
    return setLevel(1);
  }
};

const handleSetEventType = (pathEventType, setEventType) => setEventType(pathEventType);

const handleUrlParams = (search, setUrlParams) => setUrlParams(search || '');

export const prepareInstance = (instances) => {
  if (instances) {
    return `&instances=${instances}`;
  } else {
    return '';
  }
};
