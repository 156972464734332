import { useNavigate } from 'react-router';
import LayoutMain from '../components/layout/layoutMain';
import { useEffect } from 'react';

export const ProtectedRoute = ({ loggedIn, isLoading, authStatus, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn !== true && !isLoading && authStatus !== 'initial') navigate('/login');
  }, [loggedIn, isLoading, authStatus]);

  return <>{loggedIn && <LayoutMain>{children}</LayoutMain>}</>;
};
