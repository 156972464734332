import styled from '@emotion/styled';

export const ReportsPageListStyled = styled.div`
  border-radius: 8px;
  background: white;
  padding: 20px;

  li {
    > div {
      padding-left: 0;
    }
  }

  ul {
    list-style: none;
  }

  .header {
    &Title {
      color: #697a8d;
      font-weight: 700;
      font-size: 18px;
      line-height: 19px;
      margin-bottom: 21px;
    }

    &Description {
      line-height: 25px;
      font-weight: 400;
      color: #566a7f;
      margin-bottom: 23px;
    }
  }
`;
