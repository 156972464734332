export const reportSelectOptions = [
  {
    value: 1,
    label: 'Co miesiąc',
  },
  {
    value: 2,
    label: 'Co dwa tygodnie zdarzeń',
  },
  {
    value: 3,
    label: 'Co tydzień',
  },
  {
    value: 4,
    label: 'Co dziennie',
  },
];
