import styled from '@emotion/styled';

export const AddWarehouseStyled = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 26px;

  .wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
`;

export const AddWarehouseTitle = styled.p`
  color: #697a8d;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
`;

export const AddWarehouseMainTitle = styled.p`
  color: #435971;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 30px;
  margin-bottom: 10px;
`;
