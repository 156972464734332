import { createSlice } from '@reduxjs/toolkit';
import { fetchEventsHistogram } from './eventsHistogram.action';

const initialState = {
  histogram: {
    data: [],
    isLoading: false,
    status: 'initial',
  },
};

export const eventsHistogram = createSlice({
  name: 'eventsHistogram',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventsHistogram.pending, (state) => {
        state.histogram.isLoading = true;
        state.histogram.status = 'pending';
      })
      .addCase(fetchEventsHistogram.fulfilled, (state, action) => {
        state.histogram.status = 'fulfilled';
        state.histogram.isLoading = false;
        state.histogram.data = action.payload;
      })
      .addCase(fetchEventsHistogram.rejected, (state, action) => {
        state.histogram.status = 'rejected';
        state.histogram.isLoading = false;
      });
  },
});

export default eventsHistogram.reducer;
