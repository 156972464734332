import { Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css';
import { eventIconDefault } from '../CustomIcon/IconsDefinitions';
import { useDispatch } from 'react-redux';
import { setIdOfEventToShow } from '../../../store/events/events.reducer';

export const EventMarker = ({ position, data }) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(setIdOfEventToShow(data.id));
  };

  return (
    <Marker
      position={position}
      icon={eventIconDefault}
      eventHandlers={{
        click: () => {
          onClickHandler();
        },
      }}
    />
  );
};
