import React from 'react';
import { Stepper } from '../../components/Stepper/Stepper';
import { Step } from '../../components/Step/Step';
import { ReportsPageCreator } from './ReportsPageCreator/ReportsPageCreator';
import { ReportsPageHeader } from './ReportsPageHeader/ReportsPageHeader';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ReportsPageList } from './ReportsPageLists/ReportsPageList';
import { useReportPage } from './useReportPage';
import { messages } from '../ProcessComposition/processCompositionMessages';
import { ReportsPageStyled } from './ReportsPageStyled';

export const ReportsPage = () => {
  const { reportsList, predefinedReportsList, isLoading, isPredefinedListLoading } = useReportPage();
  const handleEditReport = (processId) => {
    // fetch report id
  };

  return (
    <Stepper>
      <Step
        step={1}
        render={(props) => {
          return (
            <ReportsPageStyled>
              <Grid2 container columns={12} rowSpacing={2} columnSpacing={3}>
                <Grid2 xs={12} lg={6}>
                  <ReportsPageHeader onNextStep={props.onNextStep} />
                  <ReportsPageList
                    list={reportsList}
                    isPredefinedList={false}
                    isLoading={isLoading}
                    navigateTo={props.navigateTo}
                    handleEdit={handleEditReport}
                    title={messages.reports.title}
                    description={messages.reports.description}
                  />
                </Grid2>
                <Grid2 xs={12} lg={6}>
                  <ReportsPageList
                    list={predefinedReportsList}
                    isPredefinedList={true}
                    isLoading={isPredefinedListLoading}
                    title={messages.reports.predefinedTitle}
                    description={messages.reports.predefinedDescription}
                  />
                </Grid2>
              </Grid2>
            </ReportsPageStyled>
          );
        }}
      />
      <Step
        step={2}
        render={(props) => {
          return <ReportsPageCreator onPrevStep={props.onPrevStep} />;
        }}
      />
    </Stepper>
  );
};
