import { ProcessCompositionHeader } from '../ProcessCompositionHeader/ProcessCompositionHeder';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ProcessCompositionCreatorStyled } from './ProcessCompositionCreatorStyled';
import { messages } from '../processCompositionMessages';
import React from 'react';
import { ProcessCompositionDiagram } from '../ProcessCompositionDiagram/ProcessCompositionDiagram';
import { useProcessCreator } from './useProcessCreator';

export const ProcessCompositionCreator = ({ navigateTo, selectedId, isEditProcess }) => {
  useProcessCreator();

  return (
    <>
      <ProcessCompositionHeader
        isNewProceessSectionEnabled={false}
        title={messages.creator.header.title}
        description={messages.creator.header.description}
      />
      <Grid2 container rowSpacing={2} columns={12}>
        <Grid2 xs={12}>
          <ProcessCompositionCreatorStyled>
            <div className="creator">
              <p className="creatorTitle">{messages.creator.header.title}</p>
              <span className="creatorDescription">{messages.creator.header.description}</span>
            </div>
          </ProcessCompositionCreatorStyled>
          <ProcessCompositionDiagram
            navigateTo={navigateTo}
            selectedId={selectedId}
            isEditProcess={isEditProcess}
          />
        </Grid2>
      </Grid2>
    </>
  );
};
