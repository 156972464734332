import { createSlice } from '@reduxjs/toolkit';
import { fetchAppConfigInitialData, fetchWarehousesList } from './appConfig.action';

const initialState = {
  warehouses: {
    isLoading: false,
    status: 'initial',
    list: [],
    error: null,
  },
  eventDefinition: {
    list: [],
  },
  appConfig: {
    activeWarehouseId: 0,
    onBoardingWarehouseId: 0,
    warehousesLimit: 0,
    isAnyWarehouseActive: false,
    areAllFetched: false,
  },
};

export const appConfigReducer = createSlice({
  name: 'appConfig',
  initialState,

  reducers: {
    setActiveWarehouseId: (state, action) => {
      state.appConfig.activeWarehouseId = action.payload;
    },
    setOnboardingActiveWarehouseId: (state, action) => {
      state.appConfig.onBoardingWarehouseId = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAppConfigInitialData.pending, (state) => {
        state.warehouses.isLoading = true;
        state.warehouses.status = 'pending';
      })
      .addCase(fetchAppConfigInitialData.fulfilled, (state, action) => {
        state.warehouses.status = 'fulfilled';
        state.warehouses.isLoading = false;
        state.warehouses.list = action.payload.warehouseResponse;
        state.eventDefinition.list = action.payload.eventDefinitionResponse;
        state.appConfig.activeWarehouseId = action.payload.warehouseResponse.find((item) => item)?.id || 1;
        state.appConfig.warehousesLimit = action.payload.warehousesLimitResponse?.warehouse_limit || 999;
        state.appConfig.isAnyWarehouseActive = action.payload.warehouseResponse.some((item) => item.active || true);
        state.appConfig.areAllFetched = true;
      })
      .addCase(fetchAppConfigInitialData.rejected, (state, action) => {
        state.warehouses.status = 'error';
        state.warehouses.isLoading = false;
        state.warehouses.list = [];
      })
      .addCase(fetchWarehousesList.fulfilled, (state, action) => {
        state.warehouses.status = 'fulfilled';
        state.warehouses.isLoading = false;
        state.warehouses.list = action.payload.warehouseResponse;
        state.appConfig.activeWarehouseId = action.payload.warehouseResponse.find((item) => item)?.id || 1;
        state.appConfig.isAnyWarehouseActive = action.payload.warehouseResponse.some((item) => item.active || true);
      });
  },
});

export const { setActiveWarehouseId, setOnboardingActiveWarehouseId } = appConfigReducer.actions;

export default appConfigReducer.reducer;
