import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGranularity, restParamsObject } from '../../../utils/utils';
import { useSearchParams } from 'react-router-dom';
import { ACSABenchmarkState, ACSADataState } from '../../../store/warehouseDetails/dangerLevel/dangerLevel.selector';
import { fetchACSABenchmark, fetchDangerLevel } from '../../../store/warehouseDetails/dangerLevel/dangerLevel.action';
import { getDangerLevelChartData } from '../../../utils/chart.helpers';

export const useDangerLevel = () => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { data: ACSAData, isLoading: ACSADataIsLoading } = useSelector(ACSADataState);
  const { data: ACSABenchmarkData, isLoading: ACSABenchmarkIsLoading } = useSelector(ACSABenchmarkState);

  useEffect(() => {
    dispatch(fetchACSABenchmark());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchDangerLevel({
        params: {
          ...restParamsObject(searchParams, ['timestamp_from', 'timestamp_to']),
          aggregation: getGranularity(searchParams.get('timestamp_from'), searchParams.get('timestamp_to')),
        },
      })
    );
  }, [searchParams, dispatch]);

  useEffect(() => {
    setIsLoading(ACSADataIsLoading && ACSABenchmarkIsLoading);
  }, [ACSADataIsLoading, ACSABenchmarkIsLoading]);

  useEffect(() => {
    if (!ACSADataIsLoading && !!ACSABenchmarkData?.length) {
      try {
        setChartData(getDangerLevelChartData(ACSAData, ACSABenchmarkData));
      } catch (e) {}
    }
  }, [ACSAData, ACSABenchmarkData, ACSADataIsLoading]);

  return {
    chartData,
    isLoading,
  };
};
