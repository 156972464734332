import styled from '@emotion/styled';

export const WarehouseManagementListStyled = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;

  .wrapperLoader {
    height: 100px;
  }

  ul {
    list-style: none;
  }

  li:first-of-type {
    padding-bottom: 11px;
    padding-top: 0;
  }

  li {
    padding: 17px 0;
  }
`;

export const AddNewWarehouseButtonStyled = styled.button`
  width: 350px;
  height: 50px;
  font-weight: 700;
  line-height: 21px;
  font-size: 18px;
  color: #566a7f;
  border-radius: 4px;
  border: 2px solid #0d6dc5;
  background: white;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.disabled ? '0.1' : '1')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const WarehouseManagementListHeader = styled.p`
  color: #697a8d;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
`;

export const StatusChildStyled = styled.div`
  font-weight: 600;
  line-height: 23px;
  color: #697a8d;
`;
