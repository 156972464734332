import * as React from 'react';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster/src/react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css';
import { RecommendationMarker } from './RecommendationMarker';

export const RecommendationsCluster = ({ mapIsReady, points }) => {
  return (
    <>
      {mapIsReady && (
        <MarkerClusterGroup
          spiderLegPolylineOptions={{
            weight: 0,
            opacity: 0,
          }}
        >
          {points.map(
            (point, index) =>
              point.recommendation.marker && (
                <RecommendationMarker
                  position={point.recommendation.marker.position}
                  key={index}
                  data={point.recommendation}
                />
              )
          )}
        </MarkerClusterGroup>
      )}
    </>
  );
};
