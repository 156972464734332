import { ActionButtonsTypeStyled, EventButtonsTypeStyled } from './ProcessCompositionButtonsStyled';

export const CaseType = {
  ACTION: 'action',
  EVENT: 'event',
};

export const ProcessCompositionButton = ({ caseType, onClick, disabled, title }) => {
  if (caseType === CaseType.EVENT) {
    return (
      <ActionButtonsTypeStyled disabled={disabled} onClick={onClick}>
        {title ? title : 'ZDARZENIE'}
      </ActionButtonsTypeStyled>
    );
  }

  if (caseType === CaseType.ACTION) {
    return (
      <EventButtonsTypeStyled disabled={disabled} onClick={onClick}>
        {title ? title : 'AKCJA'}
      </EventButtonsTypeStyled>
    );
  }

  return null;
};
