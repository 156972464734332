import Grid2 from '@mui/material/Unstable_Grid2';
import {
  CaseType,
  ProcessCompositionButton,
} from '../ProcessCompositionCreator/ProcessCompositionButton/ProcessCompositionButtons';
import React from 'react';
import { useModal } from '../../../components/Modal/useModal';
import { FakeModal } from './FakeModal/FakeModal';
import { ProcessCompositionDiagramsStyled } from './ProcessCompositionDiagramStyled';
import { useDiagram } from './useDiagram';
import { DiagramCaseDetails } from './DiagramCaseDetails/DiagramCaseDetails';
import { SaveProcessModal } from '../SaveProcessModal/SaveProcessModal';
import {
  CreatorCancelButtonStyled,
  CreatorRemoveButtonStyled,
  CreatorSaveButtonStyled,
} from '../../../components/CreatorComponents/CreatorButtonStyled';
import { useDiagramForm } from './useDiagramForm';
import { Loader } from '../../../components/Loader/Loader';

export const ProcessCompositionDiagram = ({ navigateTo, isEditProcess, selectedId }) => {
  const { control, reset, setValue, getValues } = useDiagramForm();
  const { handleToggleModal, isModalOpen } = useModal();
  const { handleToggleModal: handleToggleSaveModal, isModalOpen: isSaveModalOpen } = useModal();
  const {
    removeCase,
    removeProcess,
    handleAddCase,
    handleSaveProcess,
    handleCaseDetails,
    handleCancelProcess,
    handleSetActiveCase,
    toggleDisableAddNewCase,
    isSaveProcessDisabled,
    isAddNewCaseDisabled,
    activeCase,
    itemList,
    isLoading,
  } = useDiagram(isEditProcess, selectedId, setValue, navigateTo, reset);

  const handleAddNewCase = (caseType) => () => {
    handleSetActiveCase(itemList.length)();
    handleToggleModal(false)();
    handleAddCase(caseType);
  };

  const handleEditCase = (index) => () => handleSetActiveCase(index)();
  const handleNewCaseOnPlusIcon = () => !isAddNewCaseDisabled && handleToggleModal(true)();
  const checkIsEvent = !itemList.find((item) => item.caseType === CaseType.EVENT);
  const checkIsEventAndIsAction =
    !itemList.find((item) => item.caseType === CaseType.EVENT) ||
    !itemList.find((item) => item.caseType === CaseType.ACTION);

  return (
    <>
      <ProcessCompositionDiagramsStyled>
        <Grid2 container className="creatorContent">
          <Grid2 lg={1.8}>
            <div className={'creatorSeparator'} id="myPaletteDiv">
              <ProcessCompositionButton
                caseType={CaseType.EVENT}
                disabled={isAddNewCaseDisabled}
                onClick={handleAddNewCase(CaseType.EVENT)}
              />
              <ProcessCompositionButton
                caseType={CaseType.ACTION}
                disabled={isAddNewCaseDisabled || checkIsEvent}
                onClick={handleAddNewCase(CaseType.ACTION)}
              />
            </div>
          </Grid2>
          <Grid2 lg={10.2} className="main">
            <div className={'mainContent'}>
              {isLoading ? (
                <div className="wrapperLoader">
                  <Loader />
                </div>
              ) : (
                <>
                  <ul>
                    {itemList.map((item, index) => {
                      return (
                        <li key={index} className="diagramItem">
                          <ProcessCompositionButton
                            caseType={item.caseType}
                            onClick={handleEditCase(index)}
                            title={item.caseDetails?.name}
                          />
                          <div className="diagramItemModal">
                            <FakeModal isOpen={activeCase === index}>
                              <DiagramCaseDetails
                                item={item}
                                index={index}
                                getValues={getValues}
                                initialValue={item.caseDetails?.name}
                                handleToggleModal={handleToggleModal}
                                handleCaseDetails={handleCaseDetails}
                                toggleDisableAddNewCase={toggleDisableAddNewCase}
                                removeCase={removeCase}
                                control={control}
                              />
                            </FakeModal>
                          </div>
                          <img
                            alt={'arrow'}
                            className={
                              index + 1 === itemList.length ? 'diagramItemArrowIconHide' : 'diagramItemArrowIcon'
                            }
                            src={'/static/diagram-arrow.svg'}
                          />
                        </li>
                      );
                    })}
                  </ul>
                  <div>
                    <img
                      className="diagramItemAddIcon"
                      src={'/static/plus.svg'}
                      alt={'add process'}
                      onClick={handleNewCaseOnPlusIcon}
                    />
                  </div>
                </>
              )}
              <FakeModal isOpen={isModalOpen}>
                <div className="modal">
                  <p>{'Wybierz element który chcesz dodać'}</p>
                  <div className="modalButtons">
                    <div className="modalButton">
                      <ProcessCompositionButton
                        caseType={CaseType.EVENT}
                        disabled={isAddNewCaseDisabled}
                        onClick={handleAddNewCase(CaseType.EVENT)}
                      />
                    </div>
                    <div className="modalButton">
                      <ProcessCompositionButton
                        caseType={CaseType.ACTION}
                        disabled={isAddNewCaseDisabled || checkIsEvent}
                        onClick={handleAddNewCase(CaseType.ACTION)}
                      />
                    </div>
                  </div>
                </div>
              </FakeModal>
            </div>
          </Grid2>
        </Grid2>
      </ProcessCompositionDiagramsStyled>
      <div className="saveWrapper">
        <CreatorCancelButtonStyled onClick={handleCancelProcess}>{'Anuluj'}</CreatorCancelButtonStyled>
        <CreatorSaveButtonStyled
          disabled={isSaveProcessDisabled || checkIsEventAndIsAction}
          onClick={handleToggleSaveModal(true)}
        >
          {'Zapisz proces'}
        </CreatorSaveButtonStyled>
        {isEditProcess && (
          <CreatorRemoveButtonStyled onClick={removeProcess(selectedId)}>{'Usuń proces'}</CreatorRemoveButtonStyled>
        )}
      </div>
      <SaveProcessModal
        isEditProcess={isEditProcess}
        handleSaveProcess={handleSaveProcess}
        isSaveModalOpen={isSaveModalOpen}
        handleCloseModal={handleToggleSaveModal(false)}
      />
    </>
  );
};
