import React from 'react';
import { Router } from './routes/routes';
import { Global } from '@emotion/react';
import { GlobalStyles } from './globalStyles';
import LumilookTheme from './globalStyles/lumilookTheme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

function App() {
  return (
    <>
      <MuiThemeProvider theme={LumilookTheme}>
        <ThemeProvider theme={LumilookTheme}>
          <ErrorBoundary>
            <SnackbarProvider maxSnack={5} preventDuplicate autoHideDuration={7000}>
              <Global styles={GlobalStyles} />
              <Router />
            </SnackbarProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </MuiThemeProvider>
    </>
  );
}

export default App;
