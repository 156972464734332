import { InputComponent } from '../../../components/InputComponent';
import React from 'react';
import { Modal } from '../../../components/Modal/Modal';
import { useInputValue } from '../../../hooks/useInputValue';
import { CreatorSaveButtonStyled } from '../../../components/CreatorComponents/CreatorButtonStyled';
import { ModalContent } from '../../../components/Modal/ModalContent';
import { useSelector } from 'react-redux';
import { processState } from '../../../store/process/process.selector';

export const SaveProcessModal = ({ handleSaveProcess, isSaveModalOpen, handleCloseModal, isEditProcess }) => {
  const { caseDetails } = useSelector(processState);
  const { inputValue, handleInputChange } = useInputValue(isEditProcess ? caseDetails?.name : undefined);
  const { inputValue: intervalValue, handleInputChange: handleIntervalValue } = useInputValue(
    isEditProcess ? caseDetails?.run_unique_every_minutes : undefined
  );
  const onSaveButtonClick = () => handleSaveProcess(inputValue, intervalValue, isEditProcess);

  return (
    <Modal isModalOpen={isSaveModalOpen} maxWidth="lg" fullWidth={true} backgroundColor={'rgba(0, 0, 0, 0.75)'}>
      <ModalContent
        headerTitle={'Wprowadź nazwę procesu'}
        handleCloseButton={handleCloseModal}
        body={
          <>
            <InputComponent
              placeholder={'Nazwa procesu'}
              value={inputValue || ''}
              label={'Wprowadź nazwę procesu, która będzie wyświetlana w panelu administracyjnym.'}
              handleChange={handleInputChange}
            />
            <InputComponent
              type={'number'}
              placeholder={'Wprowadz interwał w minutach'}
              value={intervalValue || ''}
              label={'Zdefiniuj co ile minut chcesz chcesz otrzymywać powiadomienia o wykrytych zdarzeniach.'}
              handleChange={handleIntervalValue}
            />
          </>
        }
        footer={
          <CreatorSaveButtonStyled onClick={onSaveButtonClick} disabled={inputValue === '' || intervalValue === ''}>
            {'Zapisz proces'}
          </CreatorSaveButtonStyled>
        }
      />
    </Modal>
  );
};
