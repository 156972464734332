import { DynamicListItem } from '../../../components/DynamicListItem/DynamicListItem';
import { messages } from '../../ProcessComposition/processCompositionMessages';
import { Loader } from '../../../components/Loader/Loader';
import { ReportsPageListStyled } from './ReportsPageListStyled';
import { useDispatch } from 'react-redux';
import {
  activeUserReport,
  addPredefinedUserReport,
  deActiveUserReport,
} from '../../../store/reportsManager/reports.actions';
import {
  ActionChildStyled,
  MainChildStyled,
  StatusChildStyled,
} from '../../../components/DynamicListItem/DynamicListItemStyled';

export const ReportsPageList = ({ isLoading, list, isPredefinedList, title, description, handleEdit, navigateTo }) => {
  const dispatch = useDispatch();
  const actionChildTitle = (item) =>
    !isPredefinedList ? (item.isActive ? messages.button.switchOn : messages.button.switchOff) : messages.button.add;

  const handleOnClickAction = (processId, isActive) => () => {
    if (isPredefinedList) {
      return dispatch(addPredefinedUserReport(processId, dispatch));
    }

    return isActive
      ? dispatch(deActiveUserReport(processId, dispatch))
      : dispatch(activeUserReport(processId, dispatch));
  };

  return (
    <ReportsPageListStyled>
      <div className="header">
        <p className="headerTitle">{title}</p>
        <p className="headerDescription">{description}</p>
      </div>
      <ul>
        <DynamicListItem
          processChild={messages.process.process}
          statusChild={!isPredefinedList ? messages.process.status : ''}
          actionChild={messages.process.actions}
          isFirst={true}
        />
        {isLoading ? (
          <div className="wrapperLoader">
            <Loader />
          </div>
        ) : (
          <>
            {list.map((item) => (
              <DynamicListItem
                key={item.id}
                title={messages.button.shows}
                isFirst={false}
                id={item.id}
                isHoover={!isPredefinedList}
                navigateTo={navigateTo}
                handleEdit={handleEdit}
                processChild={<MainChildStyled>{item.processName}</MainChildStyled>}
                statusChild={
                  !isPredefinedList && (
                    <StatusChildStyled isActive={item.isActive}>
                      {item.isActive ? messages.button.switchedOn : messages.button.switchedOff}
                    </StatusChildStyled>
                  )
                }
                actionChild={
                  <ActionChildStyled onClick={handleOnClickAction(item.id, item.isActive)}>
                    {actionChildTitle(item)}
                  </ActionChildStyled>
                }
              />
            ))}
          </>
        )}
      </ul>
    </ReportsPageListStyled>
  );
};
