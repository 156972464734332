import { setLocale } from 'yup';
import { pl } from 'yup-locales';
import * as yup from 'yup';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { remindUserPassword } from '../../../store/auth/auth.actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

setLocale(pl);

export const useRemindPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    username: '',
  };

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(remindUserPassword(data.username));
    navigate('/set-new-password');
    enqueueSnackbar('Na podany adres e-mail wysłaliśmy dodatkowe informacje.', { variant: 'success' });
  };

  const { isValid, isSubmitting, isSubmitSuccessful, isSubmitted, isValidating } = useFormState({ control });

  return {
    control,
    formState: { isValid, isSubmitting, isSubmitSuccessful, isSubmitted, isValidating },
    handleSubmit,
    onSubmit,
  };
};

const schema = yup
  .object({
    username: yup.string().required().label('pole jest wymagane'),
  })
  .required();
