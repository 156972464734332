import React from 'react';
import { IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppBarStyled } from './styles';
import { useNavigate } from 'react-router-dom';
import { authDetails } from '../../store/auth/auth.selector';
import { logOut } from '../../store/auth/auth.actions';

const NavBar = ({ isSideBarOpen, handleDrawerOpen }) => {
  const { userName } = useSelector(authDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMyAyAccount = () => {
    navigate('/my-account');
    handleClose();
  };

  const handleSelectLogout = () => {
    navigate('/my-account');
    dispatch(logOut());
    handleClose();
  };

  const initials = userName?.[0];

  return (
    <AppBarStyled position="static">
      {!isSideBarOpen && (
        <IconButton onClick={handleDrawerOpen} className="menu-open-button">
          <img src="/static/menu-icon-close.svg" alt="menu open button" />
        </IconButton>
      )}
      <Toolbar>
        <IconButton onClick={handleMenu}>
          <span className="user-account-icon">
            {initials}
            <span className="user-account-small-circle">
              <span></span>
            </span>
          </span>
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleSelectMyAyAccount}>Moje konto</MenuItem>
          <MenuItem onClick={handleSelectLogout}>Wyloguj</MenuItem>
        </Menu>
      </Toolbar>
    </AppBarStyled>
  );
};

export default NavBar;
