import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { BarGraphStyled } from './BarGraphStyled';
import { Loader } from '../Loader/Loader';

ChartJS.register(...registerables);

export const BarChart = ({
  data,
  groupMode,
  height,
  analytics,
  highestValue,
  isLoading,
  pluginsOptions,
  axisType,
  showAxisScale,
}) => {
  const legend = { legend: { display: false } };

  const chartOptions = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },

    scales: {
      y: {
        display: !!showAxisScale ?? false,
        stacked: groupMode === 'stacked',
        min: 0,
        max: highestValue ?? 150,
        type: axisType ?? 'linear',
      },
      x: {
        stacked: groupMode === 'stacked',
        grid: {
          display: false,
        },
        min: 0,
        max: 100,
      },
    },
    plugins: {
      ...legend,
      ...pluginsOptions,
    },
    datasets: {
      bar: {
        barThickness: 10,
      },
    },
  };
  return (
    <BarGraphStyled height={height ?? 130}>
      {isLoading || !data ? (
        <Loader />
      ) : (
        <div className="chart">
          <Chart type="bar" options={chartOptions} data={data} />
          <p className="graph-footer">{analytics}</p>
        </div>
      )}
    </BarGraphStyled>
  );
};
