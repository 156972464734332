export const onboardingWarehouse = [
  {
    name: 'Wgraj plan',
    step: 'Krok 1',
    header: 'Wgraj plan swojego magazynu',
    description:
      'W celu uruchomienia systemu Lumilook musimy zweryfikować czy przestrzeń spełnia kryteria wymagane przez nasze algorytmy. Aby to zrobić będziesz musiał za pomocą naszego kreatora stworzyć wirtualny plan 3D swojego magazynu. Wgranie planu magazynu ułatwi Ci to zadanie.',
    id: 'create',
    stepId: 1,
    active: true,
  },
  {
    name: 'Przytnij plan',
    step: 'Krok 2',
    header: 'Zdefiniuj obszar objęty monitoringiem',
    description: 'Oznacz na planie obszar na którym ma zostać uruchomiony system Lumilook.',
    id: 'crop',
    stepId: 2,
    active: false,
  },
  {
    name: 'Dostosuj plan',
    step: 'Krok 3',
    header: 'Zdefiniuj skale wgranego planu',
    description:
      "Narysuj linię wzdłuż wybranego elementu i wprowadź jej długość w 'cm' tak aby odpowiadała długośći elementu na planie.",
    id: 'customize',
    stepId: 3,
    active: false,
  },
  {
    name: 'Edytuj plan',
    step: 'Krok 4',
    header: 'Edytuj plan wgranego magazynu',
    description: 'Stwórz plan swojego magazynu',
    id: 'edit',
    stepId: 4,
    active: false,
  },
  {
    name: 'Podsumowanie',
    step: 'Plan Twojego magazynu jest gotowy!',
    description:
      'Stworzony przez Ciebie magazyn został dodany do naszego systemu. \n Aktualnie trwa proces przetwarzania danych, gdy tylko będą gotowe do wyświetlenia otrzymasz od nas wiadomoscść e-mail.',
    header: 'Gratulacje',
    id: 'summary',
    stepId: 5,
    active: false,
  },
];
