import React from 'react';
import { saveUserReport } from '../../../store/reportsManager/reports.actions';
import { useDispatch } from 'react-redux';

export const useReportCreator = () => {
  const [reportDetails, setReportDetailsState] = React.useState({
    reportName: '',
    emails: '',
    regularity: '',
    eventsInPartTime: false,
    eventsPriority: false,
    eventsDangerous: false,
    newEventsDefined: false,
  });

  const dispatch = useDispatch();

  const handleInputChange = (e, name) => {
    const {
      target: { value },
    } = e;
    setReportDetailsState((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckbox = (e) =>
    setReportDetailsState((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));

  const handleSelect = (value) =>
    setReportDetailsState({
      ...reportDetails,
      regularity: value,
    });

  const handleSubmitReportDetails = () => {
    if (reportDetails.reportName && reportDetails.emails) {
      dispatch(saveUserReport(reportDetails, dispatch));
    }
  };

  return {
    reportDetails,
    handleSelect,
    handleCheckbox,
    handleInputChange,
    handleSubmitReportDetails,
  };
};
