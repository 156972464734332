import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { restParamsObject, setOffset } from '../../utils/utils';
import { useDateEvents } from './useDateEvents';
import { eventsItemLimitSelectorOptions } from './WarehouseDetailsConfig';
import { DateHelper } from '../../utils/dateHelper';

export const useWarehouseDetails = () => {
  const [urlParams] = useState('');
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { dateValue, setDateValue, timestampParams } = useDateEvents();

  const setParams = useCallback(() => {
    const params = {
      ...getDateParams(),
    };

    if (pathname.includes('events')) {
      params.limit = getProductsParam();
      params.page = parseInt(searchParams.get('page')) || 1;
      params.offset = setOffset(searchParams, undefined, undefined);
    }

    setSearchParams({
      ...restParamsObject(searchParams),
      ...params,
    });
  }, [pathname, searchParams, setSearchParams]);

  const onDateChange = (date) => {
    setDateValue({
      from: new DateHelper(date[0]).getStartOfDay().getTime(),
      to: new DateHelper(date[1]).getEndOfDay().getTime(),
    });
  };

  useEffect(() => {
    setSearchParams({
      ...restParamsObject(searchParams),
      ...timestampParams,
    });
  }, [timestampParams, setSearchParams, searchParams]);

  React.useEffect(() => {
    setParams();
  }, [pathname]);

  const getProductsParam = () => {
    let limit = parseInt(searchParams.get('limit'));
    if (!eventsItemLimitSelectorOptions.filter((option) => option.value === limit).length) {
      limit = eventsItemLimitSelectorOptions[0].value ?? 20;
    }
    return limit;
  };

  const getDateParams = () => {
    return {
      timestamp_from: searchParams.get('timestamp_from') ?? dateValue.from,
      timestamp_to: searchParams.get('timestamp_to') ?? dateValue.to,
    };
  };

  return {
    urlParams,
    onDateChange,
    dateValue,
    searchParams,
    timestampParams,
  };
};
