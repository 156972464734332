import { UserHeader } from '../UserHeader/UserHeader';
import React from 'react';
import { EditButtonStyled, UserDetailsSectionStyled, UserDetailsStyled } from './UserDetailsStyled';
import { Step } from '../../../components/Step/Step';
import { CheckboxComponent } from '../../../components/Checkbox';
import { UserDetailsEditor } from '../UserDetailsEditor/UserDetailsEditor';
import { Stepper } from '../../../components/Stepper/Stepper';

const checkboxList = [
  {
    isSelected: true,
    label: 'Administrator',
  },
  {
    isSelected: true,
    label: 'BHB',
  },
  {
    isSelected: true,
    label: 'Manager',
  },
];

export const UserDetails = () => {
  const handleEditDetails = (props) => () => props.onNextStep();

  return (
    <>
      <UserHeader />
      <UserDetailsStyled>
        <div className="border" />
        <Stepper initialStep={1}>
          <Step
            step={1}
            render={(props) => {
              return (
                <>
                  {userDetailsSection('Imię i nazwisko użytkownika', 'Jan Kowalski')}
                  {userDetailsSection('Adres e-mail użytkoniwka', 'jan.kowalski@storage.com.pl')}
                  <div>
                    <p className="lowerHeader">{'Poziom Dostępu'}</p>
                    <div className="checkbox">
                      {checkboxList.map((item) => {
                        return (
                          <React.Fragment key={item.label}>
                            <CheckboxComponent label={item.label} isSelected={item.isSelected} disabled={true} />
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="buttonWrapper">
                      <EditButtonStyled onClick={handleEditDetails(props)} variant="outlined">
                        {'Edytuj dane'}
                      </EditButtonStyled>
                    </div>
                  </div>
                </>
              );
            }}
          />
          <Step
            step={2}
            render={(props) => {
              return <UserDetailsEditor onPrevStep={props.onPrevStep} />;
            }}
          />
        </Stepper>
      </UserDetailsStyled>
    </>
  );
};

const userDetailsSection = (title, value) => {
  return (
    <UserDetailsSectionStyled>
      <p>{title}</p>
      <span>{value}</span>
    </UserDetailsSectionStyled>
  );
};
