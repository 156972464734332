import React, { useState } from 'react';
import { handleSaveWarehousePlan } from '../../../store/onboarding/onboarding.actions';
import { useDispatch, useSelector } from 'react-redux';
import { onBoardingReconstructionState, onboardingState } from '../../../store/onboarding/onboarding.selector';
import { authState } from '../../../store/auth/auth.selector';
import { convertFileToBase64 } from '../../../utils/utils';
import { useSnackbar } from 'notistack';
import { appConfigState } from '../../../store/appConfig/appConfig.selector';

export const useOnBoardingViewEditor = (onNextStep) => {
  const [isReconstructionSet, setReconstruction] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { tokens } = useSelector(authState);
  const { onBoardingWarehouseId } = useSelector(appConfigState);
  const { file, imageCropped, imageCroppedProperties, sceneDimensions, isDisabledSteps, stepSource } =
    useSelector(onboardingState);
  const {
    image_raw,
    image_cropped,
    image_cropped_properties,
    scene_dimensions,
    svg: reconstructionSvg,
    three_d_scene,
  } = useSelector(onBoardingReconstructionState);
  const { enqueueSnackbar } = useSnackbar();

  const setDataToPlanner = () => {
    const iframe = document.querySelector('iframe');
    const preparedValues = {
      imageCropped: stepSource === 'AccountEditPlan' ? image_cropped : imageCropped,
      sceneDimensions: stepSource === 'AccountEditPlan' ? scene_dimensions : sceneDimensions,
      imageCroppedProperties: stepSource === 'AccountEditPlan' ? image_cropped_properties : imageCroppedProperties,
      svg: stepSource === 'AccountEditPlan' ? reconstructionSvg : '',
      threeDScene: stepSource === 'AccountEditPlan' ? three_d_scene : '',
    };

    iframe.contentWindow.postMessage(
      {
        message: 'getOnboardingData',
        value: preparedValues,
      },
      '*'
    );
  };

  const getDataFromPlanner = async (event) => {
    const message = event.data.message;
    const value = event.data.value;

    if (message === 'openHeightPopup') {
      return;
    }

    // save planer details
    if (message === 'sendToApiData') {
      const base64File = file ? await convertFileToBase64(file[0]) : null;
      const preparedValues = {
        image_raw: stepSource === 'AccountEditPlan' ? image_raw : base64File,
        image_cropped: stepSource === 'AccountEditPlan' ? image_cropped : imageCropped,
        image_cropped_properties: stepSource === 'AccountEditPlan' ? image_cropped_properties : imageCroppedProperties,
        scene_dimensions: stepSource === 'AccountEditPlan' ? scene_dimensions : sceneDimensions,
        svg: value.svg,
        three_d_scene: value.threeDScene,
        is_disabled_steps: isDisabledSteps,
        accessToken: tokens.accessToken,
      };

      if (isReconstructionSet) {
        setReconstruction(false);
        setIsLoading(true);
        dispatch(handleSaveWarehousePlan({ warehouseId: onBoardingWarehouseId || 1, body: preparedValues })).then(
          (item) => {
            // onNextStep();
            if (item.payload.status === 204) {
              onNextStep();
            } else {
              enqueueSnackbar(`Error: ${item.payload?.message}`, { variant: 'error' });
            }
            setIsLoading(false);
          }
        );
      }
    }
  };

  const handleUploadPlan = () => {
    const iframe = document.querySelector('iframe');
    iframe.contentWindow.postMessage(
      {
        message: 'startAsyncSave',
      },
      '*'
    );
  };

  React.useEffect(() => {
    window.addEventListener('message', getDataFromPlanner, false);

    return () => {
      window.removeEventListener('message', getDataFromPlanner);
    };
  }, []);

  return {
    isDisabledSteps,
    setDataToPlanner,
    handleUploadPlan,
    isLoading,
  };
};
