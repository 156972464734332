import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../utils/utils';
import { eventDefinitionState } from '../../store/appConfig/appConfig.selector';
import { getBarChartData, getHighestValueForBarChartData } from '../../utils/chart.helpers';
import { eventsEventsLast7DaysGroupedByDayState } from '../../store/dashboard/dashboard.selector';
import { fetchEventsLast7DaysGroupedByDay } from '../../store/dashboard/dashboard.actions';

export const useTimeline = () => {
  const [chartData, setChartData] = useState(null);
  const dispatch = useDispatch();
  const { list } = useSelector(eventDefinitionState);
  const [highestValue, setHighestValue] = useState(0);
  const { data, isLoading, status } = useSelector(eventsEventsLast7DaysGroupedByDayState);

  const refreshTimelineData = () => {
    dispatch(fetchEventsLast7DaysGroupedByDay());
  };
  // useEffect(() => {
  //   dispatch(fetchEventsLast7DaysGroupedByDay());
  // }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(data) && status === 'fulfilled') {
      setChartData({
        labels: Object.keys(data),
        datasets: getBarChartData(data, list),
      });

      setHighestValue(getHighestValueForBarChartData(data));
    }
  }, [data, isLoading]);

  return {
    chartData,
    highestValue,
    isLoading,
    refreshTimelineData,
  };
};
