import API, { get, put, saveFileToS3, getFileFromS3 } from './api';

export const saveWarehouseReconstruction = async (warehouseId, body) => {
  const { image_cropped, image_raw, accessToken, svg } = body;
  delete body.image_cropped;
  delete body.image_raw;
  delete body.svg;

  const resDetails = put(`${API.apiUrl}/warehouse/${warehouseId}/reconstruction`, body, '');
  const resCropped = saveFileToS3(warehouseId, 'image-cropped-upload-request', image_cropped, accessToken);
  const resRaw = saveFileToS3(warehouseId, 'image-raw-upload-request', image_raw, accessToken);
  const resSvg = saveFileToS3(warehouseId, 'svg-upload-request', svg, accessToken);

  await resDetails;
  await resCropped;
  await resSvg;
  return await resRaw;
};

export const getWarehouseReconstructionAll = async (warehouseId, accessToken) => {
  const resDetails = await get(`${API.apiUrl}/warehouse/${warehouseId}/reconstruction`, { accessToken });
  const resCropped = await getFileFromS3(warehouseId, 'image-cropped-get-request', accessToken);
  const resSvg = await getFileFromS3(warehouseId, 'svg-get-request', accessToken);
  const resRaw = await getFileFromS3(warehouseId, 'image-raw-get-request', accessToken);
  return { ...resDetails, image_cropped: resCropped.data, image_raw: resRaw.data, svg: resSvg.data };
};

export const getWarehouseReconstructionWithSvg = async (warehouseId, accessToken) => {
  const resDetails = await get(`${API.apiUrl}/warehouse/${warehouseId}/reconstruction`, { accessToken });
  const resSvg = await getFileFromS3(warehouseId, 'svg-get-request', accessToken);
  return { ...resDetails, svg: resSvg.data };
};
