export const EVENT_TYPE_RAW = 'raw';
export const EVENT_TYPE_INSTANCE = 'type_instance';
export const EVENT_TYPE_TYPE = 'type';
export const EVENT_TYPE_TIMELINE = 'type/timeline';
export const EVENTS_TYPES = [EVENT_TYPE_RAW, EVENT_TYPE_INSTANCE, EVENT_TYPE_TYPE];
export const EVENT_GROUP_NAME_NO_HELMET = 'no_helmet';
export const EVENT_GROUP_NAME_PERSON_LYING_DOWN = 'person_lying';
export const EVENT_GROUP_FORKLIFT_HIT_PERSON = 'forklift_hit_person';
export const EVENT_GROUP_NAME_NO_VEST = 'no_vest';
export const EVENT_GROUP_NAME_SOCIAL_DISTANCING = 'social_distancing';
export const EVENT_GROUP_NAME_FORKLIFT_NEAR_PERSON = 'forklift_near_person';
export const EVENT_GROUP_NAMES = [
  EVENT_GROUP_NAME_NO_HELMET,
  EVENT_GROUP_NAME_NO_VEST,
  EVENT_GROUP_NAME_SOCIAL_DISTANCING,
  EVENT_GROUP_NAME_FORKLIFT_NEAR_PERSON,
  EVENT_GROUP_NAME_PERSON_LYING_DOWN,
  EVENT_GROUP_FORKLIFT_HIT_PERSON,
];
export const EVENTS_SORT_BY_COLUMN = 'count';
export const TIMESTAMP = 'timestamp';
export const TIMESTAMP_FROM = 'timestamp_from';
export const TIMESTAMP_TO = 'timestamp_to';
export const SORT_ORDER = 'desc';
export const SORT_ORDER_ASC = 'asc';
export const LABEL_DATE_FORMAT = 'YYYY-MM-DD';
export const LABEL_TIME_FORMAT = 'hh:mm:ss';
export const LABEL_DATETIME_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const PRIORITY_HIGH = 'high';
export const PRIORITY_MEDIUM = 'medium';
export const PRIORITY_LOW = 'low';

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\,\.\<\>\/\?\[\]\{\}\-\_\+\=\\\|])(?=.{8,})/;
