import API, { get } from './api';

export const getEventsByType = async ({ warehouseId, eventType, data, params }) => {
  let url = `${API.apiUrl}/warehouse/${warehouseId}/event/${eventType}`;

  return get(url, data, params);
};

export const getRawEventsByType = async ({ warehouseId, data, eventId }) => {
  let url = `${API.apiUrl}/warehouse/${warehouseId}/event/raw/${eventId}`;

  return get(url, data);
};

export const getEventsHistogram = async ({ warehouseId, data, params }) => {
  let url = `${API.apiUrl}/warehouse/${warehouseId}/event/histogram`;
  return get(url, data, params);
};
