import styled from '@emotion/styled';

export const ActionButtonsTypeStyled = styled.button`
  width: 90%;
  height: 50px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  background: rgba(255, 62, 29, 0.5);
  border: none;
  cursor: pointer;
  margin: 10px 0;

  :disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

export const EventButtonsTypeStyled = styled.button`
  width: 90%;
  height: 50px;
  color: #435971;
  border-radius: 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  background: rgba(113, 221, 55, 0.5);
  border: none;
  cursor: pointer;
  margin: 10px 0;

  :disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;
