import { createAsyncThunk } from '@reduxjs/toolkit';
import { getACSABenchmark, getACSAData } from '../../../api/acsa';

export const fetchDangerLevel = createAsyncThunk(
  'dangerLevel/fetchDangerLevel',
  async (body, { getState }) =>
    await getACSAData({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      data: { ...getState().auth.tokens },
      params: { ...body.params },
    })
);

export const fetchACSABenchmark = createAsyncThunk(
  'appConfig/fetchACSABenchmark',
  async (_, { getState }) =>
    await getACSABenchmark({
      warehouseId: getState().appConfig.appConfig.activeWarehouseId,
      data: { ...getState().auth.tokens },
    })
);
