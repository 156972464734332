import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { StatsticsStyled } from './StatsticsStyled';
import { PieChart } from '../../../components/PieChart/PieChart';
import { useEventsTypes } from './useEventsTypes';
import { useTimeline } from './useTimeline';
import ContentBox from '../../../components/ContentBox/ContentBox';
import { BarChart } from '../../../components/BarGraph/BarGraph';
import { useDangerLevel } from './useDangerLevel';
import { dangerLevelChartOptions } from '../../../utils/chart.helpers';

export const Statistics = () => {
  const { chartData: eventsTypesData, isLoading: isEventsTypesLoading } = useEventsTypes();
  const { chartData: timelineData, isLoading: isTimelineLoading, highestValue } = useTimeline();
  const { chartData: dangerLevelData, isLoading: isDangerLevelLoading } = useDangerLevel();

  return (
    <StatsticsStyled>
      <Grid2 container>
        <Grid2 xs={12}>
          <ContentBox
            title={'Statystyki zdarzeń'}
            description={'Zobacz dane dotyczące statystyk zdarzeń w określonym czasie.'}
          >
            <BarChart
              data={timelineData}
              height={300}
              isLoading={isTimelineLoading}
              highestValue={highestValue}
              axisType={'logarithmic'}
              showAxisScale
            />
          </ContentBox>
        </Grid2>
        <Grid2 xs={12} container spacing={2}>
          <Grid2 lg={6} xs={12}>
            <ContentBox title={'Typy zdarzeń'}>
              <PieChart data={eventsTypesData} height={365} isLoading={isEventsTypesLoading} />
            </ContentBox>
          </Grid2>
          <Grid2 lg={6} xs={12}>
            <ContentBox title={'Poziom zagrożenia'}>
              <BarChart
                data={dangerLevelData}
                groupMode={'stacked'}
                height={365}
                isLoading={isDangerLevelLoading}
                highestValue={1}
                pluginsOptions={dangerLevelChartOptions}
              />
            </ContentBox>
          </Grid2>
        </Grid2>
      </Grid2>
    </StatsticsStyled>
  );
};
