import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProcessList} from '../../api/process';

export const fetchProcessList = createAsyncThunk('processList/fetchProcessList', async (warehouseId, { getState }) => {
  const response = await getProcessList({
    warehouseId: getState().appConfig.appConfig.activeWarehouseId,
    data: getState().auth.tokens,
  });
  return response;
});
