
export const processHistoryList = [
  {
    id: 1,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 2,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 3,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 4,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 5,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 6,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 7,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 8,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 9,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 10,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 11,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 12,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 13,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 14,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
  {
    id: 15,
    processName: 'Email o zdarzeniu',
    date: '22.08.2022 22:13:12',
  },
];
