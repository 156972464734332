import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNewWarehouse, editWarehouse, getWarehouseById } from "../../api/warehouse";

export const fetchWarehouseById = createAsyncThunk('fetchWarehouseById', async (warehouseId, { getState }) => {
  const response = await getWarehouseById({ warehouseId: warehouseId, data: getState().auth.tokens });
  return response;
});

export const addWarehouse = createAsyncThunk('addWarehouse', async (body) => await addNewWarehouse(body));
export const editUserWarehouse = createAsyncThunk('editUserWarehouse', async (body) => await editWarehouse(body));
