import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAppConfigInitialData } from '../../store/appConfig/appConfig.action';
import { appConfigState, warehouses } from '../../store/appConfig/appConfig.selector';

export const HomePage = () => {
  const dispatch = useDispatch();
  const { list, isLoading } = useSelector(warehouses);
  const { isAnyWarehouseActive } = useSelector(appConfigState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!list.length && !isLoading) {
      dispatch(fetchAppConfigInitialData());
    }
  }, []);

  useEffect(() => {
    if (list.length && !isLoading) {
      if (isAnyWarehouseActive) {
        const activeWarehouse = list.find(({ active }) => active);
        if (activeWarehouse) return navigate(`/dashboard/${activeWarehouse.id}`);
      } else {
        navigate('/my-account');
      }
    }
  }, [list, isLoading]);
};
