import { useDispatch, useSelector } from 'react-redux';
import { recommendationsState } from '../../store/recommendations/recommendations.selector';
import { useEffect, useState } from 'react';
import { fetchRecommendations } from '../../store/recommendations/recommendations.actions';
import { setActiveRecommendation } from '../../store/recommendations/recommendations.reducer';

export const useRecommendations = () => {
  const dispatch = useDispatch();
  const { data, isLoading, status } = useSelector(recommendationsState);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (status === 'initial') {
      dispatch(fetchRecommendations());
    }
  }, [dispatch, fetchRecommendations, status]);
  const onRowClick = (id) => {
    dispatch(setActiveRecommendation(id));
    document.querySelector('#map-container').scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    if (!isLoading && !!data?.length) {
      setRows(
        data.map(({ timestamp_from, timestamp_to, recommendation, priority, id, x, y, z }) => {
          const reco = {
            body: `${recommendation}`,
            id: id,
            isActive: false,
            marker: x && y ? { position: [x * 100, y * 100] } : null,
            onRowClick: onRowClick,
            timestamp: timestamp_from,
            priority,
          };
          const marker = {
            recommendation: reco,
            recommendationType: reco,
            priority,
            timestamp: timestamp_from,
          };
          return marker;
        })
      );
    }
  }, [isLoading, data]);

  return {
    rows,
    isLoading,
  };
};
