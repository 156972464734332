import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { ModalStyled } from './ModalStyled';

export const Modal = ({
  isModalOpen,
  closeModal,
  closeByClickedOutside,
  children,
  maxWidth,
  fullWidth,
  backgroundColor,
}) => {
  return (
    <ModalStyled>
      <Dialog
        style={{
          backgroundColor: backgroundColor ? backgroundColor : '',
        }}
        scroll={'body'}
        open={isModalOpen}
        onClose={closeByClickedOutside ? closeModal : () => {}}
        aria-labelledby="alert-dialog-title"
        hideBackdrop={true}
        aria-describedby="alert-dialog-description"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        {children}
      </Dialog>
    </ModalStyled>
  );
};
