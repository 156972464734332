import { useState, useEffect } from 'react';

export const useInfoWidget = (data) => {
  const [currentCount, setCurrentCount] = useState(0);
  const [previousCount, setPreviousCount] = useState(0);
  const [change, setChange] = useState(0);

  useEffect(() => {
    let currentData = null;
    if (data?.current?.length) {
      currentData = data.current.reduce((accumulator, item) => accumulator + item.count, 0);
      setCurrentCount(currentData);
    }
    if (data?.previous?.length && currentData) {
      const prevData = data.previous.reduce((accumulator, item) => accumulator + item.count, 0);

      let change = 100;
      if (prevData) change = Number((currentData / prevData) * 100 - 100).toFixed(2) ?? 100;

      setChange(change);
      setPreviousCount(prevData);
    }
  }, [data]);

  return {
    change,
    count: currentCount,
  };
};
