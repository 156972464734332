import { css } from '@emotion/react';

const bodyBackgroundColor = '#F2F3F4';
const textColorDefault = '#435971';
const textColorBlue = '#0D6DC5';

export const GlobalStyles = css`
  html,
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  ol,
  ul,
  li {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }

  body {
    background-color: ${bodyBackgroundColor};
    font-family: 'Public Sans', sans-serif;
    color: ${textColorDefault};
  }

  #root {
    font-size: 0.938rem;
  }

  a {
    color: ${textColorBlue};
    cursor: pointer;
  }

  iframe {
    border: none;
    display: block;
  }

  .marker-cluster {
    background-color: rgba(13, 109, 197, 0.5);
    div {
      background-color: rgba(67, 89, 113, 0.5);
      color: white;
    }
  }
  .bg--white {
    background: #fff;
  }
  .svg-map {
    filter: grayscale(0.3);
  }
  .leaflet-control-layers-list {
    text-align: left;
  }
  .marker-popup-container {
    max-height: 200px;
    padding: 0;
    overflow-y: auto;
    &__date {
      font-size: 10px;
      margin-bottom: 0 !important;
    }
    &__priority {
      font-size: 10px;
      margin-top: 0 !important;
    }
  }
`;
