import styled from '@emotion/styled';

export const DashboardPageStyled = styled.div`
  .graphs {
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    margin: 0 0 16px;
  }
`;
