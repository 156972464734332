import { Button, InputAdornment } from '@mui/material';
import React, { memo, useState } from 'react';
import { Modal } from '../../../components/Modal/Modal';
import { ModalContent } from '../../../components/Modal/ModalContent';
import { InputComponent } from '../../../components/InputComponent';

const ModalWallHeight = ({ isModalOpen, handleClose, handleSubmit }) => {
  const [height, setHeight] = useState(0);

  return (
    <Modal
      closeByClickedOutside={true}
      isModalOpen={isModalOpen}
      closeModal={handleClose}
      maxWidth="lg"
      backgroundColor={'rgba(0, 0, 0, 0.75)'}
    >
      <ModalContent
        headerTitle={'Wyznacz wysokość ściań'}
        handleCloseButton={handleClose}
        body={
          <InputComponent
            label="Wysokość ścian"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="start">cm</InputAdornment>,
            }}
            handleChange={(e) => setHeight(e.target.value)}
          />
        }
        footer={<Button onClick={() => handleSubmit(height)}>Zatwierdź</Button>}
      />
    </Modal>
  );
};
export default memo(ModalWallHeight);
