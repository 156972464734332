import styled from '@emotion/styled';

export const ProcessCompositionListStyled = styled.div`
  ul {
    list-style: none;
  }

  .wrapper {
    margin-top: 16px;
    background: white;
    padding: 20px;
    border-radius: 8px;

    &Loader {
      min-height: 375px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &Header {
      font-weight: 700;
      font-size: 18px;
      line-height: 19px;
      color: #697a8d;
      margin-bottom: 21px;
    }

    &Description {
      color: #566a7f;
      line-height: 25px;
      font-weight: 400;
    }

    &First {
      margin-right: 28px;

      @media (max-width: 767px) {
        margin-right: 0;
      }
    }
  }
`;
