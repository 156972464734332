export const dashboardPageTexts = {
  currentDayWidget: {
    icon: '/static/transactions-icon.png',
    title: 'Aktualny dzień',
  },
  lastDayWidget: {
    title: 'Zdarzenia',
    subTitle: 'Ostatnia doba',
  },
  last7DaysWidget: {
    title: 'Zdarzenia',
    subTitle: 'Ostatnie 7 dni',
  },
  last30DaysWidget: {
    title: 'Zdarzenia',
    subTitle: 'Ostatnie 30 dni',
  },
  barChart: {
    title: 'Zdarzenia w ostatnim tygodniu',
    subTitle: 'Wykres zdarzeń z ostatnich 7 dni z podziałem na typ zdarzenia',
    caption: 'W tym tygodniu odnotowaliśmy o 10% mniej zdarzeń',
  },
  eventsBox: {
    title: 'Zdarzenia w magazynach',
    description: 'Zobacz zdarzenia, które miały miejsce w Twoich magazynach w określonym przedziale czasowym.',
    select: {
      currentDay: 'Aktualny dzień',
      lastDay: 'Ostatnia doba',
      last7Days: 'Ostatnie 7 dni',
      last30Days: 'Ostatnie 30 dni',
    },
    buttonAllEvents: 'Zobacz wszystkie zdarzenia',
  },
};

/*

{
  "widgets": {
  "newEvents": {
    "icon": "/static/transactions-icon.png",
      "title": "Nowe zdarzenia",
      "count": "24",
      "change": "-13,24%"
  },
  "eventsLastDay": {
    "title": "Zdarzenia",
      "subTitle": "Ostatnia doba",
      "count": "10",
      "change": "-13,24%"
  },
  "eventsLastSevenDays": {
    "title": "Zdarzenia",
      "subTitle": "Ostatnie 7 dni",
      "count": "24",
      "change": "13,24%"
  },
  "eventsLastThirtyDays": {
    "title": "Zdarzenia",
      "subTitle": "Ostatnie 30 dni",
      "count": "48",
      "change": "-13,24%"
  }
},
  "reports": {
  "count": "0"
},
  "requests": {
  "count": "10"
},
  "lastWeekStats": [
  {
    "day": "26.09",
    "high": 68,
    "mid": 21,
    "low": 32
  },
  {
    "day": "27.09",
    "high": 39,
    "mid": 74,
    "low": 48
  },
  {
    "day": "28.09",
    "high": 76,
    "mid": 4,
    "low": 24
  },
  {
    "day": "29.09",
    "high": 75,
    "mid": 62,
    "low": 71
  },
  {
    "day": "30.09",
    "high": 4,
    "mid": 77,
    "low": 69
  },
  {
    "day": "01.10",
    "high": 58,
    "mid": 76,
    "low": 28
  },
  {
    "day": "02.10",
    "high": 30,
    "mid": 90,
    "low": 87
  }
],
  "byEventType": [
  {
    "id": "typ1",
    "label": "typ1",
    "value": 307,
    "color": "hsl(320, 70%, 50%)"
  },
  {
    "id": "typ2",
    "label": "typ2",
    "value": 406,
    "color": "hsl(146, 70%, 50%)"
  },
  {
    "id": "typ3",
    "label": "typ3",
    "value": 582,
    "color": "hsl(352, 70%, 50%)"
  },
  {
    "id": "typ4",
    "label": "typ4",
    "value": 51,
    "color": "hsl(166, 70%, 50%)"
  }
]
}*/
