import React from 'react';

import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useSelector } from 'react-redux';
import { warehouses, appConfigState } from '../../store/appConfig/appConfig.selector';

export const DashboardCards = () => {
  const navigate = useNavigate();
  const { list } = useSelector(warehouses);
  const { activeWarehouseId } = useSelector(appConfigState);
  const handleNavigate = (id) => () => {
    navigate(`/dashboard/${id}`);
  };

  const handleNavigateToReports = () => {
    navigate(`/reports-list/${activeWarehouseId}`);
  };

  return (
    <>
      <DashboardCardsStyled>
        <Grid2 container>
          <Grid2 xs={12}>
            <DashboardCard title="Twoje magazyny" text="Lista oraz status Twoich magazynów" className="dashboard-card">
              {list.map((warehouse, key) => {
                const id = warehouse.id;
                const bgColor = id === activeWarehouseId && '#0D6DC5';
                const color = id === activeWarehouseId && '#fff';
                const border = id !== activeWarehouseId && '1px solid #0D6DC5';
                return (
                  <div
                    key={warehouse.id}
                    className="dashboard-card-store"
                    style={{
                      backgroundColor: bgColor,
                      color,
                      border,
                    }}
                  >
                    <span>
                      <ActiveIndicatorStyled isActive={warehouse.active} />
                      {warehouse.name}
                    </span>
                    {warehouse.active && (
                      <Button variant="outlined" onClick={handleNavigate(warehouse.id)}>
                        IDZ
                      </Button>
                    )}
                  </div>
                );
              })}
            </DashboardCard>
          </Grid2>

          {/*<Grid2 xs={12}>*/}
          {/*  <DashboardCard title="Nowe zadania" text="" img="/static/File_dock_light.svg">*/}
          {/*    <div className="dahsboard-card-new-tasks">*/}
          {/*      <p>Ilość nowych zadań:</p>*/}
          {/*      <p>{requests?.count || 0}</p>*/}
          {/*      <CardButton variant="contained">Zobacz zadania</CardButton>*/}
          {/*    </div>*/}
          {/*  </DashboardCard>*/}
          {/*</Grid2>*/}

          <Grid2 xs={12}>
            <DashboardCard title="Raporty" text="Eksploruj wszystkie dostępne" img="/static/Chart_pin.svg">
              <div className="dahsboard-card-reports">
                <Button variant="contained" sx={{ marginTop: '2rem' }} onClick={handleNavigateToReports}>
                  Idź do raportów
                </Button>
              </div>
            </DashboardCard>
          </Grid2>
        </Grid2>
      </DashboardCardsStyled>
    </>
  );
};

const DashboardCardsStyled = styled.div`
  .dashboard-card-store {
    margin-top: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    padding: 0 15px;
    height: 56px;
    flex-wrap: nowrap;

    span {
      overflow: hidden;
      flex-shrink: 2;
      text-overflow: clip;
      margin-right: 0.5rem;
      align-items: center;
      display: flex;
    }

    button {
      padding: 3px 10px;
      font-size: 0.85rem;
      font-weight: 700;
      line-height: 20px;
      color: #566a7f;
      background-color: #fff;
    }
  }

  .dahsboard-card-new-tasks {
    p:first-of-type {
      line-height: 23px;
      color: #697a8d;
    }

    p:last-of-type {
      margin-top: 5px;
      font-weight: 700;
      line-height: 36px;
      font-size: 2rem;
      color: #0d6dc5;
    }
  }

  .dahsboard-card-reports {
  }

  .margin {
    margin-top: 20px;
  }
`;
const ActiveIndicatorStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})`
  height: 13px;
  width: 13px;
  border-radius: 50%;
  margin-right: 0.35rem;
  flex-shrink: 0;
  background-color: ${({ isActive }) => (isActive ? 'rgba(113, 221, 55, 1)' : 'rgba(0, 0, 0, 0.2)')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashboardCard = ({ title, text, img, children }) => {
  return (
    <DashboardCardStyled>
      <p className="card-title">{title}</p>
      <p className="card-text">{text}</p>
      {img && <img src={img} alt={title} />}
      <div>{children}</div>
    </DashboardCardStyled>
  );
};

const DashboardCardStyled = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  position: relative;

  .card-title {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 25.2px;
    color: #566a7f;
  }

  .card-text {
    margin-top: 20px;
    color: #697a8d;
    line-height: 23px;
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const CardButton = styled(Button)`
  margin-top: 20px;
  font-size: 0.85rem;
  letter-spacing: 0.43px;
  background-color: #0d6dc5;
  color: #fff;
  padding: 5px 10px;
`;
