import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, restParamsObject } from '../../../utils/utils';
import { useSearchParams } from 'react-router-dom';
import { eventDefinitionState } from '../../../store/appConfig/appConfig.selector';
import { getBarChartData, getHighestValueForBarChartData } from '../../../utils/chart.helpers';
import { timelineState } from '../../../store/warehouseDetails/timeline/timeline.selector';
import { fetchTimeline } from '../../../store/warehouseDetails/timeline/timeline.action';

export const useTimeline = () => {
  const { data, isLoading, status } = useSelector(timelineState);
  const [chartData, setChartData] = useState(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { list } = useSelector(eventDefinitionState);
  const [highestValue, setHighestValue] = useState(0);

  useEffect(() => {
    dispatch(fetchTimeline({ params: { ...restParamsObject(searchParams, ['timestamp_from', 'timestamp_to']) } }));
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (!isEmpty(data) && status === 'fulfilled') {
      setChartData({
        labels: Object.keys(data),
        datasets: getBarChartData(data, list),
      });

      setHighestValue(getHighestValueForBarChartData(data));
    }
  }, [data, isLoading]);

  return {
    chartData,
    highestValue,
    isLoading,
  };
};
