import { Paper, Typography, Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { memo } from 'react';

const ContentBox = ({ children, title, subtitle, description }) => {
  return (
    <Paper elevation={0}>
      <Box p={3}>
        <Grid2 container>
          <Grid2>
            {title && <Typography variant="h4">{title}</Typography>}
            {subtitle && (
              <Typography variant={'lead'} component={'div'}>
                {subtitle}
              </Typography>
            )}
            {description && (
              <Typography variant={'body2'} fontWeight={300} mt={2} component={'div'}>
                {description}
              </Typography>
            )}
          </Grid2>
        </Grid2>
        <Box pt={4}>{children}</Box>
      </Box>
    </Paper>
  );
};
export default memo(ContentBox);
