import styled from '@emotion/styled';

export const FakeModalStyled = styled.div`
  margin: 0;
  width: auto;
  position: relative;
  background-color: #fff;
  color: #566a7f;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid rgba(black, 0.1);
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.1);
`;
