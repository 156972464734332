import API, { get, post } from './api';

export const getReportsList = () => get(`${API.baseApiUrl}/reportsList`);

export const getPredefinedReportsList = () => get(`${API.baseApiUrl}/predefinedReportsList`);

export const activateReport = (reportId) => post(`${API.baseApiUrl}/activateReport`, reportId);

export const deActivateReport = (reportId) => post(`${API.baseApiUrl}/deActivateReport`, reportId);

export const addPredefinedReport = (predefinedReportId) =>
  post(`${API.baseApiUrl}/addPredefinedReport`, predefinedReportId);

export const saveReport = (body) => post(`${API.baseApiUrl}/saveReport`, body);
