import { useEffect } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css';
import RecommendationIcon from '../CustomIcon/RecommendationIcon';
import { LABEL_DATETIME_FORMAT } from '../../../constants';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { recommendationsState } from '../../../store/recommendations/recommendations.selector';
import { setActiveRecommendation } from '../../../store/recommendations/recommendations.reducer';

export const RecommendationMarker = ({ position, data }) => {
  const map = useMap();
  const { id, timestamp, priority, body } = data;
  const { activeRecommendation } = useSelector(recommendationsState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id === activeRecommendation) {
      map.setView([position[0], position[1]], 1.25);
      dispatch(setActiveRecommendation(null));
    }
  }, [id, activeRecommendation, map]);

  const onClickHandler = (e) => {
    map.setView([position[0], position[1]], 2);
  };

  return (
    <Marker
      position={position}
      icon={RecommendationIcon(data.priority)}
      eventHandlers={{
        click: (e) => {
          onClickHandler(e);
        },
      }}
    >
      {data && (
        <Popup>
          <div className={'marker-popup-container'}>{body}</div>
          <p className={'marker-popup-container__date'}>DATA: {dayjs.unix(timestamp).format(LABEL_DATETIME_FORMAT)}</p>
          <p className={'marker-popup-container__priority'}>PRIORYTET: {priority}</p>
        </Popup>
      )}
    </Marker>
  );
};
