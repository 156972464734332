import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reconstructionState } from '../../store/reconcstruction/reconstruction.selector';
import { fetchWarehouseReconstructionWithSvg } from '../../store/reconcstruction/reconstruction.action';

export const useReconstruction = () => {
  const [svg, setSvg] = useState(null);
  const [sceneDimensions, setSceneDimensions] = useState(null);
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  const {
    data: reconstructionData,
    isLoading: isReconstructionLoading,
    status: reconstructionStatus,
  } = useSelector(reconstructionState);

  useEffect(() => {
    dispatch(fetchWarehouseReconstructionWithSvg());
    setIsInitialized(true);
  }, [dispatch]);

  useEffect(() => {
    if (isReconstructionLoading === false && reconstructionStatus === 'fulfilled' && isInitialized) {
      setSvg(reconstructionData.svg);
      setSceneDimensions(JSON.parse(reconstructionData.scene_dimensions));
    }
  }, [isReconstructionLoading, reconstructionData]);

  return {
    svg,
    sceneDimensions,
  };
};
