import React from 'react';
import { Box, Typography } from '@mui/material';
import { EventsModalStyled } from './EventsModalStyled';
import dayjs from 'dayjs';
import { Modal } from '../../../components/Modal/Modal';
import { ModalContent } from '../../../components/Modal/ModalContent';
import { fetchRowEvent } from '../../../store/events/events.actions';
import { useDispatch, useSelector } from 'react-redux';
import { matchEventDefinitions } from '../../../utils/utils';
import { eventDefinitionState } from '../../../store/appConfig/appConfig.selector';
import { Loader } from '../../../components/Loader/Loader';

export const EventsModal = ({ idOfEventToShow, isModalOpen, rows, closeModal }) => {
  const [videoUrl, setVideoUrl] = React.useState('');
  const dispatch = useDispatch();
  const { list } = useSelector(eventDefinitionState);

  const currentEvent = rows.find((row) => row.id === idOfEventToShow);

  React.useEffect(() => {
    if (idOfEventToShow) {
      dispatch(fetchRowEvent({ eventId: idOfEventToShow })).then((res) => {
        setVideoUrl(res.payload.signed_url.url);
      });
    }
  }, [idOfEventToShow]);

  return (
    <Modal
      closeByClickedOutside={true}
      isModalOpen={isModalOpen}
      maxWidth="md"
      fullWidth={true}
      backgroundColor={'rgba(0, 0, 0, 0.75)'}
    >
      <ModalContent
        headerTitle={
          <>
            {matchEventDefinitions(currentEvent?.name || '', list)}
            <Box>
              <Typography variant={'body2'} component={'span'}>
                {currentEvent?.eventName}
              </Typography>
              <Typography variant={'caption'} component={'span'} fontWeight={'lighter'}>
                ({dayjs(currentEvent?.timestamp).format('DD.MM.YYYY')}{' '}
                {dayjs(currentEvent?.timestamp).format('hh:mm:ss')})
              </Typography>
            </Box>
          </>
        }
        handleCloseButton={closeModal}
        body={
          <EventsModalStyled maxWidth="sm">
            <div className="video-container">
              <Typography variant={'body'} component={'p'} mb={2}>
                Obejrzyj nagranie zdarzenia
              </Typography>
              {!!currentEvent?.media?.url || !!videoUrl ? (
                <video controls width="100%">
                  <source src={currentEvent?.media?.url || videoUrl} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              ) : (
                <Loader padding={'6'} />
              )}
            </div>
            <div className="caption">
              ID zdarzenia: <span>{currentEvent?.id}</span>
            </div>
          </EventsModalStyled>
        }
      />
    </Modal>
  );
};
