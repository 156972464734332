import styled from '@emotion/styled';

export const DiagramCaseDetailsStyled = styled.div`
  width: 280px;
  padding: 16px 20px;
  text-align: left;
  justify-content: center;

  .paragraph {
    margin-bottom: 20px;
  }

  p {
    color: #a1acb8;
    font-weight: 300;
    line-height: 18px;
    font-size: 12px;
    margin-bottom: 5px;
  }

  input {
    padding: 4px 5px;
    height: 30px;
  }

  .input {
    margin-bottom: 10px;

    &Description {
      color: #a1acb8;
      font-weight: 300;
      line-height: 18px;
      font-size: 12px;
      margin-bottom: 15px;
    }

    &Wrapper {
      min-width: 20%;
    }
  }

  .delete {
    text-align: center;
    color: red;
    font-size: 12px;
    margin-top: 10px;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  button {
    font-weight: 400;
    line-height: 14px;
    font-size: 14px;
    color: white;
    background: #0d6dc5;
    text-transform: none;
    width: 85%;
    margin: 5px auto;
    display: flex;

    :disabled {
      color: white;
      opacity: 0.3;
    }

    :hover {
      background: #0d6dc5;
    }
  }
`;
