import * as React from 'react';

import { ErrorPage } from '../../pages/ErrorPage/ErrorPage';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, errorInfo) {
    if (this.props.redirect) {
      window.location = '/internal-error';
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage errorCode={500} errorMessage={'Przepraszamy. Wystąpił problem techniczny. Już nad nim pracujemy.'} />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
