import ContentBox from '../../components/ContentBox/ContentBox';
import { TableComponent } from '../../components/TableComponent/TableComponent';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import MapChart from '../../components/MapChart/MapChart';
import { useReconstruction } from './useReconstruction';
import { useRecommendations } from './useRecommendations';
import React, { useMemo } from 'react';

const RecommendationsPage = () => {
  const { svg, sceneDimensions, isReconstructionLoading } = useReconstruction();
  const { rows, isLoading } = useRecommendations();
  const tableHeaders = ['OPIS', 'TYP', 'PRIORYTET', 'DATA'];

  const mapChartMemo = useMemo(
    () => (
      <MapChart
        svg={svg}
        sceneDimensions={sceneDimensions}
        recommendations={rows}
        isLoading={isReconstructionLoading && isLoading}
      />
    ),
    [isReconstructionLoading, rows, svg, sceneDimensions, isLoading]
  );

  return (
    <>
      <Grid2 container spacing={3}>
        <Grid2 xs={12}>
          <ContentBox title={'Mapa rekomendacji'}>{mapChartMemo}</ContentBox>
        </Grid2>
        <Grid2 xs={12}>
          <ContentBox
            title={'Lista rekomendacji'}
            description={'Zobacz rekomendacje z naszego systemu dotyczące Twojego magazynu.'}
          >
            <TableComponent headers={tableHeaders} rows={rows} isLoading={isLoading} rowPerPage={20} />
          </ContentBox>
        </Grid2>
      </Grid2>
    </>
  );
};

export default RecommendationsPage;
