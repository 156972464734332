import {
  recommendationIconHighPriority,
  recommendationIconMediumPriority,
  recommendationIconLowPriority,
  recommendationIconUndefinedPriority,
} from './IconsDefinitions';

const RecommendationIcon = (priority) => {
  let icon;
  switch (priority) {
    case 'low':
      icon = recommendationIconLowPriority;
      break;
    case 'medium':
      icon = recommendationIconMediumPriority;
      break;
    case 'high':
      icon = recommendationIconHighPriority;
      break;
    default:
      icon = recommendationIconUndefinedPriority;
  }

  return icon;
};
export default RecommendationIcon;
