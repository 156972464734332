import React, { useCallback, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { headers } from './contents';
import ContentBox from '../ContentBox/ContentBox';
import styled from '@emotion/styled';
import { TableComponent } from '../TableComponent/TableComponent';
import { dashboardPageTexts } from '../../data/dashbaordPageTexts';
import { useSelector } from 'react-redux';
import { Loader } from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { appConfigState } from '../../store/appConfig/appConfig.selector';

export const DashboardEvents = ({ className, selectBoxOptions, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const [eventRow, setEventsRows] = useState([]);
  const { isLoading, data } = useSelector(selectBoxOptions[selectedOption].selector);
  const navigate = useNavigate();
  const { activeWarehouseId } = useSelector(appConfigState);

  const handleNavigate = (url) => navigate(url);

  useEffect(() => {
    if (!isLoading && data) {
      setEventsRows(
        data?.current.map(({ name, count }) => ({
          name,
          count,
        }))
      );
    }
  }, [isLoading, data]);

  const handleChange = useCallback(
    (event) => {
      setSelectedOption(event.target.value);
    },
    [useSelector]
  );

  return (
    <ContentBox
      title={dashboardPageTexts.eventsBox.title}
      description={dashboardPageTexts.eventsBox.description}
      widget={
        <Select
          value={selectedOption}
          label=""
          onChange={handleChange}
          IconComponent={KeyboardArrowDownIcon}
          size={'small'}
          className={className}
        >
          {selectBoxOptions?.length &&
            selectBoxOptions.map((option, key) => {
              return (
                <MenuItem value={key} key={key}>
                  {option.name}
                </MenuItem>
              );
            })}
        </Select>
      }
    >
      {isLoading ? (
        <Loader padding={'3'} />
      ) : (
        <TableComponent rows={eventRow || []} headers={headers} rowPerPage={10} isModalButton={false} />
      )}
      <TableFooter>
        <Button
          variant="contained"
          onClick={() => handleNavigate(`/warehouse-details/${activeWarehouseId}/events/type`)}
        >
          {dashboardPageTexts.eventsBox.buttonAllEvents}
        </Button>
      </TableFooter>
    </ContentBox>
  );
};

const TableFooter = styled.div`
  margin-top: 20px;
  text-align: end;
`;
