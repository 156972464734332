import styled from '@emotion/styled';

export const CheckboxStyled = styled.div`
  .Mui-disabled {
    color: #0d6dc5 !important;

    svg {
      opacity: 0.5;
    }
  }

  //.MuiFormControlLabel-label {
  //color: #566A7F!important;
  //font-size: 15px;
  //font-weight: 400;
  //line-height: 25px;

  //&.Mui-disabled {
  //  //color: #566A7F;
  //  font-size: 15px;
  //  font-weight: 400;
  //  line-height: 25px;
  //  opacity: 0.5;
  //}
  //}

  //label {
  //  color: #00ff00;
  //  margin-right: 40px;
  //  font-size: 15px;
  //  font-weight: 400;
  //}
`;
