import { authConfig } from './authConfig';
import Amplify, { Auth } from 'aws-amplify';
import { AuthExceptionResolver } from './AuthExceptionResolver';
import { ChallengeRequiredException } from './exceptions/ChallengeRequiredException';

Amplify.configure(authConfig);

export const signIn = async ({ email, password }) => {
  try {
    const res = await Auth.signIn({ username: email, password: password });
    if (res?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const { requiredAttributes, userAttributes } = res.challengeParam;
      return new ChallengeRequiredException({
        challengeName: res?.challengeName,
        requiredAttributes: requiredAttributes,
        userAttributes: userAttributes,
      });
    }
    return getUser(res);
  } catch (e) {
    return AuthExceptionResolver(e);
  }
};

export const completeNewPasswordSubmit = async ({ password, email, oldPassword }) => {
  try {
    const cognitoUser = await Auth.signIn({ username: email, password: oldPassword });
    return await Auth.completeNewPassword(
      cognitoUser,
      password // new password
    );
  } catch (e) {
    throw AuthExceptionResolver(e);
  }
};

export const logout = async (rejectWithValue) => {
  try {
    return Auth.signOut();
  } catch (e) {
    return rejectWithValue(e.message);
  }
};

export const getCurrentAuthenticatedUser = async (rejectWithValue) => {
  try {
    const res = await Auth.currentAuthenticatedUser();
    return getUser(res);
  } catch (e) {
    return rejectWithValue();
  }
};

export const remindPassword = async (username, rejectWithValue) => {
  try {
    const res = await Auth.forgotPassword(username);
    return getUser(res);
  } catch (e) {
    return rejectWithValue();
  }
};

export const forgotPasswordSubmit = async ({ email, password, verificationCode }, rejectWithValue) => {
  try {
    return await Auth.forgotPasswordSubmit(email, verificationCode, password);
  } catch (e) {
    return rejectWithValue(AuthExceptionResolver(e));
  }
};

const getUser = (session) => {
  const tokens = getTokens(session.signInUserSession);
  const details = getUserDetails(session);
  return { tokens, details };
};
const getTokens = (session) => {
  return {
    accessToken: session.getAccessToken().getJwtToken(),
    idToken: session.getIdToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
};
const getUserDetails = (auth) => {
  return {
    userName: auth?.username,
    email: auth?.attributes?.email,
    isEmailVerified: auth?.attributes?.email_verified,
  };
};
