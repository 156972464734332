import React, { useEffect } from 'react';
import { AuthContainer } from '../../../components/AuthContainer';
import { CompleteNewPasswordForm } from './CompleteNewPasswordForm';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { AuthenticationException } from '../../../services/auth/exceptions/AuthenticationException';

export const CompleteNewPassword = () => {
  const { state } = useLocation();
  const { email, password: oldPassword } = state;
  const { error } = useSelector(({ challenge }) => challenge);
  const { loggedIn } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loggedIn) {
      enqueueSnackbar('Nowe hasło ustawione pomyślnie', { variant: 'info' });
      navigate('/');
    }
  }, [loggedIn, navigate, enqueueSnackbar]);

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  const onSubmitComplete = (results) => {
    if (results?.error) {
      if (results?.payload instanceof AuthenticationException) {
        enqueueSnackbar(results.payload?.message, { variant: 'error' });
        navigate('/login');
      }
    }
  };
  return (
    <AuthContainer title={'Aby zalogować się do aplikacji Lumilook ustal nowe hasło.'}>
      <CompleteNewPasswordForm onCompleteNewPassword={onSubmitComplete} email={email} oldPassword={oldPassword} />
    </AuthContainer>
  );
};
