import Grid2 from '@mui/material/Unstable_Grid2';
import { DynamicListItemStyled } from './DynamicListItemStyled';
import { Button } from '@mui/material';
import React from 'react';

export const DynamicListItem = ({
  processChild,
  statusChild,
  actionChild,
  isFirst,
  title,
  navigateTo,
  handleEdit,
  id,
  isHoover,
  actionOnclick,
}) => {
  const [showButton, setShowButton] = React.useState(false);
  const shouldShowButton = Boolean(title);

  const showAdditionalButton = () => isHoover && setShowButton(true);
  const hideAdditionalButton = () => isHoover && setShowButton(false);
  const handleSeeProcess = () => {
    if (handleEdit && id && navigateTo) {
      navigateTo(3);
      handleEdit(id);
    }
  };

  return (
    <DynamicListItemStyled isFirst={isFirst} onMouseEnter={showAdditionalButton} onMouseLeave={hideAdditionalButton}>
      <Grid2 container columns={12}>
        <Grid2 xs={6} lg={8}>
          <div className="process">
            {processChild}
            {shouldShowButton && showButton && <Button onClick={handleSeeProcess}>{title}</Button>}
          </div>
        </Grid2>
        <Grid2 xs={3} lg={2} className="status">
          {statusChild}
        </Grid2>
        <Grid2 xs={3} lg={2} className="actions">
          {actionChild}
        </Grid2>
      </Grid2>
    </DynamicListItemStyled>
  );
};
