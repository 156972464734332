import React, { useEffect, useState } from 'react';
import { fetchEventsTypes } from '../../../store/warehouseDetails/eventTypes/eventsTypes.action';
import { useDispatch, useSelector } from 'react-redux';
import { eventsTypes } from '../../../store/warehouseDetails/eventTypes/eventsTypes.selector';
import { restParamsObject } from '../../../utils/utils';
import { useSearchParams } from 'react-router-dom';
import { eventDefinitionState } from '../../../store/appConfig/appConfig.selector';
import { getPieChartData } from '../../../utils/chart.helpers';
export const useEventsTypes = () => {
  const { data, isLoading, status } = useSelector(eventsTypes);
  const [chartData, setChartData] = useState(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { list } = useSelector(eventDefinitionState);

  useEffect(() => {
    dispatch(fetchEventsTypes({ params: { ...restParamsObject(searchParams, ['timestamp_from', 'timestamp_to']) } }));
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (!!data?.length && status === 'fulfilled') {
      setChartData(getPieChartData(data, list));
    }
  }, [data, isLoading]);

  return {
    chartData,
    isLoading,
  };
};
