import { ReportsPageCreatorStyled } from './ReportsPageCreatorStyled';
import Grid2 from '@mui/material/Unstable_Grid2';
import { InputComponent } from '../../../components/InputComponent';
import { DynamicSelect } from '../../../components/DynamicSelect/DynamicSelect';
import { CheckboxComponent } from '../../../components/Checkbox';
import { reportSelectOptions } from './reportsPageCreator.helper';
import React from 'react';
import {
  CreatorCancelButtonStyled,
  CreatorSaveButtonStyled,
} from '../../../components/CreatorComponents/CreatorButtonStyled';
import { useReportCreator } from './useReportCreator';

export const ReportsPageCreator = ({ onPrevStep }) => {
  const { reportDetails, handleInputChange, handleCheckbox, handleSelect, handleSubmitReportDetails } =
    useReportCreator();

  const handleSubmit = () => {
    handleSubmitReportDetails();
    onPrevStep();
  };

  return (
    <ReportsPageCreatorStyled>
      <Grid2 container>
        <Grid2 xs={12}>
          <div>
            <div className="contentHeader">{'Stwórz nowy raport'}</div>
            <p className="contentDescription">
              {'Stwórz nowy raport definiując do kogo, co jaki czas oraz jakie dane mają być w nim wysłane. '}
            </p>
            <form onSubmit={handleSubmit} id="form">
              <Grid2 xs={4.5}>
                <InputComponent
                  id="reportName"
                  handleChange={handleInputChange}
                  type={'text'}
                  name="reportName"
                  value={reportDetails.reportName}
                  label={'Wpisz nazwę raportu'}
                  placeholder={'Wpisz nazwę raportu'}
                />
                <InputComponent
                  id="emails"
                  type={'email'}
                  handleChange={handleInputChange}
                  value={reportDetails.emails}
                  name="emails"
                  label={'Adresaci raportu (adres e-mail oddziel przecinkiem)'}
                  placeholder={'Wpisz adresy e-mail'}
                />
                <div className="contentSelect">
                  <DynamicSelect
                    options={reportSelectOptions}
                    label={'Systematyczność wysyłania raportów'}
                    handleSelect={handleSelect}
                  />
                </div>
              </Grid2>
              <div className="contentCheckbox">
                <CheckboxComponent
                  name="eventsInPartTime"
                  disabled={false}
                  label={'Ilość zdarzeń w danym okresie'}
                  handleCheckbox={handleCheckbox}
                  checked={reportDetails.eventsInPartTime}
                />
                <CheckboxComponent
                  name="eventsPriority"
                  disabled={false}
                  label={'Priorytet zdarzeń'}
                  handleCheckbox={handleCheckbox}
                  checked={reportDetails.eventsPriority}
                />
                <CheckboxComponent
                  name="eventsDangerous"
                  disabled={false}
                  label={'Niebezpieczenstwo zdarzeń'}
                  handleCheckbox={handleCheckbox}
                  checked={reportDetails.eventsDangerous}
                />
                <CheckboxComponent
                  name="newEventsDefined"
                  disabled={false}
                  label={'Nowe zadania do zdefiniowania'}
                  handleCheckbox={handleCheckbox}
                  checked={reportDetails.newEventsDefined}
                />
              </div>
              <CreatorCancelButtonStyled onClick={onPrevStep}>{'Anuluj'}</CreatorCancelButtonStyled>
              <CreatorSaveButtonStyled>{'Zapisz raport'}</CreatorSaveButtonStyled>
            </form>
          </div>
        </Grid2>
      </Grid2>
    </ReportsPageCreatorStyled>
  );
};
