import { Button, InputAdornment } from '@mui/material';
import React, { memo, useState } from 'react';
import { Modal } from '../../../components/Modal/Modal';
import { ModalContent } from '../../../components/Modal/ModalContent';

const ModalClearProject = ({ isModalOpen, handleClose, handleSubmit }) => {
  const [height, setHeight] = useState(0);

  return (
    <Modal
      closeByClickedOutside={true}
      isModalOpen={isModalOpen}
      closeModal={handleClose}
      maxWidth="lg"
      backgroundColor={'rgba(0, 0, 0, 0.75)'}
    >
      <ModalContent
        headerTitle={'Czy na pewno chcesz wyszyścić projekt?'}
        handleCloseButton={handleClose}
        footer={
          <div>
            <Button onClick={handleClose}>Anuluj</Button>
            <Button onClick={handleSubmit}>Zatwierdź</Button>
          </div>
        }
      />
    </Modal>
  );
};
export default memo(ModalClearProject);
