import React from 'react';

export const useUserDetailsEdit = () => {
  const [editDetailsState, setEditDetailsState] = React.useState({
    name: '',
    email: '',
    admin: false,
    bhp: false,
    manager: false,
  });

  const handleInputChange = (e, name) => {
    const {
      target: { value },
    } = e;
    setEditDetailsState((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckbox = (e) => {
    setEditDetailsState((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleEditUserDetails = (e) => {
    e.preventDefault();
    if (editDetailsState.name && editDetailsState.email) {
      console.log(editDetailsState);
    }
  };

  return { handleEditUserDetails, handleInputChange, handleCheckbox, editDetailsState };
};
