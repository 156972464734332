import React from 'react';
import { DynamicSelect } from '../../../../components/DynamicSelect/DynamicSelect';
import { useSearchParams } from 'react-router-dom';
import { eventsItemLimitSelectorOptions } from '../../WarehouseDetailsConfig';
import { restParamsObject, setOffset } from '../../../../utils/utils';
import styled from '@emotion/styled';

const EventsItemLimitSelector = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const productsLimitInitialValue = searchParams.get('limit') || 20;

  const handleChange = (value) => {
    setSearchParams({
      ...restParamsObject(searchParams),
      limit: value || 20,
      offset: setOffset(searchParams, value, undefined),
    });
  };

  return (
    <EventsItemLimitSelectorStyled>
      <span>Ilość wierszy:</span>
      <DynamicSelect
        options={eventsItemLimitSelectorOptions}
        handleSelect={handleChange}
        initialValue={productsLimitInitialValue}
      />
    </EventsItemLimitSelectorStyled>
  );
};

export default EventsItemLimitSelector;

const EventsItemLimitSelectorStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  > span {
    margin-right: 0.5rem;
  }
`;
