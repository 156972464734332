import React from 'react';
import { Stepper } from '../../../components/Stepper/Stepper';
import { Step } from '../../../components/Step/Step';
import { WarehouseManagementList } from './WarehouseManagementList/WarhouseManagementList';
import { AddWarehouse } from './AddWarehouse/AddWarehouse';
import { useWarehouseManagement } from './useWarehouseManagement';

export const WarehouseManagement = () => {
  const [warehouseState, setWarehouseState] = React.useState({});
  const { handleCreateWarehousePlane, isLoading } = useWarehouseManagement();

  const handleWarehouseState = (payload) => setWarehouseState(payload);

  return (
    <Stepper initialStep={1}>
      <Step
        step={1}
        render={(props) => <WarehouseManagementList {...props} handleWarehouseState={handleWarehouseState} />}
      />
      <Step step={2} render={(props) => <AddWarehouse {...props} isEditor={false} />} />
      <Step
        step={3}
        render={(props) => (
          <AddWarehouse
            {...props}
            isEditor={true}
            isLoading={isLoading}
            initialValues={warehouseState}
            handleCreateWarehousePlane={handleCreateWarehousePlane}
          />
        )}
      />
    </Stepper>
  );
};
