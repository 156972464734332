import { TableCell } from '@mui/material/';
import React from 'react';
import { Button } from '@mui/material/';

export const RecommendationTypeCell = ({ data }) => {
  const { id, onRowClick, marker } = data;
  const clickHandle = () => {
    onRowClick(id);
  };

  return (
    <TableCell className="">
      {marker ? (
        <Button variant="text" onClick={clickHandle}>
          Zobacz
        </Button>
      ) : (
        <span>OGÓLNY</span>
      )}
    </TableCell>
  );
};
