import React, { useEffect, useState } from 'react';
import { DateHelper } from '../../utils/dateHelper';

export const useDateEvents = () => {
  const [dateValue, setDateValue] = useState({
    from: new DateHelper().getDateWithOffset(30).getStartOfDay().getTime(),
    to: new DateHelper().getTime(),
  });

  const [timestampParams, setTimestampParams] = useState({
    timestamp_to: dateValue.to,
    timestamp_from: dateValue.from,
  });

  useEffect(() => {
    setTimestampParams({
      timestamp_to: dateValue.to,
      timestamp_from: dateValue.from,
    });
  }, [dateValue]);

  return {
    dateValue,
    setDateValue,
    timestampParams,
  };
};
