import React from 'react';
import styled from '@emotion/styled';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Loader } from '../Loader/Loader';

ChartJS.register(ArcElement, Tooltip, Legend);
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right',
    },
  },
};

export const PieChart = ({ data, isLoading, height }) => {
  return (
    <PieChartStyled>
      <div className="chart-container">
        {isLoading || !data ? <Loader /> : <Doughnut data={data} options={chartOptions} />}
      </div>
    </PieChartStyled>
  );
};

const PieChartStyled = styled.div`
  position: relative;
  width: 100%;
  height: 365px;
  @media (max-width: 768px) {
    height: 600px;
  }
  @media (max-width: 375px) {
    height: 700px;
  }

  .chart-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
