import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCurrentAuthenticatedUser,
  logout,
  signIn,
  remindPassword,
  forgotPasswordSubmit,
} from '../../services/auth/authAmplify';
import { ChallengeRequiredException } from '../../services/auth/exceptions/ChallengeRequiredException';
import { createChallenge } from '../challenge/challenge.reducer';
import { AuthenticationException } from '../../services/auth/exceptions/AuthenticationException';

export const login = createAsyncThunk('auth/login', async (body, { dispatch, rejectWithValue }) => {
  const response = await signIn(body);
  if (response instanceof ChallengeRequiredException) {
    dispatch(createChallenge(response));
    return rejectWithValue(response);
  }
  if (response instanceof AuthenticationException) {
    return rejectWithValue(response);
  }
  return response;
});

export const authorize = createAsyncThunk('auth/authorize', async (body, { rejectWithValue }) => {
  const response = await getCurrentAuthenticatedUser(rejectWithValue);
  return response;
});
export const logOut = createAsyncThunk('auth/logOut', async () => {
  const response = await logout();
  return response;
});

export const remindUserPassword = createAsyncThunk('auth/remindUserPassword', async (username, { rejectWithValue }) => {
  const response = await remindPassword(username, rejectWithValue);
  return response;
});

export const setNewPassword = createAsyncThunk('auth/setNewPassword', async (body, { rejectWithValue }) => {
  return await forgotPasswordSubmit(body, rejectWithValue);
});
