import { Divider, Link } from '@mui/material';
import React, { useCallback } from 'react';
import { FileList } from './FilesList';
import { TargetBox } from './TargetBox';
import ModalWarehouseDimensions from './ModalWarehouseDimensions';
import { useDispatch, useSelector } from 'react-redux';
import NavigationWarehouse from './NavigationOnboarding';
import {
  clearFile,
  setDisabledSteps,
  setFile,
  setSceneDimensions,
  setStepSource,
} from '../../../store/onboarding/onboarding.reducer';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useModal } from '../../../components/Modal/useModal';
import { onBoardingReconstructionState, onboardingState } from '../../../store/onboarding/onboarding.selector';
import { convertBase64ToUrl } from '../../../utils/utils';

const DividerStyle = {
  margin: '3rem 0 1rem 0',
  borderBottomWidth: 1,
};

const UploadFile = ({ navigateTo, onNextStep }) => {
  const dispatch = useDispatch();
  const { file, stepSource } = useSelector(onboardingState);

  const handleFileDrop = useCallback(
    (item) => {
      if (item) {
        dispatch(setFile(item));
      }
    },
    [dispatch]
  );

  const { isModalOpen, setModalOpen } = useModal();
  const handleSubmitModalWarehouseDimensions = useCallback(
    (sceneDimensions) => {
      setModalOpen(false);

      const sceneDimensionsInCm = { width: sceneDimensions.width * 100, length: sceneDimensions.length * 100 };
      dispatch(setSceneDimensions(sceneDimensionsInCm));
      dispatch(setDisabledSteps(true));
      navigateTo(4);
    },
    [dispatch, navigateTo, setModalOpen]
  );

  React.useEffect(() => {
    if (stepSource === 'AccountEditPlan') {
      dispatch(setStepSource('AccountNewPlan'));
      dispatch(clearFile());
    }
  }, []);

  const handleCloseModal = () => setModalOpen(false);
  const handleClick = () => setModalOpen(true);

  return (
    <>
      <TargetBox onDrop={handleFileDrop} />
      <FileList files={file} />
      <NavigationWarehouse handleNextStep={onNextStep} isNextButtonDisabled={!Boolean(file)} />
      <Divider sx={DividerStyle} variant="fullWidth" />
      <Grid2 textAlign={'center'}>
        <Link onClick={handleClick}>Nie posiadam planu magazynu</Link>
      </Grid2>
      <ModalWarehouseDimensions
        isModalOpen={isModalOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModalWarehouseDimensions}
      />
    </>
  );
};
export default UploadFile;
