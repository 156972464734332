import { SubmitButton } from '../../../components/SubmmitButton';
import { UserDetailsEditorStyled } from './UserDetailsEditorStyled';
import { InputComponent } from '../../../components/InputComponent';
import { CheckboxComponent } from '../../../components/Checkbox';
import { useUserDetailsEdit } from './useUserDetailsEdit';

export const UserDetailsEditor = ({ onPrevStep }) => {
  const { handleInputChange, editDetailsState, handleEditUserDetails, handleCheckbox } = useUserDetailsEdit();

  const handleSubmit = () => {
    handleEditUserDetails();
    onPrevStep();
  };

  return (
    <UserDetailsEditorStyled onSubmit={handleSubmit}>
      <div className="wrapper">
        <InputComponent
          label={'Imię i nazwisko użytkownika'}
          handleChange={handleInputChange}
          id="name"
          type="text"
          name="name"
          value={editDetailsState?.name}
        />
        <InputComponent
          label={'Adres e-mail użytkoniwka'}
          handleChange={handleInputChange}
          id="email"
          type="email"
          name="email"
          value={editDetailsState?.email}
        />
        <p className="label">{'Poziom dostępu'}</p>
        <div className="checkBoxWrapper">
          <CheckboxComponent
            name="admin"
            disabled={false}
            label={'Administrator'}
            handleCheckbox={handleCheckbox}
            checked={editDetailsState.admin}
          />
          <CheckboxComponent
            name="bhp"
            disabled={false}
            label={'BHP'}
            handleCheckbox={handleCheckbox}
            checked={editDetailsState.bhp}
          />
          <CheckboxComponent
            name="manager"
            disabled={false}
            label={'Manager'}
            handleCheckbox={handleCheckbox}
            checked={editDetailsState.manager}
          />
        </div>
      </div>
      <div className="buttonWrapper">
        <SubmitButton text={'Zapisz dane'} />
      </div>
    </UserDetailsEditorStyled>
  );
};
