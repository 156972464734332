import API, { deleteMethod, get, post, putJSON } from './api';

export const getHistoryProcessList = (body) =>
  get(`${API.apiUrl}/warehouse/${body.warehouseId}/scenario/execution`, body.data, body.params);

export const getAvailableScenario = (body) => get(`${API.apiUrl}/block`, body.data);

export const getProcessList = (body) => get(`${API.apiUrl}/warehouse/${body.warehouseId}/scenario`, body.data);

export const addScenario = (body) =>
  post(`${API.apiUrl}/warehouse/${body.warehouseId}/scenario`, body.data, 'application/json');

export const updateScenario = (body) =>
  putJSON(`${API.apiUrl}/warehouse/${body.warehouseId}/scenario/${body.scenarioId}`, body.data);

export const getScenarioById = (body) =>
  get(`${API.apiUrl}/warehouse/${body.warehouseId}/scenario/${body.scenarioId}`, body.data);

export const deleteScenario = (body) =>
  deleteMethod(`${API.apiUrl}/warehouse/${body.warehouseId}/scenario/${body.scenarioId}`, body.data);

export const getScenarioExecutions = (body) =>
  get(`${API.apiUrl}/warehouse/${body.warehouseId}/scenario/${body.scenarioId}/execution`, body.data);
