import { matchEventDefinitions } from '../../../../utils/utils';

export const prepareCase = (cases, list) =>
  cases.map((item) => {
    return {
      block_id: item.id,
      value: item.name,
      name: matchEventDefinitions(item.name, list),
      label: matchEventDefinitions(item.name, list),
      parameters: item.parameters,
    };
  });

export const preparedKeys = (obj, index) =>
  Object.keys(obj)
    .filter((key) => key.includes(`caseId${index}`))
    .reduce((cur, key) => {
      const fixedKeyName = key.replace(`caseId${index}_`, '');
      return Object.assign(cur, { [fixedKeyName]: obj[key] });
    }, {});
