import { createAsyncThunk } from '@reduxjs/toolkit';
import { completeNewPasswordSubmit } from '../../services/auth/authAmplify';
import { authorize } from '../auth/auth.actions';

export const completeNewPassword = createAsyncThunk(
  'auth/completeNewPassword',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await completeNewPasswordSubmit(data);
      dispatch(authorize());
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
