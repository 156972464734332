import * as yup from 'yup';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { pl } from 'yup-locales';
import { setLocale } from 'yup';
import { PASSWORD_REGEX } from '../../../constants';

setLocale(pl);

export const useCompleteNewPassword = () => {
  const passwordRegex = PASSWORD_REGEX;
  const schema = yup
    .object({
      password: yup
        .string()
        .matches(
          passwordRegex,
          'Hasło musi zawierać co najmniej jedną wielką i jedną mała literę, jedną liczbę oraz jeden znak specjalny. Minimalna długość hasła to 8 znaków'
        )
        .required()
        .min(8)
        .label('Hasło'),
      password_confirm: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Hasła są różne')
        .matches(
          passwordRegex,
          'Hasło musi zawierać co najmniej jedną wielką i jedną mała literę, jedną liczbę oraz jeden znak specjalny. Minimalna długość hasła to 8 znaków'
        )
        .required()
        .min(8)
        .label('Potwórz hasło'),
    })
    .required();

  const defaultValues = {
    password: '',
    password_confirm: '',
  };

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, isSubmitting, isSubmitSuccessful, isSubmitted, isValidating } = useFormState({ control });

  return { control, formState: { isValid, isSubmitting, isSubmitSuccessful, isSubmitted, isValidating }, handleSubmit };
};
