import styled from '@emotion/styled';
import { Button, TableRow } from '@mui/material';

export const TableOutlinedButton = styled(Button)`
  font-size: 0.65rem;
  border-radius: 4px;
  border: 1px solid #0d6dc5;
  text-transform: none;
  padding: 1px 6px;
`;

export const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => {
    return prop !== 'read';
  },
})`
  .view-button {
    visibility: hidden;
  }
  &:hover {
    .view-button {
      visibility: ${({ read }) => !read && 'visible'};
    }
  }
`;
