import { Box, Button, Typography } from '@mui/material';
import { messages } from '../../ProcessComposition/processCompositionMessages';
import React from 'react';
import { ReportsPageHeaderStyled } from './ReportsPageHeaderStyled';

export const ReportsPageHeader = ({ onNextStep }) => {
  return (
    <ReportsPageHeaderStyled>
      <Box p={3} className="header headerProcess">
        <Typography variant="h5" gutterBottom>
          {messages.reports.addReport.title}
        </Typography>
        <div className="headerProcessList">
          <span>{messages.reports.addReport.count}</span>
          <span className="headerProcessListCounter">{5}</span>
        </div>
        <Button onClick={onNextStep}>{messages.reports.addReport.button}</Button>
        <img className="headerImg" src={'/static/new-report.svg'} alt={'new report'} />
      </Box>
    </ReportsPageHeaderStyled>
  );
};
