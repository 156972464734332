import { Button } from '@mui/material';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const FormButton = ({ text, textAlign, type, variant, align, ...props }) => {
  const buttonTextAlign = textAlign ? textAlign : 'center';
  const buttonType = type ?? 'button';
  const buttonVariant = variant ?? 'outlined';

  return (
    <Box textAlign={textAlign}>
      <SubmitButtonStyled
        type={buttonType}
        sx={{ textAlign: buttonTextAlign }}
        variant={buttonVariant}
        align={'center'}
        {...props}
      >
        {text}
      </SubmitButtonStyled>
    </Box>
  );
};

const SubmitButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  font-size: 1.125em;
  padding: 5px 20%;
  margin-top: 25px;
`;
