export const warehouseDetailsPagesTexts = {
  dateTimePicker: {
    title: 'Wybierz zakres dat',
  },
  nav: {
    tab1Title: 'Zdarzenia <br /> w magazynie',
    tab2Title: 'Statystyki <br /> magazynu',
  },
  eventsPage: {
    mapChartTitle: 'Mapa zdarzeń',
    eventsListTitle: 'Zdarzenia w magazynie',
    eventsListSubtitle: 'Zobacz zdarzenia, które miały miejsce w Twoich magazynach w określonym przedziale czasowym.',
  },
};
