import { Button, InputAdornment, Typography } from '@mui/material';
import React, { memo } from 'react';
import { Modal } from '../../../components/Modal/Modal';
import { ModalContent } from '../../../components/Modal/ModalContent';
import { useForm, useFormState } from 'react-hook-form';
import { FormInputText } from '../../../components/FormComponents/FormInputText';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { pl } from 'yup-locales';
import { setLocale } from 'yup';
setLocale(pl);

const schema = yup
  .object({
    width: yup.number().integer().max(200).min(1).required().label('szerokość'),
    length: yup.number().integer().max(200).min(1).required().label('długość'),
  })
  .required();

const defaultValues = {
  width: '',
  length: '',
};

const ModalWarehouseDimensions = ({ isModalOpen, handleClose, handleSubmit: handleModalSubmit }) => {
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid } = useFormState({ control });
  const onSubmit = (data) => {
    handleModalSubmit(data);
  };

  return (
    <Modal
      closeByClickedOutside={true}
      isModalOpen={isModalOpen}
      closeModal={handleClose}
      maxWidth="sm"
      backgroundColor={'rgba(0, 0, 0, 0.75)'}
    >
      <ModalContent
        headerTitle={'Wymiary magazynu'}
        handleCloseButton={handleClose}
        body={
          <>
            <Typography mb={4} variant={'body2'}>
              Zdefiniuj szerokość i długość obszaru objętego monitoringiem. Na tej podstawie zostanie przygotowany
              odpowiedniej wielkości obszar roboczy w plannerze 3D.
            </Typography>
            <form>
              <FormInputText
                name={'width'}
                label={'Szerokość magazynu w metrach'}
                control={control}
                InputProps={{
                  endAdornment: <InputAdornment position="start">m</InputAdornment>,
                }}
              />
              <FormInputText
                name={'length'}
                label={'Długość magazynu w metrach'}
                control={control}
                InputProps={{
                  endAdornment: <InputAdornment position="start">m</InputAdornment>,
                }}
              />
            </form>
          </>
        }
        footer={
          <Button onClick={handleSubmit(onSubmit)} disabled={!isValid}>
            Zatwierdź
          </Button>
        }
      />
    </Modal>
  );
};
export default memo(ModalWarehouseDimensions);
