import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setActiveStep, setStepSource } from '../../../store/onboarding/onboarding.reducer';
import { fetchWarehouseReconstruction } from '../../../store/onboarding/onboarding.actions';
import { onBoardingReconstructionState } from '../../../store/onboarding/onboarding.selector';
import { setOnboardingActiveWarehouseId } from '../../../store/appConfig/appConfig.reducer';

export const useWarehouseManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector(onBoardingReconstructionState);

  const handleCreateWarehousePlane = (isPlaneExist, warehouseId) => () => {
    dispatch(setOnboardingActiveWarehouseId(warehouseId));

    if (isPlaneExist) {
      dispatch(fetchWarehouseReconstruction({ warehouseId })).then(() => {
        dispatch(setStepSource('AccountEditPlan'));
        dispatch(setActiveStep(4));
        navigate('/onboarding');
      });
    } else {
      dispatch(setActiveStep(1));
      navigate('/onboarding');
      dispatch(setStepSource('AccountNewPlan'));
    }
  };

  return {
    isLoading,
    handleCreateWarehousePlane,
  };
};
