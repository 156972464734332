import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHistoryProcessList } from '../../api/process';
import {SORT_ORDER_ASC, TIMESTAMP} from '../../constants';

export const fetchHistoryProcessList = createAsyncThunk('historyProcessList/fetchHistoryProcessList', async (body, { getState }) => {
  const response = await getHistoryProcessList({
    warehouseId: getState().appConfig.appConfig.activeWarehouseId,
    data: {
      accessToken: getState().auth.tokens.accessToken,
    },
    params: { sort: `${TIMESTAMP}:${SORT_ORDER_ASC}` },
  });

  return response;
});
