import styled from '@emotion/styled';

export const UserHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 164px;
  position: relative;
  background-color: white;
  padding: 20px 20px 0 20px;

  .header {
    color: #697a8d;
    font-weight: 700;
    line-height: 19px;
    font-size: 18px;
  }

  .wrapper {
    margin-top: 30px;
    margin-bottom: 38px;
    display: flex;
    color: #435971;

    div {
      width: 50%;
    }

    p {
      font-weight: 400;
      margin-bottom: 20px;
      line-height: 18px;
      font-size: 14px;
    }

    span {
      font-weight: 700;
      line-height: 18px;
      font-size: 14px;
    }
  }
`;
