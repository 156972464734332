import React from 'react';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate, matchPath } from 'react-router';
import { DrawerHeader, DrawerStyled, ListItemStyled } from './styles';
import { DrawerStoreSelector } from './components/DrawStoreSelector';
import { authenticatedPagesList } from '../../routes/pagesList';
import { useSelector } from 'react-redux';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import { onboardingWarehouse } from '../../data/createWarehouse';
import { OnboardingStepIndicator } from './components/OnboardingStepIndicator';
import { ListHeader } from './components/ListHeader';
import { appConfigState, warehouses } from '../../store/appConfig/appConfig.selector';

const SideBar = ({ isSideBarOpen, handleDrawerClose }) => {
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { list } = useSelector(warehouses);
  const { isAnyWarehouseActive, activeWarehouseId } = useSelector(appConfigState);

  const handleNavigate = (href) => () => {
    if (activeWarehouseId && isAnyWarehouseActive) {
      if (href.includes('warehouse-details')) {
        return navigate(href.replace(':warehouseId', activeWarehouseId));
      }

      return navigate(`${href}/${activeWarehouseId}`);
    }
  };

  const width = isSideBarOpen ? 260 : 0;

  return (
    <DrawerStyled
      width={width}
      variant={screenWidth > 991 ? 'persistent' : 'temporary'}
      anchor="left"
      open={isSideBarOpen}
      transitionDuration={0}
    >
      <DrawerHeader>
        <img src="/static/lumilook-logo.svg" alt="lumilook logo" />

        <IconButton onClick={handleDrawerClose} className="menu-open-button">
          <img src="/static/menu-icon-close.svg" alt="menu open button" />
        </IconButton>
      </DrawerHeader>
      {pathname.includes('/onboarding') ? (
        <OnboardingStepIndicator title={'Twój wirtualny magazyn'} options={onboardingWarehouse} />
      ) : (
        <>
          <DrawerStoreSelector title={'Wybór magazynu'} options={list} path="warehouse" />
          <List>
            <ListHeader>PANEL</ListHeader>
            {authenticatedPagesList(isAnyWarehouseActive)
              .filter((item) => !item.shouldHide)
              .filter((item) => item.menu.includes('sideBar'))
              .map((item, index) => {
                let isSelected = matchPath(item.hrefWithParam, pathname) || null;
                isSelected =
                  isSelected || (item.alternativeMenuParam && matchPath(item.alternativeMenuParam, pathname));

                return (
                  <ListItemStyled
                    key={index}
                    disabled={!item.isSectionDisabled || isNaN(activeWarehouseId)}
                    disablePadding
                    onClick={handleNavigate(item.href)}
                    isSelected={isSelected}
                    customMargin={item.customMargin}
                  >
                    <ListItemButton>
                      <ListItemIcon className="menu-icons">
                        <img src={item.icon} alt={item.name} />
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItemStyled>
                );
              })}
          </List>
        </>
      )}
    </DrawerStyled>
  );
};

export default SideBar;
