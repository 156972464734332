import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { TableComponent } from '../../../components/TableComponent/TableComponent';
import { useModal } from '../../../components/Modal/useModal';
import { EventsModal } from '../EventsModal/EventsModal';
import { BreadcrumbsNav } from '../BreadcrumbsNav/BreadcrumbsNav';
import MapChart from '../../../components/MapChart/MapChart';
import ContentBox from '../../../components/ContentBox/ContentBox';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useEvents } from './useEvents';
import EventsItemLimitSelector from './EventsItemLimitSelector/EventsItemLimitSelector';
import { PaginationStyled } from '../../../components/TableComponent/styles';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import { warehouseDetailsPagesTexts } from '../../../data/warehouseDetailsPagesTexts';
import { commonTexts } from '../../../data/commonTexts';
import { restParamsObject, setOffset } from '../../../utils/utils';
import { useEventsHistogram } from './useEventsHistogram';
import { idOfEventToShowState } from '../../../store/events/events.selector';
import { useDispatch, useSelector } from 'react-redux';
import { setIdOfEventToShow } from '../../../store/events/events.reducer';

export const Events = () => {
  const { level, eventList, rows, isLoading, tableHeaders, markers } = useEvents();
  const { svg, sceneDimensions, heatMapPoints, isEventsHistogramLoading } = useEventsHistogram();
  const { isModalOpen, setModalOpen } = useModal();
  let [searchParams, setSearchParams] = useSearchParams();
  const idOfEventToShow = useSelector(idOfEventToShowState);
  const dispatch = useDispatch();
  const urlPage = Number(searchParams.get('page')) || 0;
  const rowsLimit = Number(searchParams.get('limit')) || 0;
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const handleReadEvent = useCallback(
    (row) => {
      dispatch(setIdOfEventToShow(row));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!!idOfEventToShow) {
      setModalOpen(true);
    }
  }, [idOfEventToShow, setModalOpen]);

  useEffect(() => {
    if (rows.length < rowsLimit) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
  }, [rows, rowsLimit, urlPage]);

  const handleClickNext = () => {
    const page = urlPage ? urlPage + 1 : 1;
    setSearchParams({
      ...restParamsObject(searchParams),
      page,
      offset: setOffset(searchParams, undefined, page),
    });
  };

  const handleClickPrev = () => {
    const page = urlPage ? urlPage - 1 : 1;

    return setSearchParams({
      ...restParamsObject(searchParams),
      page,
      offset: setOffset(searchParams, undefined, page),
    });
  };

  return (
    <>
      <Grid2 xs={12}>
        <ContentBox title={warehouseDetailsPagesTexts.eventsPage.mapChartTitle}>
          <MapChart
            heatmapData={heatMapPoints}
            events={markers}
            svg={svg}
            sceneDimensions={sceneDimensions}
            isLoading={isEventsHistogramLoading}
          />
        </ContentBox>
      </Grid2>
      <Grid2 xs={12}>
        <ContentBox title={warehouseDetailsPagesTexts.eventsPage.eventsListTitle} widget={<EventsItemLimitSelector />}>
          <p className="table-subtitle">{warehouseDetailsPagesTexts.eventsPage.eventsListSubtitle}</p>
          <Box>
            <BreadcrumbsNav level={level} />
          </Box>
          <TableComponent
            headers={tableHeaders}
            rows={rows}
            isLoading={isLoading}
            rowPerPage={10}
            pagination={true}
            level={level}
            handleReadEvent={handleReadEvent}
          />
          <EventsModal
            isModalOpen={isModalOpen}
            closeModal={() => {
              setModalOpen(false);
              dispatch(setIdOfEventToShow(null));
            }}
            idOfEventToShow={idOfEventToShow}
            rows={eventList}
            maxWidth="md"
          />
          <PaginationStyled>
            <Button onClick={handleClickPrev} variant="contained" disabled={urlPage === null || urlPage === 1}>
              {commonTexts.nav.previousPage}
            </Button>
            <Button onClick={handleClickNext} variant="contained" disabled={isNextButtonDisabled}>
              {commonTexts.nav.nextPage}
            </Button>
          </PaginationStyled>
        </ContentBox>
      </Grid2>
    </>
  );
};
