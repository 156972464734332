import styled from '@emotion/styled';

export const WarehouseDetailsStyled = styled.div`
  .warehouse-header {
    .MuiTab-root {
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      span {
        font-weight: 700;
        color: #697a8d;
        line-height: 19px;
        text-align: start;
        margin-left: 20px;
      }
      img {
        width: auto;
        height: 42px;
        object-fit: cover;
      }
    }

    .date-picker-container {
      > div {
        border-radius: 6px;
        background-color: #fff;
        padding: 15px 20px 10px;

        @media (max-width: 900px) {
          margin-top: 20px;
        }

        p {
          color: #697a8d;
          font-weight: 600;
          line-height: 19px;
          font-size: 0.85rem;
        }
      }
    }
  }
`;
