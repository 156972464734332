import { Button, Grid } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { memo } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const containerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: 'auto',
};

const NavigationWarehouse = ({
  handleNextStep = null,
  handleBackStep = null,
  isNextButtonDisabled = false,
  isPreviousButtonDisabled = false,
  isLastStep = false,
  stepId = false,
}) => {
  const gridContainerStyle = !handleBackStep
    ? { ...containerStyle, justifyContent: 'flex-end' }
    : { ...containerStyle };
  return (
    <Grid2 container style={gridContainerStyle} xs={11} pt={3}>
      {handleBackStep && (
        <Grid2>
          <Button
            variant="outlined"
            size={'small'}
            onClick={handleBackStep}
            disabled={isPreviousButtonDisabled}
            startIcon={<ArrowBackIosIcon />}
          >
            Wstecz
          </Button>
        </Grid2>
      )}
      {handleNextStep && (
        <Grid2>
          <Button
            disabled={isNextButtonDisabled}
            size={'small'}
            variant={stepId === 3 ? 'contained' : 'outlined'}
            onClick={handleNextStep}
            endIcon={<ArrowForwardIosIcon />}
          >
            {stepId === 4 ? 'Zapisz' : stepId === 5 ? 'Zakończ' : 'Dalej'}
          </Button>
        </Grid2>
      )}
    </Grid2>
  );
};
export default memo(NavigationWarehouse);
