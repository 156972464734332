import styled from '@emotion/styled';
import Button from '@mui/material/Button';

export const UserDetailsStyled = styled.div`
  background: white;
  padding: 0 20px 20px 20px;

  .checkbox {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .border {
    border-top: 1px #eceef1 solid;
    margin-bottom: 10px;
  }

  .lowerHeader {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #435971;
    padding: 40px 0 11px 0;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
  }
`;

export const UserDetailsSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  color: #435971;
  font-size: 14px;

  p {
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  span {
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 20px;
  }
`;

export const EditButtonStyled = styled(Button)`
  color: #566a7f;
  background-color: #fff;
  border: 2px solid;
  margin-top: 30px;
  width: 350px;
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  @media (max-width: 767px) {
    width: 200px;
  }

  :hover {
    background: white;
    border: 2px solid;
  }
`;
