import React from 'react';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from '@emotion/styled';

const format = 'dd MMMM yyyy';
const locale = 'pl-PL';
const divider = '/';

export const DateRangePickerComponent = ({ onChange, value }) => {
  const [selectWidth, setSelectWidth] = React.useState([]);
  React.useLayoutEffect(() => {
    const options = [...document.querySelectorAll('select option:checked')];
    const width = options.length && options.map((item) => item.innerText.length);
    setSelectWidth(width);
  }, [value]);

  return (
    <DateRangePickerStyled
      onChange={onChange}
      value={[new Date(value.from), new Date(value.to)]}
      clearIcon={null}
      calendarIcon={<KeyboardArrowDownIcon className="arrow-down" />}
      locale={locale}
      format={format}
      rangeDivider={divider}
      selectWidth={selectWidth}
    />
  );
};

const DateRangePickerStyled = styled(DateRangePicker)`
  margin-top: 10px;
  width: 100%;
  & > div {
    padding: 3px 0 3px 10px;
    border: 1px solid #adb5bd;
    border-radius: 6px;
  }
  .react-daterange-picker {
    &__calendar {
      z-index: 1006;
    }
  }
  .react-daterange-picker__wrapper {
    & > div:first-of-type {
      select {
        width: ${({ selectWidth }) => selectWidth[0] * 9.5 + 'px'};
      }
    }
    & > div:last-of-type {
      select {
        width: ${({ selectWidth }) => selectWidth[1] * 9.5 + 'px'};
      }
    }
  }

  input,
  select,
  .react-daterange-picker__range-divider,
  .arrow-down,
  .react-daterange-picker__inputGroup__leadingZero {
    color: #566a7f;
    font-size: 0.9rem;
  }
  .react-daterange-picker__inputGroup__day {
    min-width: auto;
  }

  .react-daterange-picker__inputGroup__month {
  }

  .react-daterange-picker__inputGroup__year {
    min-width: 35px;
  }

  .react-daterange-picker__inputGroup {
    text-align: center;
  }

  .react-daterange-picker__button {
    margin-left: auto;
  }

  .react-date-picker__inputGroup__divider {
    display: none;
  }

  .react-daterange-picker__inputGroup {
    width: auto;
  }

  select {
    -webkit-appearance: none;
    text-align: center;
    max-width: min-content;
  }
`;
