import ContentBox from '../components/ContentBox';
import Grid2 from '@mui/material/Unstable_Grid2';
import NavigationWarehouse from '../components/NavigationOnboarding';
import React, { useCallback, useEffect, useState } from 'react';
import { LoaderWrapper, PlanLoader } from './OnBoardingViewEditorStyled';
import { useOnBoardingViewEditor } from './useOnBoardingViewEditor';
import { onboardingWarehouse } from '../../../data/createWarehouse';
import { GridContainer } from '../components/GridContainerStyled';
import { useSelector } from 'react-redux';
import { onboardingState } from '../../../store/onboarding/onboarding.selector';
import { useIframePlanner } from './useIframePlanner';
import { Loader } from '../../../components/Loader/Loader';
import ModalWallHeight from '../components/ModalWallHeight';
import ModalClearProject from '../components/ModalClearProject';

export const OnBoardingViewEditor = ({ onNextStep, onPrevStep, navigateTo }) => {
  const onboarding = useSelector(onboardingState);
  const { handleUploadPlan, setDataToPlanner, isDisabledSteps, isLoading } = useOnBoardingViewEditor(onNextStep);
  const { handleIframeError, isIframeError, handleIframeLoading, isIframeLoading } = useIframePlanner();
  const [heightModalActivity, setHeightModalActivity] = useState(false);
  const [clearProjectModalActivity, setClearProjectModalActivity] = useState(false);

  const { step, header, description, stepId } = onboardingWarehouse[3];

  const handleNext = useCallback(() => {
    //here we should call to api
    handleUploadPlan();
  }, [handleUploadPlan, onNextStep]);

  const handlePrev = useCallback(() => {
    if (isDisabledSteps) {
      navigateTo(1);
    } else {
      onPrevStep();
    }
  }, [onPrevStep, navigateTo, onboarding]);

  const handleOnError = () => {
    handleIframeError();
    handleIframeLoading();
  };

  const handleOnLoad = () => {
    setDataToPlanner();
    handleIframeLoading();
  };

  const handleWallHeightChange = (value) => {
    const iframe = document.querySelector('iframe');
    if (!iframe) return;
    iframe.contentWindow.postMessage(
      {
        value,
        message: 'setWallHeight',
      },
      '*'
    );
    setHeightModalActivity(false);
  };

  const listenHeightModalActivity = (e) => {
    const message = e.data.message;

    if (message === 'openHeightPopup') {
      setHeightModalActivity(true);
    }
  };

  const handleClearProject = () => {
    const iframe = document.querySelector('iframe');
    if (!iframe) return;
    iframe.contentWindow.postMessage(
      {
        message: 'clearProject',
      },
      '*'
    );
    setClearProjectModalActivity(false);
    // setTimeout(() => {
    //   setDataToPlanner();
    // }, 2000);
  };

  const listenClearProject = (e) => {
    const message = e.data.message;

    if (message === 'openClearProjectPopup') {
      setClearProjectModalActivity(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', listenHeightModalActivity, false);
    window.addEventListener('message', listenClearProject, false);

    return () => {
      window.removeEventListener('message', listenHeightModalActivity);
      window.removeEventListener('message', listenClearProject);
    };
  }, []);
  return (
    <ContentBox title={step} subtitle={header} description={description}>
      <ModalWallHeight
        isModalOpen={heightModalActivity}
        handleClose={() => setHeightModalActivity(false)}
        handleSubmit={handleWallHeightChange}
      />
      <ModalClearProject
        isModalOpen={clearProjectModalActivity}
        handleClose={() => setClearProjectModalActivity(false)}
        handleSubmit={handleClearProject}
      />
      <Grid2 container xs={12} style={{ margin: 'auto' }}>
        <GridContainer>
          {isIframeError ? (
            <>{'Coś poszło nie tak'}</>
          ) : (
            <>
              {(isIframeLoading || isLoading) && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}

              <PlanLoader
                id="lumi-planner"
                title={'lumi-planer'}
                src={process.env.REACT_APP_PLANNER_URL}
                onLoad={handleOnLoad}
                onError={handleOnError}
              />
            </>
          )}
        </GridContainer>
      </Grid2>
      <NavigationWarehouse
        handleNextStep={handleNext}
        handleBackStep={handlePrev}
        isPreviousButtonDisabled={isLoading}
        isNextButtonDisabled={isLoading}
        isLastStep
        stepId={stepId}
      />
    </ContentBox>
  );
};
